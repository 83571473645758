import { createContext, useState } from 'react';
import { theme } from 'modules/defines/names';

const GlobalContext = createContext(null);

export default GlobalContext;

export const useGlobalContext = () => {
  const initUserData = {
    id: '', // 로그인 유저 아이디
    name: '', // 로그인 유저명
    email: '',
    token: '',
    CompanyInformation: {
      hospital_name: '', // 치과 병원명
      id: '', // 치과 병원 아이디
    },
    
  };
  const initModalData = {
    display: false,
    message: '',
    onConfirm: null,
  };

  const initPatientInfo = {
    patient_id: '',
    working_state: '',
    created_at: null,
    image: [],
  };


  
  const [globalState, setGlobalState] = useState({
    user: initUserData,
    theme: localStorage.getItem('theme') || theme.light, // light(default), dark
    modal: initModalData,
    patientInfo: initPatientInfo,
    // patientInfo: {
    //   patient_id: '',
    //   image: [],
    // },
  });

  const clear = () => {
    setGlobalState(prev => ({ ...prev, user: initUserData }));
  };
  const setModal = (data) => {
    setGlobalState(prev => ({ ...prev, modal: data }));
  };
  const closeModal = () => {
    setGlobalState(prev => ({ ...prev, modal: initModalData }));
  };
  const setPatientInfo = (data) => {
    setGlobalState(prev => ({ ...prev, patientInfo: data }));
  };

  return {
    ...globalState,
    set: setGlobalState,
    clear,
    setModal,
    closeModal,
    setPatientInfo,
  };
};