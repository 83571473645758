import React from 'react';

const Search = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '14'} height={props.height || '14'} viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <g fill={props.color || '#444A58'}>
        <path d="M720.526 198.636c-2.031-2.033-5.275-2.19-7.493-.364-2.219 1.828-2.686 5.041-1.08 7.425 1.606 2.383 4.76 3.156 7.286 1.786l3.343 3.176c.363.344.933.337 1.287-.016l.858-.858c.358-.36.358-.94 0-1.3l-3.244-3.25c1.177-2.169.787-4.853-.957-6.599zm-1.657 6.23c-.924.924-2.315 1.201-3.523.7-1.209-.5-1.996-1.679-1.996-2.986 0-1.308.787-2.487 1.996-2.987 1.208-.5 2.599-.224 3.523.701 1.259 1.264 1.259 3.307 0 4.571z" transform="translate(-711 -197)" />
      </g>
    </g>
  </svg>
);

export default Search;