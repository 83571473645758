import React, { useContext } from 'react';

import GlobalContext from 'modules/context/GlobalContext';
import ModalPopup from 'components/modal/ModalPopup';

const ModalContainer = () => {
  const globalContext = useContext(GlobalContext);

  const handleClose = () => {
    globalContext.closeModal();
  };

  return (
    <ModalPopup onClose={handleClose} onConfirm={globalContext.modal.onConfirm}>
      {globalContext.modal.message}
    </ModalPopup>
  );
};

export default ModalContainer;