import axios from 'axios';

export const defaultOptions = {
  method: 'POST',
  // baseURL: process.env.NODE_ENV === 'development' ? 'https://test.node.3d-labeling.ddhinc.net' : 'https://test.node.3d-labeling.ddhinc.net',  // 추후 dev 경우 url 변경되어야함 ADD test.
  baseURL: process.env.NODE_ENV === 'development' ? 'https://node.3d-labeling.ddhinc.net' : 'https://node.3d-labeling.ddhinc.net',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const http = (host, path, callOptions) => {
  const options = { ...defaultOptions, ...callOptions };
  return new Promise((resolve, reject) => {
    axios(host + path, options)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(error => console.error(error));
  });
};

export default http;