import React from 'react';

const Tooth = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" className={props.className}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M21.35 15.852C19.622 17.462 13.64 18 12 18c-1.64 0-7.45-.404-9.35-2.148C.751 14.108.588 9.267.16 7.173c-.436-2.14.031-4.287 1.251-5.745C2.171.52 3.138 0 4.064 0 6.447 0 7.755.526 8.91.99c.356.143.704.283 1.068.402.192.076.389.153.59.234 2.051.815 4.603 1.83 6.724 1.83.283 0 .514-.225.514-.504s-.23-.504-.514-.504c-1.203 0-2.613-.382-3.983-.86.64-.139 1.202-.364 1.783-.598C16.244.526 17.552 0 19.935 0c.927 0 1.895.52 2.654 1.428 1.22 1.458 1.687 3.606 1.25 5.745-.427 2.094-.764 7.07-2.49 8.679z" transform="translate(-38 -207) translate(38.5 207)" />
      <path fill="#4295DB" d="M3.922 7c-.054 0-.107-.018-.15-.05-.085-.065-.123-.179-.096-.285l.29-1.132-.871-.736c-.082-.068-.115-.184-.083-.29.034-.105.124-.178.229-.183l1.12-.06.401-1.094C4.802 3.067 4.895 3 5 3c.105 0 .198.067.237.17l.402 1.093 1.121.061c.105.005.195.078.229.184.032.105 0 .22-.082.29l-.87.734.29 1.132c.027.106-.011.22-.096.284-.085.065-.199.068-.287.008L5 6.316l-.941.64c-.042.028-.09.044-.138.044z" transform="translate(-38 -207) translate(38.5 207)" />
    </g>
  </svg>
);

export default Tooth;
