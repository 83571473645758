import React, { useContext } from 'react';

import { TopMenuLayout } from 'components/layout';
import MenuButtonGroup from 'components/button/MenuButtonGroup';
import MenuButton from 'components/button/MenuButton';
import GlobalContext from 'modules/context/GlobalContext';
import ViewerContext from 'modules/context/ViewerContext';
import Localization from 'i18n';

const MenuContainer = () => {
  const globalContext = useContext(GlobalContext);
  const viewerContext = useContext(ViewerContext);

  return (
    <TopMenuLayout>
      {viewerContext.step === 1 && (
        <MenuButtonGroup>
          <MenuButton theme={globalContext.theme} icon="autoShape" title={Localization.autoMarginCurve} />{/* auto create margin curve */}
          <MenuButton theme={globalContext.theme} icon="manualShape" title={Localization.manualMarginCurve} />{/* manual create margin curve */}
          <MenuButton theme={globalContext.theme} icon="editShape" title={Localization.editMarginCurve} />{/* edit margin curve */}
          <MenuButton theme={globalContext.theme} icon="cutMesh" title={Localization.cutMesh} />{/* cut mesh */}
        </MenuButtonGroup>
      )}

      {(viewerContext.step === 2 || viewerContext.step === 3) && (
        <MenuButtonGroup>
          <MenuButton theme={globalContext.theme} icon="createMesh" title={Localization.createToothMesh} />{/* create tooth meth */}
          <MenuButton theme={globalContext.theme} icon="stripTooth" title={Localization.stripTooth} />{/* strip tooth */}
          <MenuButton theme={globalContext.theme} icon="transformTooth" title={Localization.transformTooth} />{/* transform tooth */}
        </MenuButtonGroup>
      )}

      <MenuButtonGroup>
        <MenuButton theme={globalContext.theme} icon="undo" title={Localization.undo} />{/* undo */}
        <MenuButton theme={globalContext.theme} icon="redo" title={Localization.redo} />{/* redo */}
        <MenuButton theme={globalContext.theme} icon="cancel" title={Localization.deleteObject} />{/* delete object */}
      </MenuButtonGroup>
    </TopMenuLayout>
  );
};

export default MenuContainer;
