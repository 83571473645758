import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import CustomScroll from 'react-custom-scroll';

import IconTimes from 'components/icons/Times';
import IconCheck from 'components/icons/Check';
import { palette } from 'modules/defines/styles';
import 'react-custom-scroll/dist/customScroll.css';
import Localization from 'i18n';

const FilesArea = styled.div`
  border-radius: 5px;
  background-color: ${palette.bg};
`;
const FilesEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const Group = styled.div`
  display: flex;
  padding: 20px 20px 0;
  &:last-child {
    padding-bottom: 20px;
  }
`;
const GroupTitle = styled.div`
  max-width: 180px;
  color: ${palette.gray};
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GroupBranch = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  background-image: 
    linear-gradient(to right,
      ${palette.border} 0,
      ${palette.border} 10px,
      transparent 10px,
      transparent 100%),
    linear-gradient(to bottom,
      transparent 0,
      transparent 12px,
      ${palette.border} 12px,
      ${palette.border} calc(100% - 10px),
      transparent calc(100% - 10px),
      transparent 100%);
  background-repeat: no-repeat;
  background-size: 100% 1px, 1px 100%;
  background-position: 5px 12px, 15px 0;
`;

const ButtonConfirm = styled.button`
  visibility: hidden;
  flex-shrink: 0;
  margin-left: 10px;
  margin-top: 5px;
  color: ${palette.blue};
  font-size: 11px;
  svg {
    margin-right: 5px;
    margin-bottom: 3px;
    vertical-align: middle;
  }
  svg path {
    fill: ${palette.blue};
  }
`;
const ButtonCancel = styled.button`
  visibility: hidden;
  flex-shrink: 0;
  margin-left: 10px;
  margin-top: 5px;
  color: ${palette.red};
  font-size: 11px;
  svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-bottom: 3px;
    vertical-align: middle;
  }
  svg path {
    fill: ${palette.red};
  }
`;
const GroupItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 12px;
  color: ${palette.gray};
  font-size: 13px;
  cursor: pointer;
  background-image: linear-gradient(to right,
    ${palette.border} 0,
    ${palette.border} 10px,
    transparent 10px,
    transparent 100%);
  background-repeat: no-repeat;
  background-size: 8px 1px;
  background-position: 0 18px;

  &:hover {
    color: ${palette.blue};
    ${ButtonConfirm},
    ${ButtonCancel} {
      visibility: visible;
    }
  }
`;
const ItemName = styled.span`
  text-overflow: ellipsis;
`;
const ItemDate = styled.span`
  margin-left: 10px;
  margin-top: 2px;
  color: ${palette.disabled};
  font-size: 11px;
  white-space: nowrap;
`;

const FileList = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <FilesArea>
      {data.length > 0 ? (
        <CustomScroll heightRelativeToParent="280px">
          {data.map((value, i) => (
            <Group key={i}>
              <GroupTitle title={value.name}>{value.name}</GroupTitle>
              <GroupBranch data-id={value.id}>
                {value.AshleyLowerProjects.map((v, j) => (
                  <GroupItem key={j} data-id={v.id}>
                    <ItemName title={v.name}>{v.name}</ItemName>
                    <ItemDate>{v.created_at.split('T')[0]}</ItemDate>
                    <ButtonConfirm onClick={() => props.onOpen && props.onOpen(v.id)}><IconCheck />{Localization.openImage}</ButtonConfirm>
                    {v.name !== 'Origin STL File' && <ButtonCancel onClick={() => props.onDelete && props.onDelete(value.id, v.id)}><IconTimes />{Localization.remove}</ButtonCancel>}
                  </GroupItem>
                ))}
              </GroupBranch>
            </Group>
          ))}
        </CustomScroll>
      ) : (
        <FilesEmpty>{Localization.noImage}</FilesEmpty>
      )}
    </FilesArea>
  );
};

export default FileList;