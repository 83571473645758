export const pagePaths = {
  login: '/login',
  admin: '/admin',
  main: '/main',
  patientList: '/patient/list',
  patientRegi: '/patient/registration',
  patientDetail: '/patient/:patientId',
  viewer: '/viewer/:lowerProjectId', /* temporary */
  upload: '/upload',
  download: '/download',
};

export const apiPaths = {
  signup : '/user/public/signup',
  login : '/api/user/public/login',
  adminLogin : '/api/admin/public/admin-login',
  logout: '/api/user/logout',
  adminLogout : '/api/admin/public/admin-logout',
  patientList: '/api/patient/list',
  patientCreate: '/api/patient/create',
  update: '/api/patient/update',
  patientDetail: '/api/patient/detail',
  user: '/api/user',
  // patientDelete: '/api/patient/delete',
  fileUpload: '/api/patient/upload',
  initData: 'api/annotation/init',
  saveData: 'api/annotation/save',
  loadData: 'api/annotation/load',
  bulkDownload: '/api/admin/bulk-download',
  combinedDownload: '/api/admin/combind-bulk-downloads',

  // fileRead: '/api/file/stlCall', // deprecated
  fileDelete: '/api/patient/delete',

  projectDetails: '/api/project/details',
  getSTL: '/api/patient/getSTL',
};
