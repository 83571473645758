import React from 'react';

import DefaultButton from 'components/button/DefaultButton';
import GroupIcon from 'components/icons/Group';
import Localization from 'i18n';

const RegiButton = (props) => (
  <DefaultButton size="md" {...props}><GroupIcon />{Localization.uploadData}</DefaultButton>
);

export default RegiButton;