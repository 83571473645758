import React, { useRef, useContext, useEffect } from 'react';
import * as THREE from 'three';
import {
    extend, Canvas, useThree, useFrame,
} from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import ViewerContext from 'modules/context/ViewerContext';
import Meshes from 'components/viewer/3d/Meshes';

const Main3DCanvas = () => {
    const viewerContext = useContext(ViewerContext);
    const trackballControlsRef = useRef();
    const cameraRef = useRef();

    extend({ TrackballControls });

    const CameraControls = () => {
        const { camera, gl } = useThree();
        // ref to the controls, so that we can update them on every frame via useFrame
        useFrame(() => trackballControlsRef.current && trackballControlsRef.current.update());
        return (
            <trackballControls ref={trackballControlsRef} staticMoving args={[camera, gl.domElement]} {...viewerContext.trackballSettings} />
        );
    };

    useEffect(() => {
        // store controls & camera in context for general access
        viewerContext.update({ cameraRef, trackballControlsRef });
    }, []);

    const dimmer = 0.75;

    return (
        <Canvas>
            {/* establish scene lighting & camera controls */}
            <PerspectiveCamera ref={cameraRef} makeDefault {...viewerContext.camSettings}>
                <ambientLight intensity={0.15 * dimmer} />
                <pointLight position={[-200, 200, 200]} intensity={0.45 * dimmer} />
                <pointLight position={[200, 200, 200]} intensity={0.65 * dimmer} />
                <pointLight position={[0, 200, -200]} intensity={1.0 * dimmer} />
            </PerspectiveCamera>
            <CameraControls />
            <Meshes viewerContext={viewerContext} trackballControlsRef={trackballControlsRef} />
        </Canvas>
    );
};

export default Main3DCanvas;
