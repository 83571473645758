import React from 'react';

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <path d="M4.528 5.5L.2 1.174C-.067.905-.067.47.201.2c.269-.268.704-.268.973 0L5.5 4.528 9.826.2c.269-.268.704-.268.973 0 .268.269.268.704 0 .973L6.472 5.5 10.8 9.826c.268.269.268.704 0 .973-.269.268-.704.268-.973 0L5.5 6.472 1.174 10.8c-.269.268-.704.268-.973 0-.268-.269-.268-.704 0-.973L4.528 5.5z" transform="translate(-666 -27) translate(666 27)" />
      </g>
    </g>
  </svg>
);

export default Close;
