import React from 'react';

const ShapeAuto = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M1.452 11.817h1.215c.38.25.146.85 4.833 1.04 4.687.19 7.286-3.076 7.286-6.857H16c0 4.411-3.813 8-8.5 8-4.687 0-6.35-1.618-6.048-2.183z" transform="translate(-749 -21) translate(749 21)" />
        <text fontFamily="Montserrat-Bold, Montserrat" fontSize="10" fontWeight="bold" transform="translate(-749 -21) translate(749 21)">
          <tspan x="4.17" y="10">A</tspan>
        </text>
        <path d="M2 10.333c-.368 0-.667.299-.667.667 0 .368.299.667.667.667.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667M2 13c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2M15 4.333c-.368 0-.667.299-.667.667 0 .368.299.667.667.667.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667M15 7c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2M7 10v10l2.4-2.5H13L7 10zm1.2 3.453l2.237 2.797H8.903l-.351.366-.352.367v-3.53z" transform="translate(-749 -21) translate(749 21)" />
      </g>
    </g>
  </svg>
);

export default ShapeAuto;
