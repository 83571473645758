import React, { useContext, useState } from 'react';
import ViewerContext from 'modules/context/ViewerContext';
import styled from 'styled-components/macro';
import IconGrip from 'components/icons/Grip';
import { zIndex } from 'modules/defines/styles';

import {
  IconTooth, IconSideMapOff, IconSideShapeOff,
  IconSideTeethAllOff, IconSideTeethViewBaseOff, IconSideViewOff,
  IconSideTeethViewMoveOff, IconSideViewOutlineOff, IconSideTeethBottomOff,
  IconSideTeethNoneOff, IconSideTeethTopOff,
} from 'components/icons';

const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  background-color: ${(props) => (props.theme === 'dark' ? '#636363' : '#d8d8d8')}; // default is 'light'
  box-shadow: ${(props) => (props.theme === 'dark' ? '0 0 4px 5px rgba(47, 47, 47, 0.34)' : '0 0 4px 5px rgba(255, 255, 255, 0.34)')};
  border-radius: 20px;
  pointer-events: fill;

  & + & {
    margin-top: 115px;
  }
`;
const GripButton = styled.button`
  height: 30px;
  &:active svg {
    fill: #a7a7a7;
  }
  cursor: default;
`;

export const ViewerMenuGroup = (props) => (
  <ButtonGroupWrapper theme={props.theme}>
    <GripButton type="button">
      <IconGrip />
    </GripButton>
    {props.children}
  </ButtonGroupWrapper>
);

const ButtonWrapper = styled.div`
  position: relative;
  flex-shrink: 1;

  & + & {
    margin-top: 10px;
  }
`;
const ButtonInner = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  ${props => (props.isActive && 'background-color: #4295DB;')}

  &:hover {
    .icon-tooth {
      g > path:first-child {
        fill: #4295DB; // TODO:: temp value
      }
      g > path:last-child {
        fill: #fff; // TODO:: temp value
      }
    }

    .icon-teethShape {
      path {
        fill: #4295DB;
      }
    }

    svg.icon-objectHide {
      & > g > path {
        fill: #4295DB;
      }
      & > g > g > path {
        fill: #4295DB;
      }
      & > g > path:last-child {
        fill: #fff;
      }
    }

    .icon-objectShow {
      g > path:first-child {
        fill: #4295DB; // TODO:: temp value
      }
    }

    .icon-teeth {
      g > g path {
        fill: #4295DB;
      }
    }

    .icon-toothCopy {
      g > g > path {
        fill: #4295DB;
      }
    }

    .icon-toothCheck {
      g > g > path {
        fill: #4295DB;
      }
    }

    .icon-toothBase {
      g > path {
        fill: #4295DB;
        stroke: #fff;
      }
    }
  }
`;

const ButtonSubGroup = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  background-color:#d8d8d8;
  border-radius: 20px 0 0 20px;
  padding: 0 10px;

  & > button + button {
    margin-left: 4px;
  }
`;

const MenuSubGroup = (props) => {
  const { context } = props;
  const { display, updateDisplay } = context;

  return (
    <ButtonSubGroup>
      <ButtonInner type="button" isActive={display.teeth === 'showBoth'} onClick={() => updateDisplay({ teeth: 'showBoth' })}><IconSideTeethAllOff /> </ButtonInner>
      <ButtonInner type="button" isActive={display.teeth === 'lower'} onClick={() => updateDisplay({ teeth: 'lower' })}><IconSideTeethTopOff /></ButtonInner>
      <ButtonInner type="button" isActive={display.teeth === ''} onClick={() => updateDisplay({ teeth: 'upper' })}><IconSideTeethBottomOff /> </ButtonInner>
      <ButtonInner type="button" isActive={display.teeth === 'none'} onClick={() => updateDisplay({ teeth: 'none' })}><IconSideTeethNoneOff /> </ButtonInner>
    </ButtonSubGroup>
  );
};


export const ViewerMenuButton = (props) => {
  const viewerContext = useContext(ViewerContext);
  const [showMenu, setShowMenu] = useState(false);

  return (  
    <ButtonWrapper>
      <ButtonInner
        isActive={showMenu}
        type="button" onClick={(e) => { 

          if (props.onClick) { props.onClick(e); }

          if (props.icon === 'teeth') {
            setShowMenu(!showMenu);
          }
        }}
      >
        {props.icon === 'tooth' && <IconTooth className="icon-tooth" />}
        {/* TODO:: make icons */}
        {props.icon === 'teethShape' && <IconSideShapeOff className="icon-teethShape" />}
        {props.icon === 'objectHide' && <IconSideMapOff className="icon-objectHide" />}
        {props.icon === 'objectShow' && <IconSideViewOff className="icon-objectShow" />}
      
      
        {props.icon === 'teeth' && <IconSideTeethAllOff className="icon-teeth" />}
        {props.icon === 'toothCopy' && <IconSideTeethViewMoveOff className="icon-toothCopy" />}
        {props.icon === 'toothCheck' && <IconSideViewOutlineOff className="icon-toothCheck" />}
        {props.icon === 'toothBase' && <IconSideTeethViewBaseOff className="icon-toothBase" />}
      </ButtonInner>

      {(props.icon === 'teeth' && showMenu) && <MenuSubGroup context={viewerContext} />}
    </ButtonWrapper>
  ); 
};

const ViewrCtrlMenuLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  z-index: ${zIndex.floatingMenu};
  width: 40px;
  pointer-events: none;
`;

export const ViewrCtrlMenuLayout = (props) => (
  <ViewrCtrlMenuLayoutWrapper>{props.children}</ViewrCtrlMenuLayoutWrapper>
);
