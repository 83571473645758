import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import {
    extend, Canvas, useThree, useFrame,
} from '@react-three/fiber';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { Marker, Axis, MarkerHighLight } from 'components/viewer/3d/Helpers/Markers';
import ToothAxis from 'components/viewer/3d/Helpers/ToothAxis';
import LingualTipBuccalPlane from 'components/viewer/3d/Helpers/LingualTipBuccalPlane';
import { useControls } from 'leva'
import { useKeyEvent, useMouseButtonState } from 'modules/context/ViewerContext';

const degToRad = (degrees) => degrees * (Math.PI / 180);
const radToDeg = (rad) => rad / (Math.PI / 180);

const GingivaMaterial = ({ viewerContext }) => {
    const translucent = viewerContext.labelingStepName.match(/Adjust tooth axis/);
    const clippingPlaneRef = useRef(null);
    useEffect(() => {
        if (clippingPlaneRef.current && !viewerContext.clippingPlaneRef)
            viewerContext.update({ clippingPlaneRef });
    }, []);
    const clipArgs = viewerContext.clippingPlaneRef && viewerContext.clippingPlaneRef.current
        ? [viewerContext.clippingPlaneRef.current.normal, viewerContext.clippingPlaneRef.current.position]
        : [new THREE.Vector3(1, 0, 0), 1e6];
    return (
        <meshPhongMaterial
            type="MeshPhongMaterial"
            color="#e97d72" emissive={0} specular="#bcb7a3" shininess={7.36} transparent opacity={translucent ? 0.75 : 1.0}
        >
            <plane ref={clippingPlaneRef} attach="clippingPlanes-0" args={clipArgs} />
        </meshPhongMaterial>
    );
}

const GingivaMeshwireframe = ({ position, geometry }) => {
    const geom2 = new THREE.WireframeGeometry(geometry);

    return (
        <lineSegments
            geometry={geom2} position={position} scale={[1, 1, 1]}
            rotation={[degToRad(0), degToRad(0), degToRad(0)]}
        >
            <lineBasicMaterial color="#444" transparent />
        </lineSegments>
    );
};

const orientationEulerAngles = {
    front: new THREE.Euler(Math.PI / 2, 0, 0),
    top: new THREE.Euler(0, 0, 0),
    bottom: new THREE.Euler(-Math.PI, 0, -Math.PI),
    left: new THREE.Euler(-Math.PI / 2, 0, -Math.PI / 2),
    right: new THREE.Euler(-Math.PI / 2, 0, Math.PI / 2),
};

const Meshes = ({ position, viewerContext, trackballControlsRef }) => {
    // const viewerContext = useContext(viewerContext);   why doesn't this work!!??  always yields {} empty object here, but nowhere else up the component nesting
    const { camera, gl, scene, renderer } = useThree();
    const [upperMeshRefCurrent, upperMeshRef] = useState();
    const [lowerMeshRefCurrent, lowerMeshRef] = useState();
    const [baseGroupCurrent, baseGroup] = useState();
    const [meshGroupCurrent, meshGroup] = useState();
    const shiftKeyDown = useRef(false);
    // const buttonState = useMouseButtonState();
    const mouseDown = useRef(false);

    const callback = event => {
        trackballControlsRef.current.enabled = !event.value;
    };

    // track shift-key down state in viewerContext.shiftDown (move this to ViewerContainer, perhaps)
    useKeyEvent((shiftDown) => {
        shiftKeyDown.current = shiftDown;
        if (trackballControlsRef.current)
            trackballControlsRef.current.noRotate = !shiftDown;
    });

    useEffect(() => {
        // store the orientation-setting function in the viewerContext for others to call
        viewerContext.update({
            meshGroup: meshGroupCurrent,
            upperMeshRefCurrent,
            lowerMeshRefCurrent,
            setOrientation: (orientation) => {
                if (meshGroupCurrent)
                    meshGroupCurrent.setRotationFromEuler(orientationEulerAngles[orientation]);
            } });
        viewerContext.update({ meshGroup: meshGroupCurrent });
        // set initial orientation
        if (false && meshGroupCurrent)
            meshGroupCurrent.setRotationFromEuler(orientationEulerAngles[viewerContext.display.orient]);
    }, [meshGroupCurrent]);

    const clickMesh = (event, mesh) => {
        // handle clicks on the main gingiva mesh
        mouseDown.current = true;
        if (!shiftKeyDown.current) {  // only if transform-control is not active
            switch (viewerContext.labelingStepName) {
                case 'Pick tooth': // select current-numbered tooth and position camera above it
                    meshGroupCurrent.updateWorldMatrix(true, true);
                    trackballControlsRef.current.target = event.point;
                    viewerContext.autoStep({ meshPointClicked: event.point });
                    viewerContext.trackballControlsRef.current.rotateSpeed = 10;
                    break;

                case 'Pick mesial point': // grab mesial & distal points setting & bump step
                case 'Pick distal point':
                case 'Pick tooth cusp tip':
                case 'Pick lingual groove point':
                case 'Pick buccal groove point':
                case 'Pick FA point':
                    viewerContext.autoStep({ meshPointClicked: event.point });
                    break;

                default:
                    break;
            }
        }
    };

    // the following allows pressing anddragging on the mesh, but it is very slow and unresponsive, so disabled for now.
    const moveOnMesh = (event, mesh) => {
        // if (mouseDown.current && viewerContext.labelingSteps[viewerContext.labelingStep].holdAtStep) {
        //     const stepLabelData = viewerContext.labels[viewerContext.display.teeth][viewerContext.toothNumber];
        //     const point = ['mesial', 'distal', 'tip', 'lingual', 'buccal', 'FAPoint'][viewerContext.labelingStep - 1];
        //     stepLabelData[point] = event.point;
        //     viewerContext.update({ labels: viewerContext.labels });
        //     // console.log('moveOnMesh', event.point);
        // }
    };

    const releaseOnMesh = (event, mesh) => {
        // mouseDown.current = false;
   };

    const LabelMarkers = ({ viewerContext }) => (
        Object.entries(viewerContext.labels[viewerContext.display.teeth]).map(([tooth, labelData]) => {
            const curTooth = () => tooth === viewerContext.toothNumber.toString();
            const curStep = viewerContext.labelingStep;
            const active = (step) => curTooth(tooth) && curStep === step;
            const showLingualBuccalTipPoints = () => true; // curTooth(tooth) && !viewerContext.labelingStepName.match(/axis/);
            const showLingualBuccalPlane = () => curTooth(tooth) && labelData.tip && labelData.lingual && labelData.buccal  && viewerContext.labelingStepName.match(/FA|facial/);
            const markerData = (step) => ({ step, teeth: viewerContext.display.teeth, tooth: Number.parseInt(tooth, 10) });
            // const showFAAxis = () => curTooth(tooth) && labelData.FAPoint && !viewerContext.labelingStepName.match(/Adjust tooth axis/);
            // const showMesialDistalLine = () => curTooth(tooth) && labelData.mesial && labelData.distal && viewerContext.labelingStepName !== 'Rotate facial axis';
            return (
                <React.Fragment key={tooth}>

                    { labelData.mesial && <Marker active={active(1)} viewerContext={viewerContext} color={0x40ff40} position={labelData.mesial} data={markerData(1)} /> }
                    { active(1) && (labelData.mesial && <MarkerHighLight viewerContext={viewerContext} position={labelData.mesial} transparent={true} opacity={0.5} />) }
                    { labelData.distal && <Marker active={active(2)} viewerContext={viewerContext} color={0xFF8043} position={labelData.distal} data={markerData(2)} /> }
                    { active(2) && (labelData.distal && <MarkerHighLight viewerContext={viewerContext} position={labelData.distal} transparent={true} opacity={0.5} />) }
                    {/*{ showToothAxis() &&*/}
                    {/*    <Axis viewerContext={viewerContext} color={0xFF8043} position={labelData.toothAxisCenter} rotation={labelData.toothAxisDirection} length={labelData.toothAxisLength} /> }*/}
                    { showLingualBuccalTipPoints() && (
                        <>
                            { labelData.tip && <Marker active={active(3)} viewerContext={viewerContext} color={0xff3399} position={labelData.tip} data={markerData(3)} /> }
                            { active(3) && ( labelData.tip && <MarkerHighLight viewerContext={viewerContext} position={labelData.tip} transparent={true} opacity={0.5} />) }
                            { labelData.lingual && <Marker active={active(4)} viewerContext={viewerContext} color={0x00ffff} position={labelData.lingual} data={markerData(4)} /> }
                            { active(4) && ( labelData.lingual && <MarkerHighLight viewerContext={viewerContext} position={labelData.lingual} transparent={true} opacity={0.5} />) }
                            { labelData.buccal && <Marker active={active(5)} viewerContext={viewerContext} color={0xff0000} position={labelData.buccal} data={markerData(5)} /> }
                            { active(5) && ( labelData.buccal && <MarkerHighLight viewerContext={viewerContext} position={labelData.buccal} transparent={true} opacity={0.5} />) }
                        </>
                    )}
                    { labelData.FAPoint && <Marker active={active(6)} viewerContext={viewerContext} color={0xffff40} position={labelData.FAPoint} data={markerData(6)} /> }
                    { active(6) && ( labelData.FAPoint && <MarkerHighLight viewerContext={viewerContext} position={labelData.FAPoint} transparent={true} opacity={0.5} />) }

                    { showLingualBuccalPlane() && <LingualTipBuccalPlane viewerContext={viewerContext} /> }
                    {/*{ showFAAxis() && <FAAxis viewerContext={viewerContext} color={0xffff40} position={labelData.FAPoint} /> }*/}
                    { ((curTooth(tooth) && labelData.toothAxisCenter) || viewerContext.labelingStepName.match(/tooth axis/)) && <ToothAxis viewerContext={viewerContext} /> }

                    {/*{ showMesialDistalLine() && <MesiallDistalLine viewerContext={viewerContext} /> }*/}

                    {/* debugging.. */}
                    {/*{ labelData.mdPlaneNormal && <arrowHelper args={[labelData.mdPlaneNormal, labelData.midPoint, 20]} /> }*/}
                    {/*{ labelData.toothAxisYZPlane && <arrowHelper args={[labelData.toothAxisYZPlane.normal, labelData.toothAxisCenter, 20, 0xff0000]} /> }*/}
                </React.Fragment>
            );
        })
    );

    return (
        <group ref={baseGroup}>
            <group ref={meshGroup}>
                { ['showBoth', 'upper'].includes(viewerContext.display.teeth) && viewerContext.upperGeometry &&
                    <mesh
                        ref={upperMeshRef} geometry={viewerContext.upperGeometry}
                        onPointerDown={e => clickMesh(e, upperMeshRefCurrent)}
                        onPointerMove={e => moveOnMesh(e, upperMeshRefCurrent)}
                        onPointerUp={e => releaseOnMesh(e, upperMeshRefCurrent)}
                    >
                        <GingivaMaterial viewerContext={viewerContext} />
                        { viewerContext.showWireframe && <GingivaMeshwireframe geometry={viewerContext.upperGeometry} position={[0, 0, 0]} />}
                    </mesh>
                }
                { ['showBoth', 'lower'].includes(viewerContext.display.teeth) && viewerContext.lowerGeometry &&
                    <mesh
                        ref={lowerMeshRef} geometry={viewerContext.lowerGeometry}
                        onPointerDown={e => clickMesh(e, lowerMeshRefCurrent)}
                        onPointerMove={e => moveOnMesh(e, lowerMeshRefCurrent)}
                        onPointerUp={e => releaseOnMesh(e, lowerMeshRefCurrent)}
                    >
                        <GingivaMaterial viewerContext={viewerContext} />
                        {viewerContext.showWireframe && <GingivaMeshwireframe geometry={viewerContext.lowerGeometry} position={[0, 0, 0]} />}
                    </mesh>
                }
                <LabelMarkers viewerContext={viewerContext} />
                {/*<axesHelper args={[65]} position={[0, 0, 0]} rotation={[0, 0, 0]} />*/}
            </group>
        </group>
    );
};

export default Meshes;