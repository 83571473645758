import React from 'react';
import styled from 'styled-components/macro';

import { fontEn, palette } from 'modules/defines/styles';

const Button = styled.button`
  min-width: ${props => (props.theme === 'paging' ? '16px' : '19px')};
  height: ${props => (props.theme === 'paging' ? '16px' : '19px')};
  color: #393939;
  font-size: 13px;

  ${props => props.selected && (`
    color: ${palette.blue};
    font-weight: bold;
  `)}

  ${props => props.active && (`
    color: ${palette.blue};
    svg path {
      fill: ${palette.blue};
    }
  `)}

  &:hover {
    color: ${palette.blue};
    svg path {
      fill: ${palette.blue};
    }
  }

  &:disabled {
    color: #bfbfbf;
    svg path {
      fill: #bfbfbf;
    }
  }
`;

export const IndexButton = (props) => (
  <Button type="button" {...props}>{props.children}</Button>
);

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 380px;
  padding: 0 15px;
  white-space: nowrap;
`;

// export const IndexButtons = (props) => ( 한글 인덱스 버튼
//   <ButtonsWrapper>{props.children}</ButtonsWrapper>
// );

const PagingWrapper = styled.div`
  text-align: center;
  button {
    font-family: ${fontEn};
    &:hover {
      color: ${palette.blue};
    }
  }
`;

export const IndexPaging = (props) => (
  <PagingWrapper>{props.children}</PagingWrapper>
);