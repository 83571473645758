import React, { useEffect } from 'react';

import MainLayout from 'components/layout/MainLayout';
import GlobalContainer from 'containers/common/GlobalContainer';
import ViewerContainer from 'containers/viewer/ViewerContainer';
import LabelStep from 'containers/viewer/LabelStep';
import ViewerMenuContainer from 'containers/viewer/ViewerMenuContainer';
import OverviewContainer from 'containers/viewer/OverviewContainer';
import HudContainer from 'containers/viewer/HudContainer';
import MenuContainer from 'containers/common/MenuContainer';
import ViewerContext, { useViewerContext } from 'modules/context/ViewerContext';

const Viewer = (props) => {
  const viewerContextState = useViewerContext();

  useEffect(() => {
    document.body.id = 'viewer-page';
  }, []);

  return (
    <ViewerContext.Provider value={viewerContextState}>
      <MainLayout>
        <GlobalContainer />
        {/*<MenuContainer />*/}
        <LabelStep />
        <HudContainer />
        <OverviewContainer />
        {viewerContextState.step > 0 && <ViewerMenuContainer />}
        <ViewerContainer lowerProjectId={props.match.params.lowerProjectId} />
      </MainLayout>
    </ViewerContext.Provider>
  );
};

export default Viewer;
