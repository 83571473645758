import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import LoginForm, { FormWrapper, InputForm, InputItem, Label, Input, ShowButton, InputButton, FormErrorMessage, FormFooter, FooterLinks, FormRemember } from 'components/forms/LoginForm';
import IconEye from 'components/icons/Eye';
import LoginButton from 'components/button/LoginButton';
import Checkbox from 'components/forms/Checkbox';
import authAPI from 'modules/api/auth';
import { pagePaths } from 'modules/defines/paths';
import GlobalContext from 'modules/context/GlobalContext';
import { emailRegex } from 'utils/regex';
import { Base64Encode } from 'utils/Base64';
import Localization from 'i18n';

const LoginContainer = () => {
  const history = useHistory();
  const globalState = useContext(GlobalContext);
  const [cookies, setCookie, removeCookie] = useCookies();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: cookies.email || '',
    password: '',
    remember: !!cookies.email,
  });
  const [errorMessage, setError] = useState('');

  const validate = () => {
    // 이메일 비밀번호 undifined 체크
    if (!formData.email || !formData.password) {
      return setError(Localization.invalidInput);
    }

    // 이메일 형식 체크
    if (!emailRegex.test(formData.email)) {
      return setError(Localization.invalidEmail);
    }

    return true;
  };

  const handleChange = (e) => {
    const newObj = {};
    newObj[e.target.name] = e.target.value;
    setFormData(prev => ({ ...prev, ...newObj }));

    if (errorMessage !== '') setError('');
  };
  const handleKeyDown = (e) => {
    // enter key submit
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleCheck = (value) => {
    setFormData(prev => ({ ...prev, remember: value }));
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const rqData = {
      email: formData.email,
      password: Base64Encode(formData.password), // 패스워드 base64로 인코딩(서버에서 디코딩)
    };

    // 로그인
    authAPI.login(rqData).then(response => {
      if (response.success) {
        if (formData.remember) {
          setCookie('email', formData.email, { path: '/', maxAge: 60 * 60 * 24 * 30 }); // 이메일 아이디 기억하기 30일
        } else {
          removeCookie('email');
        }

        // 의사명, 병원명 저장
        globalState.set(prev => ({ ...prev, user: response.data }));
        setCookie('user', response.data, { path: '/', maxAge: 60 * 60 * 24 * 7 }); // 로그인 상태 저장 7일

        history.push(pagePaths.patientList.replace(':userId', response.data.id));
      } else {
        setError(response.data);
      }
    });
  };

  useEffect(() => {
    // 로그인 된 상태이면 patient list로 redirect
    if (cookies.user) {
      history.push(pagePaths.patientList);
    }
  }, []);

  return (
    <LoginForm>
      <FormWrapper>
        <InputForm>
          <InputItem>
            <Label htmlFor="email">email</Label>
            <Input>
              <input
                type="email" name="email" id="email" maxLength="30" value={formData.email}
                onChange={handleChange}
              />
            </Input>
          </InputItem>
          <InputItem>
            <Label htmlFor="password">password</Label>
            <Input>
              <input
                type={showPassword ? 'text' : 'password'} name="password" id="password" maxLength="30"
                onChange={handleChange} onKeyDown={handleKeyDown}
              />
              <ShowButton type="button" active={showPassword} onClick={() => setShowPassword(!showPassword)}><IconEye /></ShowButton>
            </Input>
          </InputItem>
        </InputForm>

        <InputButton>
          <LoginButton onClick={handleSubmit} />
        </InputButton>
      </FormWrapper>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>

      <FormFooter>
        <FooterLinks>
          <a href="https://cloud.ddhinc.net" rel="noreferrer" target="_blank">{Localization.signup}</a>
          <a href="https://cloud.ddhinc.net" rel="noreferrer" target="_blank">{Localization.findPassword}</a>
        </FooterLinks>
        <FormRemember>
          <Checkbox checked={formData.remember} onChange={handleCheck}>{Localization.rememberIID}</Checkbox>
        </FormRemember>
      </FormFooter>
    </LoginForm>
  );
};

export default LoginContainer;