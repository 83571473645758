import React from 'react';
import styled from 'styled-components/macro';

const MainWrpper = styled.div`
  position: relative;
  min-width: 1200px;
  min-height: 800px;
  height: calc(100vh - 30px);
`;

const MainLayout = (props) => (
  <MainWrpper>
    {props.children}
  </MainWrpper>
);

export default MainLayout;