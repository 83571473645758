import React from 'react';
import styled from 'styled-components/macro';

import { fontEn, palette } from 'modules/defines/styles';

const FormsWrapper = styled.div`
  width: 520px;
`;

export const Forms = (props) => (
  <FormsWrapper>{props.children}</FormsWrapper>
);

export const FormGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;

  .btn-delete {
    position: absolute;
    right: -60px;
    top: 7px;
  }
`;

export const FormArea = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;

  textarea {
    display: block;
    width: 100%;
    height: 200px;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    background-color: ${palette.white};
    color: #656565;
    font-size: 13px;

    &:focus {
      border-color: ${palette.blue};
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 250px;
  margin-top: 25px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${props => (props.focus ? palette.blue : palette.form)};

  input[type='text'] {
    width: 100%;
    height: 22px;
    border: 0;
    font-size: 15px;
    background: transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  input[name=name] {
    font-weight: 500;
  }
  input[name=date_of_birth_year],
  input[name=date_of_birth_month],
  input[name=date_of_birth_day] {
    text-align: center;
    font-family: ${fontEn};

    &:focus {
      background-color: ${palette.form};
    }
  }
  input[name=date_of_birth_year] {
    width: 40px;
  }
  input[name=date_of_birth_month],
  input[name=date_of_birth_day] {
    width: 20px;
  }
`;

export const Label = styled.label`
  display: ${props => (props.theme === 'hidden' ? 'none' : 'block')};
  flex-shrink: 0;
  width: 125px;
  color: ${palette.gray};
  font-size: 14px;
  font-family: ${fontEn};
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const FormButtonInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TextError = styled.div`
  color: ${palette.red};
`;