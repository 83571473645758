import React from 'react';

const SideTeethBottomOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-837 -529) translate(837 529) translate(0 .35)">
        <path fill={props.color || '#fff'} d="M9.29 2.652c-.173 0-.314.14-.314.313v2.457c0 .762.51 1.21.987 1.376.424.15 1.014.13 1.461-.322V2.652H9.29zm1.159 5.209c-.268 0-.54-.045-.807-.14-.999-.344-1.645-1.248-1.645-2.3V2.966c0-.711.58-1.292 1.293-1.292h3.113v4.879c.027.136-.004.284-.096.4-.464.588-1.144.909-1.858.909z" />
        <path fill={props.color || '#fff'} d="M13.431 7.86c-.714 0-1.394-.32-1.858-.908-.168-.212-.132-.52.08-.687.214-.167.52-.131.688.08.458.579 1.114.615 1.576.454.477-.167.987-.616.987-1.377V2.965c0-.172-.14-.313-.314-.313h-2.624c-.27 0-.489-.22-.489-.49s.22-.488.49-.488h2.623c.713 0 1.293.58 1.293 1.29v2.458c0 1.05-.645 1.954-1.644 2.3-.268.093-.54.139-.808.139" />
        <path fill={props.color || '#fff'} d="M16.868 7.138c-.714 0-1.394-.32-1.858-.909-.168-.212-.131-.52.08-.687.213-.168.52-.13.688.081.457.578 1.113.613 1.576.453.477-.167.987-.615.987-1.377V2.173c0-.135-.11-.244-.244-.244h-1.96c-.271 0-.49-.219-.49-.489s.219-.489.49-.489h1.96c.674 0 1.223.548 1.223 1.222V4.7c0 1.051-.646 1.955-1.645 2.3-.267.094-.54.14-.807.14" />
        <path fill={props.color || '#fff'} d="M20.304 6.187c-.714 0-1.394-.32-1.859-.909-.167-.212-.13-.52.081-.687.214-.167.52-.131.688.081.456.578 1.114.614 1.576.453.477-.167.987-.615.987-1.377V1.222c0-.135-.11-.244-.244-.244h-1.96c-.27 0-.49-.219-.49-.489 0-.271.22-.489.49-.489h1.96c.674 0 1.222.548 1.222 1.222v2.526c0 1.051-.645 1.955-1.644 2.3-.267.094-.539.139-.807.139M7.013 7.138c-.268 0-.54-.045-.808-.139-1-.345-1.644-1.249-1.644-2.3V2.173C4.56 1.5 5.11.951 5.784.951h1.96c.27 0 .49.218.49.49 0 .27-.22.488-.49.488h-1.96c-.134 0-.244.11-.244.244V4.7c0 .762.51 1.21.987 1.377.462.16 1.118.125 1.576-.453.167-.212.476-.249.687-.08.212.166.248.474.08.686-.463.588-1.144.91-1.857.91" />
        <path fill={props.color || '#fff'} d="M3.576 6.187c-.268 0-.54-.045-.808-.139-.999-.346-1.644-1.249-1.644-2.3V1.222C1.124.548 1.674 0 2.347 0h1.96c.27 0 .49.218.49.489 0 .27-.22.489-.49.489h-1.96c-.135 0-.244.109-.244.244v2.526c0 .761.51 1.21.987 1.377.462.161 1.12.125 1.576-.453.168-.212.476-.248.688-.081.212.167.248.475.08.687-.464.589-1.144.909-1.858.909" />
        <path fill={props.color || '#fff'} d="M11.92 10.65C5.454 10.65.39 8.225.39 5.129c0-.74.281-1.45.84-2.114.171-.207.482-.235.688-.06.207.175.234.482.06.689-.405.484-.61.983-.61 1.485 0 2.462 4.832 4.544 10.552 4.544S22.473 7.59 22.473 5.128c0-.488-.195-.972-.577-1.443-.17-.21-.139-.517.071-.687.21-.17.518-.14.688.07.53.649.797 1.343.797 2.06 0 3.096-5.066 5.523-11.532 5.523" mask="url(#w6nmjidcsb)" />
      </g>
    </g>
  </svg>
);

export default SideTeethBottomOff;