import React from 'react';

const SideTeethViewMoveOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19" className={props.className}>
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M18.68 14.09C17.17 15.522 11.935 16 10.5 16c-1.436 0-6.519-.36-8.18-1.91C.656 12.54.513 8.238.14 6.377c-.382-1.902.027-3.81 1.095-5.107C1.899.462 2.745 0 3.556 0c2.085 0 3.23.467 4.24.88.31.127.616.252.934.358.168.066.34.136.517.207 1.794.725 4.027 1.627 5.882 1.627.249 0 .45-.2.45-.448s-.201-.448-.45-.448c-1.052 0-2.286-.34-3.485-.765.56-.123 1.052-.323 1.56-.531 1.01-.413 2.154-.88 4.24-.88.81 0 1.657.463 2.321 1.27 1.068 1.295 1.477 3.204 1.095 5.106-.374 1.861-.67 6.284-2.18 7.715z" transform="translate(-305 -610) translate(305.5 610) translate(0 3)" />
      </g>
      <path stroke="#979797" strokeDasharray="2 1" d="M21.68 14.09C20.17 15.522 14.935 16 13.5 16c-1.436 0-6.519-.36-8.18-1.91-1.663-1.55-1.806-5.853-2.18-7.714-.382-1.902.027-3.81 1.095-5.107C4.899.462 5.745 0 6.556 0c2.085 0 3.23.467 4.24.88.31.127.616.252.934.358.168.066.34.136.517.207 1.794.725 4.027 1.627 5.882 1.627.249 0 .45-.2.45-.448s-.201-.448-.45-.448c-1.052 0-2.286-.34-3.485-.765.56-.123 1.052-.323 1.56-.531 1.01-.413 2.154-.88 4.24-.88.81 0 1.657.463 2.321 1.27 1.068 1.295 1.477 3.204 1.095 5.106-.374 1.861-.67 6.284-2.18 7.715z" transform="translate(-305 -610) translate(305.5 610)" />
    </g>
  </svg>
);

export default SideTeethViewMoveOff;