import React from 'react';
import styled from 'styled-components/macro';
import { size } from 'modules/defines/styles';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  min-width: ${size.width};
  min-height: ${size.height};
`;

const ContentsLayout = (props) => (
  <Wrapper>
    {props.children}
  </Wrapper>
);

export default ContentsLayout;
