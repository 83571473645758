import React from 'react';
import styled from 'styled-components/macro';
import IconArrowAlt from 'components/icons/ArrowAlt';
import IconPlay from 'components/icons/Play';
import IconTarget from 'components/icons/Target';

const ViewerCtrlWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 33px;
  left: 50%;
  padding-left: 300px;
  transform: translateX(-50%);
  height: 24px;
`;

const ViewerCtrlGroup = (props) => (
  <ViewerCtrlWrapper>
    {props.children}
  </ViewerCtrlWrapper>
);

export default ViewerCtrlGroup;

const ViewerCtrlButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  width: 24px;
  height: 24px;

  & + & {
    margin-left: 15px;
  }

  &:hover {
    svg g {
      fill: #4296dc;
    }
  }
`;

export const ViewerCtrlButton = (props) => (
  <ViewerCtrlButtonWrapper type="button" {...props}>
    {props.icon === 'arrow' && <IconArrowAlt />}
    {props.icon === 'target' && <IconTarget />}
    {props.icon === 'play' && <IconPlay />}
  </ViewerCtrlButtonWrapper>
);
