import React from 'react';
import styled from 'styled-components/macro';

const ButtonGroupWrapper = styled.div`
  display: flex;
  & + & {
    margin-left: 25px;
  }
  button:first-child {
    border-radius: 12.5px 0 0 12.5px;
  }
  button:last-child {
    border-radius: 0 12.5px 12.5px 0;
  }
`;

const ButtonGroup = (props) => (
  <ButtonGroupWrapper>{props.children}</ButtonGroupWrapper>
);

export default ButtonGroup;
