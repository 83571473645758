import React from 'react';
import styled from 'styled-components/macro';

const ViewrWrpper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 300px;
  align-items: center;
  min-width: 1200px;
  min-height: 800px;
  height: 100%;
`;

const ViewerLayout = (props) => (
  <ViewrWrpper id="three-d-viewer">
    {props.children}
  </ViewrWrpper>
);

export default ViewerLayout;
