import React from 'react';
import styled from 'styled-components/macro';

import {
  IconUserAdd, IconUser, IconImport, IconExport,
  IconBack, IconForward, IconClose, IconShapeAuto,
  IconShape, IconShapeEdit, IconShapeSlice, IconTeethS,
  IconTeethDevide, IconTeethMove,
} from 'components/icons';
import { theme } from 'modules/defines/names';
import { palette } from 'modules/defines/styles';

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  width: 60px;
  height: 25px;
  border-left: solid 1px #eaebef;
  background-color: #b7bdbd;
  &:first-child {
    border-left: 0;
  }
  &:hover svg g { 
    fill: ${palette.blue};
  }

  .${theme.dark} & {
    border-color: #3f3f3f;
    background-color: #636363;
  }
`;

const MenuButton = (props) => (
  <ButtonWrapper type="button" {...props}>
    {props.icon === 'userPlus' && <IconUserAdd />}
    {/* TODO:: make icons */}
    {props.icon === 'user' && <IconUser />}
    {props.icon === 'import' && <IconImport />}
    {props.icon === 'export' && <IconExport />}

    {props.icon === 'undo' && <IconBack />}
    {props.icon === 'redo' && <IconForward />}
    {props.icon === 'cancel' && <IconClose />}

    {props.icon === 'autoShape' && <IconShapeAuto />}
    {props.icon === 'manualShape' && <IconShape />}
    {props.icon === 'editShape' && <IconShapeEdit />}
    {props.icon === 'cutMesh' && <IconShapeSlice />}

    {props.icon === 'createMesh' && <IconTeethS />}
    {props.icon === 'stripTooth' && <IconTeethDevide />}
    {props.icon === 'transformTooth' && <IconTeethMove />}
  </ButtonWrapper>
);

export default MenuButton;
