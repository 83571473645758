import React from 'react';

const ShapeSlice = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="15" height="21" viewBox="0 0 15 21">
    <defs>
      <path id="rxaa4hjk2a" d="M0 0L15 0 15 14 0 14z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M7.5 12c.828 0 1.5-.448 1.5-1s-.672-1-1.5-1-1.5.448-1.5 1 .672 1 1.5 1" transform="translate(-928 -20) translate(928 20)" />
      <g transform="translate(-928 -20) translate(928 20) translate(0 7)">
        <mask id="vaehzjaz5b" fill="#fff">
          <use xlinkHref="#rxaa4hjk2a" />
        </mask>
        <path fill="#FFF" d="M7.5 1.089c3.016 0 6.377 1.155 6.377 2.813 0 1.657-3.36 2.813-6.377 2.813S1.123 5.559 1.123 3.902c0-1.658 3.36-2.813 6.377-2.813m0 11.822c-3.017 0-6.377-1.155-6.377-2.813V5.982C2.434 7.084 4.775 7.804 7.5 7.804c2.725 0 5.066-.72 6.377-1.822v4.116c0 1.658-3.36 2.813-6.377 2.813m7.5-2.813V3.902C15 1.714 11.706 0 7.5 0 3.294 0 0 1.714 0 3.902v6.196C0 12.286 3.294 14 7.5 14c4.206 0 7.5-1.714 7.5-3.902z" mask="url(#vaehzjaz5b)" />
        <path fill="#FFF" d="M0.751 5.5L0.751 10.634 2.71 12.498 6 13.206 8.462 13.206 11.743 12.498 13.157 11.743 13.877 10.935 14.319 10.027 14.319 5.701 12.814 6.283 10.633 7 8.462 7.587 6 7.261 3.63 6.715 1.632 5.982z" mask="url(#vaehzjaz5b)" />
      </g>
      <g fill="#FFF">
        <path d="M6.936 7.6L4.567 5.231 5.231 4.567 7.6 6.936zM1.781 1.781l.876 2.337 1.46-1.46-2.336-.877zM2.25 6L0 0l6 2.25L2.25 6z" transform="translate(-928 -20) translate(928 20) rotate(45 2.879 9.414)" />
      </g>
    </g>
  </svg>
);

export default ShapeSlice;
