import { apiPaths } from 'modules/defines/paths';

import http from './axios';

const patientAPI = {
  user: (cookies) => {
    return new Promise((resolve, reject) => {
      http('', `${apiPaths.user}`, { 
        method: 'GET',
        headers: { 'Authorization': `bearer ${cookies.user.token}` },
       })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },

  list: (userId, cookies, sortData) => {
    return new Promise((resolve, reject) => {
      http('', `${apiPaths.patientList}`, { 
        method: 'POST',
        headers: { 'Authorization': `bearer ${cookies.user.token}` },
        data: sortData,
       })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },
  create: (data) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.patientCreate, { data })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },
  update: (data) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.patientUpdate, { method: 'put', data })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },
  detail: (userId, patientId) => {
    return new Promise((resolve, reject) => {
      http('', `${apiPaths.patientDetail}?user_id=${userId}&patient_id=${patientId}`, { method: 'get' })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },
  delete: (data) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.patientDelete, { data })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },
};

export default patientAPI;