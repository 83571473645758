import styled from 'styled-components/macro';
import { zIndex } from 'modules/defines/styles';

export const TopMenuLayout = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 20px;
  z-index: ${zIndex.floatingMenu};
  transform: translateX(-50%);
`;

export const ThemeButtonWrapper = styled.div`
  position: absolute;
  right : 30px;
  top : 50%;
  height: 15px;
  transform: translateY(-50%);
  z-index: ${zIndex.floatingMenu};
`;
