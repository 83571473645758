import React, { useState } from 'react';
import styled from 'styled-components/macro';
import ReactHtmlParser from 'react-html-parser';

import { fontEn, palette } from 'modules/defines/styles';
import ArrowBack from 'components/icons/ArrowBack';
import { theme } from 'modules/defines/names';
import DefaultButton from 'components/button/DefaultButton';
import IconCheck from 'components/icons/Check';
import Localization from 'i18n';

const StepPannelWrapper = styled.div`
  position: absolute;
  top: 92px;
  left: 30px;
  width: 230px;
  padding: 11px 14px 16px;
  border-radius: 20px;
  box-shadow: 0 10px 30px 0 rgba(19, 23, 31, 0.2);
  background-color: ${palette.white};
  z-index: 100;

  .${theme.dark} & {
    background-color: #272727;
  }
`;
const StepLabel = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 16px;
  background-image: linear-gradient(to left,
  transparent 0,
  transparent 13px,
  ${palette.form} 13px,
  ${palette.form} calc(100% - 13px),
  transparent calc(100% - 13px),
  transparent 100%);
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: 50% 100%;
`;
const Step = styled.div`
  width: 61px;
  height: 25px;
  line-height: 25px;
  border-radius: 25px;
  background-color: ${palette.form};
  color: ${palette.black};
  font-size: 11px;
  font-family: ${fontEn};
  text-align: center;
  white-space: nowrap;

  &.on {
    color: ${palette.white};
    background-color: ${palette.blue};
  }

  .${theme.dark} & {
    background-color: ${palette.disabled};

    &.on {
      background-color: ${palette.blue};
    }
  }
`;

export const PanelTitle = styled.h2`
  margin-bottom: 10px;
  color: ${palette.gray};
  font-size: 15px;
  font-weight: bold;
`;

const labelStyle = `
  height: 25px;
  line-height: 25px;
  padding: 0 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 20px;
  font-size: 13px;
`;
export const ContentLabel = styled.div`
  ${labelStyle};
  background-color: ${palette.form};
  color: ${palette.black};
`;

const BackButton = styled.button`
  position: absolute;
  top: -29px;
  left: 9px;
  height: 16px;
  font-size: 12px;

  span {
    margin-left: 5px;
    color: ${palette.black};
    font-family: ${fontEn};

    .${theme.dark} & {
      color: #b7bdbd;
    }
  }

  em {
    color: ${palette.blue};
  }

  svg {
    vertical-align: middle;
    margin-top: -2px;
  }
`;
const StepSave = styled.div`
  position: absolute;
  bottom: -42px;
  right: 15px;
  height: 25px;
`;

const SaveModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 352px;
  height: 162px;
  padding: 17px 30px 28px;
  background-color: ${palette.white};
  border: 1px solid ${palette.blue};
  border-radius: 5px;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: #737373;

  span {
    color: ${palette.blue};
  }
`;
const ModalContents = styled.div`
  display: flex;
  margin-top: 20px;

  input {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid ${palette.form};
  }
`;
const FormLabel = styled.label`
  flex-shrink: 0;
  width: 61px;
  color: ${palette.black};
  font-size: 14px;
  font-weight: 500;
`;
const ModalBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StepDepth = styled.div`
  padding-left: 18px;
`;
export const ContentSubLabel = styled.div`
  ${labelStyle};
  margin-top: 10px;
  color: ${palette.gray};
  background-color: #f6f6f6;
  cursor: pointer;

  &.on {
    background-color: ${palette.blue};
    color: ${palette.white};
  }

  .${theme.dark} & {
    background-color: #434343;
    color: #d8d8d8;

    &.on {
      background-color: ${palette.blue};
      color: ${palette.white};
    }
  }
`;

export const Functions = styled.div`
  padding: 5px 10px 5px 0;
  text-align: center;

  button {
    padding: 5px;
    color: #858997;
    font-size: 11px;

    .${theme.dark} {
      color: #b7bdbd;
    }

    .${theme.dark} &.active {
      color: ${palette.white};
    }

    svg {
      vertical-align: middle;
      margin-right: 5px;

      path {
        fill: #c8c8c8;
      }
    }

    &.active {
      color: ${palette.black};

      svg path {
        fill: ${palette.blue};
      }
    }
  }
`;
export const GuideStep = styled.div`
  padding-top: 10px;
  font-size: 11px;

  .${theme.dark} & {
    color: #b7bdbd;
  }

  .${theme.dark} &.active {
    color: ${palette.white};
  }

  span {
    margin-right: 5px;
  }
`;
export const GuideText = styled.div`
  padding: 10px 0 0 10px;
  color: ${palette.blue};
  font-size: 14px;
  font-weight: 300;

  button {
    margin-left: 15px;
  }
`;
export const ContentSubLayer = styled.div`
  padding: 10px;
`;

const SaveModal = (props) => {
    const [value, setValue] = useState('');
    const handleCancel = () => {
        setValue('');
        if (props.onCancel) props.onCancel();
    };

    return (
        <SaveModalWrapper>
            <ModalTitle>{ReactHtmlParser(Localization.historySave)}</ModalTitle>
            <ModalContents>
                <FormLabel>{Localization.historyName}</FormLabel>
                <input
                    type="text" placeholder={Localization.inputHistoryName} value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </ModalContents>
            <ModalBottom>
                <DefaultButton theme="red" onClick={handleCancel}>{Localization.cancel}</DefaultButton>
                <DefaultButton
                    disabled={value === ''}
                    onClick={() => props.onSave && props.onSave(value)}
                >
                    {Localization.save}
                </DefaultButton>
            </ModalBottom>
        </SaveModalWrapper>
    );
};

const StepPannel = props => (
    <>
        {/* <StepPannelWrapper>
            <StepLabel>
                <Step className={props.step === '1' ? 'on' : null}>STEP 1</Step>
                <Step className={props.step === '2' ? 'on' : null}>STEP 2</Step>
                <Step className={props.step === '3' ? 'on' : null}>STEP 3</Step>
            </StepLabel>

            <PanelTitle>{props.title}</PanelTitle>

            {props.children}

            {props.back && props.onBack && (
                <BackButton type="button" onClick={props.onBack}>
                    <ArrowBack /><span>BACK</span> <em>{props.back}</em>
                </BackButton>
            )}

            {props.step === '2' && props.displayMode > 0 && (
                <StepSave>
                    <DefaultButton onClick={e => props.onOpenModal && props.onOpenModal(e)}><IconCheck />{Localization.save}
                    </DefaultButton>
                </StepSave>
            )}
        </StepPannelWrapper>

        {props.step === '2' && props.modalState && <SaveModal onSave={props.onSave} onCancel={props.onCancel} />} */}
    </>
);

export default StepPannel;
