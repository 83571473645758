import React from 'react';
import styled from 'styled-components/macro';

import { fontEn, palette } from 'modules/defines/styles';

const CopyrightWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  z-index: -1;
  min-height: calc(100vh - 15px);
  color: ${palette.gray};
  font-size: 11px;
  font-family: ${fontEn};
`;

const Copyright = (props) => (
  <CopyrightWrapper {...props}>{props.children}</CopyrightWrapper>
);

export default Copyright;