import React, { useEffect } from 'react';
import ContentsLayout from 'components/layout/ContentsLayout';
import LoginContainer from 'containers/login/LoginContainer';
import GlobalContainer from 'containers/common/GlobalContainer';
import { theme } from 'modules/defines/names';

const Login = () => {
  useEffect(() => {
    document.body.id = 'login-page';
    document.body.classList.remove(theme.dark);
  }, []);

  return (
    <ContentsLayout>
      <GlobalContainer />
      <LoginContainer />
    </ContentsLayout>
  );
};

export default Login;
