import React from 'react';

const TeethS = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M15.122 11.449C13.9 12.61 9.662 13 8.5 13s-5.277-.292-6.623-1.551C.532 10.189.417 6.693.114 5.18-.196 3.635.136 2.084 1 1.03 1.537.376 2.222 0 2.879 0 4.567 0 5.493.38 6.31.715c.252.103.5.204.757.29l.419.17c1.452.588 3.26 1.321 4.761 1.321.202 0 .365-.163.365-.364 0-.201-.163-.364-.365-.364-.851 0-1.85-.276-2.82-.621.452-.1.85-.263 1.262-.432C11.506.38 12.433 0 14.121 0c.657 0 1.342.376 1.88 1.031.863 1.053 1.195 2.604.885 4.15-.302 1.512-.541 5.105-1.764 6.268z" transform="translate(-1017 -26) translate(1017 26)" />
      <path stroke="#4398E0" strokeDasharray="0 2" strokeLinecap="round" d="M0 12.1c5.637 3.732 11.304 3.732 17 0" transform="translate(-1017 -26) translate(1017 26)" />
    </g>
  </svg>
);

export default TeethS;
