import React from 'react';
import { HashRouter } from 'react-router-dom';
import Routes from 'routes';
import { createBrowserHistory } from 'history';

import GlobalStyling from 'modules/defines/styles';
import GlobalContext, { useGlobalContext } from 'modules/context/GlobalContext';
import ModalContainer from 'containers/common/ModalContainer';

function App() {
  const globalState = useGlobalContext();
  const history = createBrowserHistory();

  return (
    <HashRouter history={history}>
      <GlobalStyling />
      <GlobalContext.Provider value={globalState}>
        <Routes />
        {globalState.modal.display && <ModalContainer />}
      </GlobalContext.Provider>
    </HashRouter>
  );
}

export default App;
