import React from 'react';

const SideTeethAllOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"
    className={props.className}
  >
    <defs>
      <path id="wbjhe0mwhb" d="M0.53 0.075L4.841 0.075 4.841 6.122 0.53 6.122z" />
      <path id="3ulcjod42d" d="M0.488 0.957L23.444 0.957 23.444 5.341 0.488 5.341z" />
      <path id="lcc1unkgpf" d="M0.389 0.188L23.452 0.188 23.452 8 0.389 8z" />
      <filter id="izgpnv1nra">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#izgpnv1nra)" transform="translate(-305 -530) translate(305 530)">
        <path fill="#2193D1" d="M9.29 15.044c-.173 0-.314.141-.314.313v2.457c0 .762.51 1.21.987 1.377.424.15 1.014.13 1.461-.322v-3.825H9.29zm1.159 5.21c-.268 0-.54-.046-.807-.14-.999-.345-1.645-1.248-1.645-2.3v-2.457c0-.71.58-1.291 1.293-1.291h3.113v4.879c.027.136-.004.284-.096.4-.464.588-1.144.909-1.858.909z" />
        <path fill="#2193D1" d="M13.431 20.253c-.714 0-1.394-.32-1.858-.909-.168-.212-.132-.52.08-.687.214-.166.52-.13.688.081.458.579 1.114.614 1.576.454.477-.168.987-.616.987-1.378v-2.457c0-.171-.14-.313-.314-.313h-2.624c-.27 0-.489-.219-.489-.489s.22-.489.49-.489h2.623c.713 0 1.293.58 1.293 1.291v2.457c0 1.052-.645 1.954-1.644 2.3-.268.093-.54.14-.808.14" />
        <path fill="#2193D1" d="M16.868 19.53c-.714 0-1.394-.32-1.858-.908-.168-.212-.131-.52.08-.687.213-.168.52-.131.688.08.457.579 1.113.614 1.576.454.477-.167.987-.615.987-1.377v-2.526c0-.135-.11-.244-.244-.244h-1.96c-.271 0-.49-.22-.49-.49s.219-.488.49-.488h1.96c.674 0 1.223.548 1.223 1.222v2.526c0 1.05-.646 1.955-1.645 2.3-.267.094-.54.139-.807.139" />
        <path fill="#2193D1" d="M20.304 18.58c-.714 0-1.394-.32-1.859-.91-.167-.211-.13-.52.081-.686.214-.167.52-.131.688.08.456.579 1.114.615 1.576.454.477-.167.987-.615.987-1.377v-2.526c0-.135-.11-.244-.244-.244h-1.96c-.27 0-.49-.22-.49-.49s.22-.488.49-.488h1.96c.674 0 1.222.548 1.222 1.222v2.526c0 1.05-.645 1.955-1.644 2.3-.267.094-.539.139-.807.139M7.013 19.53c-.268 0-.54-.044-.808-.138-1-.345-1.644-1.25-1.644-2.3v-2.526c0-.674.549-1.222 1.223-1.222h1.96c.27 0 .49.218.49.489 0 .27-.22.489-.49.489h-1.96c-.134 0-.244.109-.244.244v2.526c0 .762.51 1.21.987 1.377.462.16 1.118.125 1.576-.453.167-.212.476-.25.687-.081.212.167.248.475.08.687-.463.588-1.144.909-1.857.909" />
        <path fill="#2193D1" d="M3.576 18.58c-.268 0-.54-.045-.808-.14-.999-.345-1.644-1.248-1.644-2.3v-2.525c0-.674.55-1.222 1.223-1.222h1.96c.27 0 .49.218.49.489 0 .27-.22.489-.49.489h-1.96c-.135 0-.244.109-.244.244v2.526c0 .76.51 1.21.987 1.377.462.16 1.12.125 1.576-.453.168-.212.476-.248.688-.081.212.167.248.475.08.687-.464.589-1.144.909-1.858.909M13.86 5.928L13.122 7c-.45.656-.69 1.423-.69 2.218V10.6c0 .191.155.347.345.347h3.03c.19 0 .345-.156.345-.347V6.642c0-.693-.564-1.258-1.257-1.258-.413 0-.8.204-1.035.544m1.947 5.996h-3.03c-.729 0-1.324-.595-1.324-1.325V9.22c0-.995.3-1.954.863-2.773l.738-1.072c.417-.606 1.105-.968 1.841-.968 1.233 0 2.236 1.002 2.236 2.236V10.6c0 .73-.594 1.325-1.324 1.325" />
        <path fill="#2193D1" d="M11.108 11.924H8.08c-.73 0-1.324-.595-1.324-1.325V6.642c0-1.234 1.003-2.237 2.235-2.237.736 0 1.425.363 1.842.97l.738 1.07c.402.584.734 1.565.854 2.233.048.266-.129.52-.395.568-.27.043-.52-.129-.568-.394-.1-.559-.389-1.404-.698-1.852l-.737-1.072c-.235-.34-.622-.544-1.036-.544-.693 0-1.256.564-1.256 1.258V10.6c0 .191.155.346.345.346h3.03c.19 0 .344-.155.344-.346 0-.27.22-.489.49-.489s.49.218.49.49c0 .73-.595 1.324-1.325 1.324M19.193 11.055h-2.551c-.271 0-.49-.219-.49-.489s.219-.49.49-.49h2.55c.16 0 .293-.13.293-.292V7.398c0-.439-.186-.826-.521-1.092-.347-.273-.801-.38-1.213-.279-.261.067-.527-.1-.59-.363-.062-.262.1-.526.363-.589.7-.168 1.466.004 2.046.463.576.455.893 1.115.893 1.86v2.386c0 .702-.57 1.271-1.27 1.271" />
        <g transform="translate(19 4.043)">
          <mask id="6v7r0xah6c" fill="#fff">
            <use xlinkHref="#wbjhe0mwhb" />
          </mask>
          <path fill="#2193D1" d="M3.57 6.122H1.02c-.27 0-.49-.219-.49-.489s.22-.49.49-.49h2.55c.16 0 .292-.13.292-.292V2.465c0-.582-.322-1.065-.86-1.292C2.592 1 1.99.975 1.525 1.428c-.195.19-.504.183-.693-.009C.644 1.225.648.915.842.726 1.518.068 2.493-.106 3.383.271c.9.38 1.458 1.22 1.458 2.194v2.386c0 .701-.57 1.271-1.27 1.271" mask="url(#6v7r0xah6c)" />
        </g>
        <path fill="#2193D1" d="M3.821 10.166h-2.55c-.7 0-1.27-.57-1.27-1.271V6.509c0-.93.52-1.752 1.36-2.15.835-.4 1.788-.295 2.484.275.21.17.241.479.07.689-.17.208-.479.239-.689.07-.399-.328-.952-.383-1.447-.15-.5.238-.8.71-.8 1.266v2.386c0 .16.131.292.291.292h2.551c.271 0 .49.219.49.49 0 .27-.219.489-.49.489" />
        <path fill="#2193D1" d="M7.199 11.055h-2.55c-.701 0-1.271-.569-1.271-1.27V7.397c0-.732.307-1.385.866-1.839.563-.457 1.314-.644 2.01-.499.265.056.435.315.38.58-.055.264-.318.437-.58.378-.413-.089-.856.027-1.192.302-.326.264-.505.646-.505 1.078v2.386c0 .162.13.292.292.292h2.55c.27 0 .49.22.49.49s-.22.49-.49.49" />
        <g transform="translate(0 .043)">
          <mask id="aibo2zille" fill="#fff">
            <use xlinkHref="#3ulcjod42d" />
          </mask>
          <path fill="#2193D1" d="M22.842 5.34c-.023 0-.046-.001-.07-.004-.267-.038-.453-.285-.415-.553.079-.558.162-1.134.066-1.662-.073-.398-.301-.891-.724-1.085-.417-.19-.95-.08-1.553.074l-5.85 1.472c-1.836.465-2.33.588-4.66 0L3.788 2.112c-.603-.154-1.135-.265-1.554-.075-.423.194-.651.687-.724 1.085-.095.528-.013 1.104.066 1.662.039.268-.148.515-.415.553-.271.033-.515-.147-.554-.415C.52 4.316.423 3.629.547 2.945c.15-.831.63-1.503 1.282-1.8.763-.347 1.592-.138 2.198.015l5.848 1.474c2.092.526 2.41.445 4.177 0l5.854-1.474c.605-.151 1.435-.361 2.198-.014.652.296 1.13.968 1.282 1.799.124.684.026 1.37-.06 1.976-.035.244-.244.42-.484.42" mask="url(#aibo2zille)" />
        </g>
        <g transform="translate(0 15.043)">
          <mask id="laysjymivg" fill="#fff">
            <use xlinkHref="#lcc1unkgpf" />
          </mask>
          <path fill="#2193D1" d="M11.92 8C5.454 8 .39 5.573.39 2.477c0-.739.281-1.45.84-2.114.171-.207.482-.235.688-.059.207.174.234.481.06.688-.405.484-.61.983-.61 1.485 0 2.462 4.832 4.544 10.552 4.544S22.473 4.94 22.473 2.477c0-.488-.195-.972-.577-1.443-.17-.209-.139-.517.071-.687.21-.17.518-.14.688.07.53.65.797 1.343.797 2.06C23.452 5.573 18.386 8 11.92 8" mask="url(#laysjymivg)" />
        </g>
      </g>
    </g>
  </svg>
);

export default SideTeethAllOff;