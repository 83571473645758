import React, { useState, useEffect } from 'react';
import UploadModal from 'components/modal/UploadModal';
import { useHistory } from 'react-router-dom';
import fileAPI from 'modules/api/file';
import { pagePaths } from 'modules/defines/paths';
import ModalPopup from 'components/modal/ModalPopup';
import { useCookies } from 'react-cookie';

const UploadContainer = () => {
  // 업로드 (inputFile)
  const [isFile, setIsFile] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [inputFile, setInputFile] = useState();
  const [files, setFile] = useState([]); 
  const [fileNameList, setFileNameList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [singleProgress, setSingleProgress] = useState(null);
  const [checkedArr, setCheckedArr] = useState([]);  
  const [failedList, setFailedList] = useState([]);
  const [finish, setFinish] = useState(false);
  const [error, setError] = useState(false);
  const cookies = useCookies();
  

  const history = useHistory();

  const handleClick = () => {
    document.getElementById('folder').click();
  };
  
  const handleReader = (e) => {
   const files = [];

   Array.from(e.target.files).map(file => {
     files.push(file);
  });

    const subDirDic = {};
    // 기관의 하위 파일을 올릴시에는 서브 폴더 안에 있는 것만 올려야 한다.
    files.filter(f => f.name[0] !== '.').map((file, i) => {
      const splitResult = file.webkitRelativePath.split('/');
      if (splitResult.length !== 3) {
        setError(true);
        return;
      }
  
      const curr = file.webkitRelativePath.split('/')[1];
      if (subDirDic[curr]) {
        subDirDic[curr].push(file);
      } else {
        subDirDic[curr] = [file];
      }
    });
    // loop over values

    const arr = [];
    const arr2 = [];
    Object.keys(subDirDic).forEach((key) => {
      const fileName = Array.from(subDirDic[key]).map(file => `${file.webkitRelativePath.split('/')[1]}/${file.webkitRelativePath.split('/')[2]}`);
      arr.push([...fileName]);
      arr2.push(subDirDic[key]);
    });

    setFileNameList(arr);
    setInputFile(arr2);
    setIsFile(e.target.files[0].webkitRelativePath.split('/')[0]);
  };

  const handleUpload = () => {
    const arr = [];
    load(arr);
  };

  async function load(arr) {
    let i = 0;
    while (i < files[0].length) {
      // eslint-disable-next-line no-await-in-loop
      await handleSingleUpload(i, arr);
      i += 1;
      if (i === files[0].length) {
        setFailedList(arr);
        setFinish(true);
      }
    }
  }

  let count = 0;
  const handleSingleUpload = (i, arr) => {
    return new Promise((resolve, reject) => {
      setIsUpload(true);
      const data = {
        path: { normalize: false, files: fileNameList[i] },
      };
      fileAPI.upload(data, files[0][i], cookies[0]).then(
        response => {
          count += 1;
          setSingleProgress(i);
          setProgress(Math.round((100 * (count)) / inputFile.length));
          resolve(response);
          setIsUpload(false);
        },
        error => {
          count += 1;
          console.log(error);
          setProgress(Math.round((100 * (count)) / inputFile.length));
          reject(error);
        },
      );
    });
  };

  const handleConfirm = () => {
    setError(false);
    history.push(pagePaths.patientList);
  };

  useEffect(() => {
    const arr = [...checkedArr];
    arr[singleProgress] = 'checked';
    setCheckedArr(arr);
  }, [singleProgress]);

  useEffect(() => {
    if (inputFile === null) {
      files.length = 0;
      return;  
    }
    if (inputFile) {
      const arr = [...files];
      arr.push(inputFile);
      setFile(arr);
    }
  }, [inputFile]);

  useEffect(() => {
    if (progress === 100) history.push(pagePaths.patientList);
  }, [progress]);

  const handleRemove = (i) => {
    const newFile = inputFile.filter((f, idx, source) => idx !== i);
    setFile(newFile);
  };

  return (
    <>
    <UploadModal 
    inputFile={inputFile} onUpload={handleUpload} 
    onClick={handleClick} onReader={handleReader} 
    isFile={isFile} history={history} 
    singleProgress={singleProgress} progress={progress}
    onRemove={handleRemove} checkedArr={checkedArr}
    isUpload={isUpload}
    />

    {error && <ModalPopup onConfirm={handleConfirm}> 잘못된 경로의 파일입니다 예시: SNU/Folder/u.stl,l.stl </ModalPopup>}
    </>
  );
};

export default UploadContainer;