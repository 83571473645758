import React from 'react';

const TeethMove = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M15.122 11.449C13.9 12.61 9.662 13 8.5 13s-5.277-.292-6.623-1.551C.532 10.189.417 6.693.114 5.18-.196 3.635.136 2.084 1 1.03 1.537.376 2.222 0 2.879 0 4.567 0 5.493.38 6.31.715c.252.103.5.204.757.29l.419.17c1.452.588 3.26 1.321 4.761 1.321.202 0 .365-.163.365-.364 0-.201-.163-.364-.365-.364-.851 0-1.85-.276-2.82-.621.452-.1.85-.263 1.262-.432C11.506.38 12.433 0 14.121 0c.657 0 1.342.376 1.88 1.031.863 1.053 1.195 2.604.885 4.15-.302 1.512-.541 5.105-1.764 6.268z" transform="translate(-1129 -26) translate(1129 26)" />
      <path fill="#4398E0" d="M14.5 13.145l.647-.846c.195-.226.51-.226.706 0 .196.226.196.593 0 .818l-1.323 1.63c-.142.163-.33.253-.53.253s-.39-.09-.53-.254l-1.323-1.629c-.196-.225-.196-.592 0-.818.195-.226.511-.226.706 0l.646.845V6.827l-.646.832c-.195.222-.511.222-.706 0-.196-.223-.196-.583 0-.806L13.47 5.25c.14-.161.33-.25.53-.25s.388.089.53.25l1.323 1.603c.196.223.196.583 0 .806-.195.222-.511.222-.706 0l-.648-.833v6.319z" transform="translate(-1129 -26) translate(1129 26)" />
      <path fill="#4398E0" d="M11.66 11.147c.221.195.221.51 0 .706-.224.196-.584.196-.807 0L9.25 10.53C9.089 10.388 9 10.2 9 10s.089-.39.25-.53l1.603-1.323c.223-.196.583-.196.806 0 .222.195.222.511 0 .706l-.832.646h6.346l-.832-.646c-.222-.195-.222-.511 0-.706.223-.196.583-.196.806 0L18.75 9.47c.161.14.25.33.25.53s-.089.388-.25.53l-1.603 1.323c-.223.196-.583.196-.806 0-.222-.195-.222-.511 0-.706l.833-.648h-6.348l.833.648z" transform="translate(-1129 -26) translate(1129 26)" />
    </g>
  </svg>
);

export default TeethMove;
