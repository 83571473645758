import React from 'react';

const Play = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
    <g fill="none" fillRule="evenodd">
      <g fill="#D8D8D8" fillRule="nonzero">
        <path d="M0 2.753C0 .616 2.332-.703 4.165.396l13.75 8.247c1.78 1.068 1.78 3.646 0 4.714l-13.75 8.247C2.332 22.704 0 21.384 0 19.247V2.753z" transform="translate(-435 -475) translate(435 475)" />
      </g>
    </g>
  </svg>
);

export default Play;
