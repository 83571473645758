import React from 'react';

const Export = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <path d="M2 14.004c0-.002 12-.005 12-.005-.004 0 0-5.003 0-5.003 0-.552.448-1 1-1s1 .448 1 1v5.008C16 15.099 15.101 16 14 16H2c-1.106 0-2-.892-2-1.996V8.996c0-.552.448-1 1-1s1 .448 1 1v5.008zm2.706-8.272c-.391.396-1.023.396-1.414 0s-.391-1.038 0-1.433L6.939.445C7.223.158 7.598 0 7.999 0c.402 0 .779.158 1.06.445l3.648 3.854c.39.395.39 1.037 0 1.433-.39.396-1.023.396-1.414 0L9 3.252v5.752c0 .552-.448 1.001-1 1.001s-1-.449-1-1.001V3.249L4.706 5.732z" transform="translate(-454 -24) translate(454 24)" />
      </g>
    </g>
  </svg>
);

export default Export;
