import React from 'react';

const Group = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '16'} height={props.height || '16'} viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g fill={props.color || '#FFF'} fillRule="nonzero">
          <g>
            <path d="M8 7.111c-1.964 0-3.556-1.592-3.556-3.555C4.444 1.592 6.036 0 8 0s3.556 1.592 3.556 3.556C11.556 5.519 9.964 7.11 8 7.11zM0 15.288c.345-4.242 3.788-6.4 7.985-6.4 4.256 0 7.753 2.04 8.013 6.4.01.175 0 .712-.668.712H.647c-.223 0-.665-.48-.646-.712z" transform="translate(-761 -196) translate(761 196)" />
          </g>
        </g>
      </g>
    </svg>

  );
};

export default Group;