import React from 'react';

const Forward = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M8.675 3.668V.479c0-.492.328-.627.731-.303l5.691 4.731c.191.14.303.358.303.588 0 .23-.112.448-.303.589l-5.69 4.74c-.405.325-.732.188-.732-.304V7.334h-.963C4.035 7.334.891 4.432.124 1.297.068 1.064.027.828.004.59-.025.346.1.292.284.464c0 0 .052.044.097.092 1.946 1.88 4.564 2.992 7.333 3.116h.963l-.002-.004z" transform="translate(-608 -27) translate(608 27)" />
      </g>
    </g>
  </svg>
);

export default Forward;
