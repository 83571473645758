import React from 'react';

const Import = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <path d="M2 14.005C2 14.003 14 14 14 14c-.004 0 0-5 0-5 0-.552.448-1 1-1s1 .448 1 1v5.005C16 15.1 15.101 16 14 16H2c-1.106 0-2-.891-2-1.995V9c0-.552.448-1 1-1s1 .448 1 1v5.005zm2.706-9.738c-.391-.395-1.023-.395-1.414 0-.391.396-.391 1.037 0 1.432l3.647 3.849c.284.286.659.444 1.06.444.402 0 .779-.158 1.06-.444l3.648-3.849c.39-.395.39-1.036 0-1.432-.39-.395-1.023-.395-1.414 0L9 6.745V1c0-.552-.448-1-1-1S7 .448 7 1v5.747l-2.295-2.48z" transform="translate(-399 -24) translate(399 24)" />
      </g>
    </g>
  </svg>
);

export default Import;
