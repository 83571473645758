import React from 'react';
import ContentsLayout from 'components/layout/ContentsLayout';
import DownloadContainer from 'containers/patient/DownloadContainer';
import GlobalContainer from 'containers/common/GlobalContainer';

const Download = () => {
  return (
    <ContentsLayout>
      <GlobalContainer />
      <DownloadContainer />
    </ContentsLayout>
  );
};

export default Download;