import React, { useEffect } from 'react';
import ContentsLayout from 'components/layout/ContentsLayout';
import GlobalContainer from 'containers/common/GlobalContainer';
import { theme } from 'modules/defines/names';
import AdminContainer from 'containers/login/AdminContainer';

const Admin = () => {
  useEffect(() => {
    document.body.id = 'admin-page';
    document.body.classList.remove(theme.dark);
  }, []);

  return (
    <ContentsLayout>
      <GlobalContainer />
      <AdminContainer />
    </ContentsLayout>
  );
};

export default Admin;
