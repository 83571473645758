import React, { useState, useEffect } from 'react';
import UploadModal from 'components/modal/UploadModal';
import { useHistory } from 'react-router-dom';
import fileAPI from 'modules/api/file';
import { pagePaths } from 'modules/defines/paths';
import styled from 'styled-components/macro';
import { palette, zIndex } from 'modules/defines/styles';
import ModalPopup from 'components/modal/ModalPopup';
import { useCookies } from 'react-cookie';
import patientAPI from 'modules/api/patient';
import { reject } from 'lodash';
import DefaultButton from 'components/button/DefaultButton';
import { IndexButton } from 'components/list/IndexButton';
import Check from 'components/icons/Check';


const DownloadWrapper = styled.div`
display: flex;
position: absolute;
flex-direction: column;
transform: translate(-50%, -50%);
align-items: flex-start;
left: 50%;
top: 50%;
font-size: 28px;
color: ${palette.blue}
`;

const DownloadListWrap = styled.div`
  display: flex;
  width: 1000px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${palette.blue};
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;

`;

const ListWrap = styled.div`
  z-index: ${zIndex.modal};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 93%;
  justify-content: flex-end;
`;

const UserListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    }
`;

const InnerList = styled.div`
   height: 100%;
   width: 100%;
`;

const TableHead = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${palette.blue};
  justify-content: space-around;
`;

const TableHeadItem = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  color: ${palette.blue};
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  &.border {
    justify-content: flex-start;
    border-right: 1px solid ${palette.blue};   
  }
`;

const TableBody = styled.div`
  display: flex;
  flex-direction : column;
  min-height: 60px;
`;

const SelectWrapper = styled.div`
    position: absolute;
    flex-direction: column;
    ${props => props.pos};
    }
`;

const DownloadBtn = styled(DefaultButton)`
    margin-top: 15px;
    width: 200px;
    font-size: 20px;
    padding: 10;
    border-radius: 17px 17px 17px 17px;
`;

const CombinedChecker = styled(IndexButton)`
    position: absolute;
    margin-top: 5px;
    ${props => props.pos};
`;



const DownloadContainer = () => {
  // 업로드 (inputFile)
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState('');
  const [isCombined, setIsCombined] = useState(false);
  const selectMail = { email: selected };
  const cookies = useCookies();
  const history = useHistory();

  useEffect(() => {
    patientAPI.user(cookies[0]).then(response => {
      if (response.success) {
          setUserList(response.data);
      }
    });
  }, []);
  

  const handleSelect = (e) => {
    setSelected(e.target.value);
};

  const SelectBox = (props) => {
    return (
        <select onChange={handleSelect} value={selected}>
        {props.options.map((item) => (
            <option value={item.email} key={item.email}>{item.email}</option>
       ))}
        </select>
    );
};

  const handleBulkDownload = () => {
    if (isCombined === true) {
      fileAPI.combinedDownload(selectMail, cookies[0]).then(response => {
        if (response.success) {
          const fileObjectUrl = response.data;
          const link = document.createElement('a');
          link.href = fileObjectUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(fileObjectUrl);
        } else {
          console.log('error');
        }
      });
    } else {
      fileAPI.bulkDownload(selectMail, cookies[0]).then(response => {
        if (response.success) {
          const fileObjectUrl = response.data;
          const link = document.createElement('a');
          link.href = fileObjectUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(fileObjectUrl);
        } else {
          console.log('error');
        }
      });
    }
  };

  const DownloadList = () => {  
    return(
      <ListWrap>
        <InnerList>
           <TableHead>
             <TableHeadItem> USERID </TableHeadItem>
             <TableHeadItem> COMBINED </TableHeadItem>
             <TableHeadItem> DOWNLOAD </TableHeadItem>
           </TableHead>
           <TableBody style={{ width: '100%' }}>
             <SelectWrapper pos="left: 100px">
             <SelectBox options={userList} />
             </SelectWrapper>
             <CombinedChecker active={isCombined === true} pos="left: 470px" onClick={() => (isCombined === true ? setIsCombined(false) : setIsCombined(true))}>
              <Check height="40px" width="20px" />
             </CombinedChecker>
             <ButtonWrap>
             <DownloadBtn onClick={handleBulkDownload}>Download</DownloadBtn>
             </ButtonWrap>
           </TableBody>
        </InnerList>
      </ListWrap>
    );
};

  return (
    <>
    <DownloadWrapper>
     Admin Download
     <DownloadListWrap style={{ alignItems: 'center' }}>
      <DownloadList />
     </DownloadListWrap>
    </DownloadWrapper>
    </>
  );
};

export default DownloadContainer;