import React from 'react';

const Shape = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M9.5 11C4.813 11 1 7.411 1 3h1.214c0 3.781 3.269 6.857 7.286 6.857 4.017 0 7.286-3.076 7.286-6.857H18c0 4.411-3.813 8-8.5 8" transform="translate(-806 -24) translate(806 24)" />
        <path d="M2 1.333c-.368 0-.667.299-.667.667 0 .368.299.667.667.667.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667M2 4C.897 4 0 3.103 0 2s.897-2 2-2 2 .897 2 2-.897 2-2 2M17 1.333c-.368 0-.667.299-.667.667 0 .368.299.667.667.667.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667M17 4c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2M9 7v10l2.4-2.5H15L9 7zm1.2 3.453l2.237 2.797H10.903l-.351.366-.352.367v-3.53z" transform="translate(-806 -24) translate(806 24)" />
      </g>
    </g>
  </svg>
);

export default Shape;
