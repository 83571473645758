import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import IconSearch from 'components/icons/Search';
import IconTimes from 'components/icons/Times';
import { palette } from 'modules/defines/styles';
import Localization from 'i18n';

const Wrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 34px;
  padding: 0 10px;
  border-radius: 20px;
  background-color: ${palette.bg};
  border: 1px solid ${props => (props.theme === 'focus' ? palette.blue : palette.bg)};

  &:hover {
    border: 1px solid ${palette.blue};
    input::placeholder {
      color: ${palette.black};
    }
  }

  input {
    width: 86px;
    border: 0;
    background: transparent;
    color: ${palette.black};
    text-align: center;

    ::placeholder {
      color: ${props => (props.theme === 'focus' ? palette.black : palette.gray)};
    }
  }
  button {
    width: 14px;
    height: 14px;
    &:hover svg g {
      fill: ${palette.blue};
    }
  }
`;

const SearchForm = (props) => {
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [keyDown, setKeyDown] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setKeyDown(true);
    } else {
      setKeyDown(false);
    }
  };

  useEffect(() => {
    if (props.onChange) props.onChange(value);
    if (props.onKeyDown) props.onKeyDown(keyDown);
  }, [value, keyDown]);

  return (
    <Wrapper theme={focus && 'focus'}>
      <input
        type="text" value={value} placeholder={Localization.inputSearch}
        onChange={e => { setValue(e.target.value); }}
        onKeyDown={e => handleKeyPress(e)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <button
        type="button" onClick={() => { setKeyDown(true); }}
      >
        <IconSearch />
        {/* {value === '' ? <IconSearch /> : <IconTimes color={palette.black} />} */}
      </button>
    </Wrapper>
  );
};

export default SearchForm;