import React from 'react';

const UserAdd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path fillRule="nonzero" d="M11.143 4.375C11.143 1.959 9.064 0 6.5 0 3.936 0 1.857 1.959 1.857 4.375c0 1.33.63 2.522 1.624 3.324C1.411 8.724 0 10.77 0 13.125c0 .483.416.875.929.875.512 0 .928-.392.928-.875 0-2.416 2.079-4.375 4.643-4.375 2.564 0 4.643 1.959 4.643 4.375 0 .483.416.875.928.875.513 0 .929-.392.929-.875 0-2.356-1.412-4.401-3.481-5.426.994-.802 1.624-1.994 1.624-3.324zM6.5 7C5.12 7 4 5.88 4 4.5S5.12 2 6.5 2 9 3.12 9 4.5 7.88 7 6.5 7z" transform="translate(-278 -26) translate(278 26)" />
        <path d="M15 8h-2c-.552 0-1-.448-1-1s.448-1 1-1h2V4c0-.552.448-1 1-1s1 .448 1 1v2h2c.552 0 1 .448 1 1s-.448 1-1 1h-2v2c0 .552-.448 1-1 1s-1-.448-1-1V8z" transform="translate(-278 -26) translate(278 26)" />
      </g>
    </g>
  </svg>
);

export default UserAdd;
