import * as THREE from 'three';

// defines labeling step sequence, including in each an update() function that captures state at the end of the step (in update])
// and prepares for next step, setting appropriate camera views, etc.
export const labelingSteps = [

    { title: 'Pick tooth', autoStep: true, cursor: 'default', update: (viewerState) => { /*`url(${pickToothCursor}), crosshair`*/
            const origin = viewerState.meshPointClicked;
            const vec = viewerState.meshPointClicked.clone().normalize().multiplyScalar(70);
            const missingStatus = false;
            const vecNorm = new THREE.Vector3().subVectors(viewerState.cameraRef.current.position, viewerState.trackballControlsRef.current.target).clone();
            const update = { toothData: { toothNumber: viewerState.toothNumber, origin, vecNorm, missingStatus } };
            const viewForMesialPick = {
                position: vec.add(new THREE.Vector3(0, 0, 10).multiplyScalar(2.5)),
                up: viewerState.display.teeth === 'upper' ? vecNorm.normalize().negate() : vecNorm,
            };
            setCameraView(viewForMesialPick, viewerState, update);

            return update;
        } },

    // { title: 'Set z-axis', enableRotation: true, cursor: 'all-scroll', update: (viewerState, labelData) => {
    //         const vertical = new THREE.Vector3().subVectors(viewerState.trackballControlsRef.current.target, viewerState.cameraRef.current.position).normalize();
    //         const update = { toothData: { vertical, verticalOrigin: viewerState.cameraRef.current.position.clone() } };

    //         const position = viewerState.cameraRef.current.position.clone().sub(labelData.origin).normalize()
    //             .multiplyScalar(75)
    //             .add(labelData.origin);
    //         setCameraView({ position }, viewerState, update);

    //         return update;
    //     } },

    { title: 'Pick mesial point', autoStep: true, cursor: 'crosshair', update: (viewerState) => {
            const mesial = viewerState.meshPointClicked;
            // const vec = viewerState.meshPointClicked.clone().normalize().multiplyScalar(60);
            const update = { toothData: { mesial } };

            // if (viewerState.toothNumber < 18 || viewerState.toothNumber > 38) {
            //     const viewForDistalPick = {
            //         position: vec.add(new THREE.Vector3(0, 0, 10).multiplyScalar(2.5).applyAxisAngle(new THREE.Vector3(0, 1.5, 0), -1)),
            //     };
            //     setCameraView(viewForDistalPick, viewerState, update);

            // } else {
            //     const viewForDistalPick = {
            //         position: vec.add(new THREE.Vector3(0, 0, 10).multiplyScalar(2.5).applyAxisAngle(new THREE.Vector3(0, -1.5, 0), -1)),
            //     };
            //     setCameraView(viewForDistalPick, viewerState, update);

            // }

            return update;
        } },

    { title: 'Pick distal point', autoStep: true, cursor: 'crosshair', update: (viewerState, labelData, upper) => {
            const distal = viewerState.meshPointClicked;
            const midPoint = distal.clone().add(labelData.mesial).divideScalar(2);
            const checkDate = new Date('2023-03-31');
            
            // mesial-distal plane normal
            const v1 = new THREE.Vector3().subVectors(labelData.mesial, midPoint);
            const v2 = midPoint;
            const vNorm = new THREE.Vector3().crossVectors(v1, v2).normalize().negate();
            // flip normal if tooth in left quadrants, so normal always points out of the tooth
            const leftQuad = labelData.mesial.clone().sub(distal).x > 0;
            if ((leftQuad && upper) || (!leftQuad && viewerState.display.teeth === 'lower'))
                vNorm.negate();
            const mdPlaneLookAt = vNorm.clone().add(midPoint);

            const update = { toothData: { distal, midPoint, mdPlaneLookAt, mdPlaneNormal: vNorm.clone() } };

            const viewForTipPick = {
                position: viewerState.cameraRef.current.position.clone().multiplyScalar(1),
                //previous cusp tip camera postion 
                up: checkDate > viewerState.createDate ? ( upper ? vNorm.negate() : vNorm ) : ( vNorm ),
                target: midPoint,
            };
            setCameraView(viewForTipPick, viewerState, update);

            return update;
        } },

    { title: 'Pick tooth cusp tip', autoStep: true, cursor: 'crosshair', update: (viewerState, labelData, upper, createDate) => {
            const update = { toothData: { tip: viewerState.meshPointClicked } };
            const checkDate = new Date('2023-03-31');
            const vec = labelData.vecNorm.clone().normalize(); // -Math.PI / 16);
            const norm = labelData.mdPlaneNormal;
            const viewForLingualPick = {
                //position: vec.multiplyScalar(-50).add(labelData.origin),
                position: vec.clone().multiplyScalar( upper ? -30 : 30 ),
                // .add(new THREE.Vector3(0, upper ? -30 : 30, 0)),
                up: checkDate > createDate ? (upper ? norm.normalize().negate() : norm) : ( norm ),
                target: labelData.origin,
            };
            setCameraView(viewForLingualPick, viewerState, update);

            return update;
        } },

    { title: 'Pick lingual groove point', autoStep: true, cursor: 'crosshair', update: (viewerState, labelData, upper) => {
            const update = { toothData: { lingual: viewerState.meshPointClicked } };

            const upVec = labelData.mdPlaneNormal.clone();
            const norm = labelData.tip.clone();
            const viewForBuccalPick = {
                position: upper ? norm.multiplyScalar(2.5) : norm.multiplyScalar(2.5).applyAxisAngle(new THREE.Vector3(0, 0, 1), upper ? Math.PI / 4 : -Math.PI / 16),
                up: upVec,
                target: labelData.midPoint,
            };
            setCameraView(viewForBuccalPick, viewerState, update);

            return update;
        } },

    { title: 'Pick buccal groove point', autoStep: true, cursor: 'crosshair', update: (viewerState, labelData, upper) => {
            const buccal = viewerState.meshPointClicked;
            const checkDate = new Date('2023-03-31');
            // lingual / tip / buccal plane
            const lingualBuccalPlaneCenter = (labelData.tip.clone().add(buccal).add(labelData.lingual)).divideScalar(3);
            const v1 = new THREE.Vector3().subVectors(labelData.lingual, buccal);
            const v2 = new THREE.Vector3().subVectors(labelData.tip, buccal);
            const vNorm = new THREE.Vector3().crossVectors(v1, v2).normalize();
            const lingualBuccalPlaneLookAt = vNorm.clone().add(lingualBuccalPlaneCenter);

            const update = { toothData: { buccal, lingualBuccalPlaneCenter,  lingualBuccalPlaneLookAt } };

            const norm = checkDate > viewerState.createDate ? ( upper ? vNorm.clone() : vNorm.clone().negate()) : vNorm.clone().negate();//.negate();
            const vec = norm.applyAxisAngle(new THREE.Vector3(0, 1, 0), 1);
            const viewForFAPick = {
                position: vec.multiplyScalar(30).add(lingualBuccalPlaneCenter),
                up: labelData.vecNorm.clone().normalize().multiplyScalar(1),
                target: lingualBuccalPlaneCenter,
            };
            setCameraView(viewForFAPick, viewerState, update);

            return update;
        } },

    { title: 'Pick FA point', cursor: 'crosshair', autoStep: true, update: (viewerState, labelData) => {
            const FAAxisLookAt = viewerState.meshPointClicked.clone().add(labelData.mdPlaneNormal);
            const update = { toothData: { FAPoint: viewerState.meshPointClicked, FAAxisLookAt } };

            // if (!autoStep) {
            //     // reposition cam pos & target for setting adjust tooth axis from the front (for adjustment in x-z plane)
            //     const viewForToothAxisXZAdjust = {
            //         position: labelData.mdPlaneNormal.clone().multiplyScalar(upper ? 50 : -50).add(labelData.midPoint),
            //         up: labelData.vertical.clone().multiplyScalar(upper ? -1 : 1),
            //         target: labelData.midPoint,
            //     };
            //     setCameraView(viewForToothAxisXZAdjust, viewerState, update);
            // }

            return update;
        } },

    // { title: 'Adjust tooth axis front', cursor: 'crosshair', update: (viewerState, labelData) => {
    //         // copy across toothAxis labelData injected by ToothAxis.saveLabelData() so step-control records complete saved-state for step-back
    //         const { toothAxisLocal, toothAxisLength, toothAxisCenter, toothAxisDirection, toothAxisYZPlane } = labelData;
    //         const update = { toothData: { toothAxisLocal, toothAxisLength, toothAxisCenter, toothAxisDirection, toothAxisYZPlane } };
    //         // console.log('\n** tooth axis front update:', update, '\n');
    //
    //         // for the tooth axis ajust in the y-z plane, create several viewpoints to be toggled through by hitting 'v' key
    //         const sideVec = labelData.toothAxisYZPlane.normal;
    //         const sideUpView = {
    //             position: sideVec.clone()
    //                         .applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI / 4)
    //                         .multiplyScalar(50)
    //                         .add(labelData.toothAxisCenter),
    //             up: labelData.toothAxisCenter.clone().sub(labelData.toothRoot).normalize(),
    //             target: labelData.toothAxisCenter,
    //         };
    //
    //         const sideView = {
    //             position: sideVec.clone()
    //                         .multiplyScalar(50)
    //                         .add(labelData.toothAxisCenter),
    //             up: labelData.toothAxisCenter.clone().sub(labelData.toothRoot).normalize(),
    //             target: labelData.toothAxisCenter,
    //         };
    //         const topAxis = labelData.toothAxisCenter.clone().sub(labelData.toothRoot).normalize();
    //         const topView = {
    //             position: topAxis.clone()
    //                         .multiplyScalar(50)
    //                         .add(labelData.toothAxisCenter),
    //             up: sideVec.clone().normalize().applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2),
    //             target: labelData.toothAxisCenter,
    //         };
    //
    //         labelData.views = [sideUpView, topView, sideView, sideView]; labelData.curView = 0;
    //
    //         // start with the view from the side and up, looking to tooth axis line centerpoint
    //         setCameraView(sideUpView, viewerState, update);
    //
    //         return update;
    //     } },
    //
    // { title: 'Adjust tooth axis side', cursor: 'crosshair', update: (viewerState, labelData) => {
    //         return { toothData: { [viewerState.toothNumber]: {} } };
    //     } },
];

// sets & records camera view
const setCameraView = (view, viewContext, update) => {
    if (viewContext.labelingSteps[viewContext.labelingStep].holdAtStep)
        return; // retain existing viewpoint while in a hold-step state
    viewContext.cameraRef.current.position.setScalar(0).add(view.position);
    if (view.up) {
        const up = view.up;
        viewContext.cameraRef.current.up.set(up.x, up.y, up.z);
    }
    if (view.target) {
        viewContext.trackballControlsRef.current.target = view.target;
        viewContext.trackballControlsRef.current.update();
    }
    update.camSettings = { position: viewContext.cameraRef.current.position, up: viewContext.cameraRef.current.up };
    update.trackballSettings = { target: viewContext.trackballControlsRef.current.target };
};

