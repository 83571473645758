import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import InnerContentLayout from 'components/layout/InnerContentLayout';
import ListHeader, { ListHeaderRight } from 'components/list/ListHeader';
import { IndexButtons, IndexButton } from 'components/list/IndexButton';
import SearchForm from 'components/list/SearchForm';
import RegiButton from 'components/button/RegiButton';
import ListBody from 'components/list/ListBody';

import { pagePaths } from 'modules/defines/paths';
import patientAPI from 'modules/api/patient';
import { initCharRegex } from 'utils/regex';
import GlobalContext from 'modules/context/GlobalContext';
import Localization from 'i18n';
import fileAPI from 'modules/api/file';
import Search from 'components/icons/Search';

const ListContainer = (props) => {
  const { history } = props;
  const [cookies, setCookie] = useCookies();
  const globalContext = useContext(GlobalContext);

  const charIndex = Localization.index;
  const charIndexs = charIndex.split(' ');

  const listHead = [
    { id: 'no', label: 'No.', sortable: false },
    { id: 'patient_id', label: Localization.patientID, sortable: true },
    { id: 'working_state', label: Localization.status, sortable: true },
    { id: 'create_date', label: Localization.created_at, sortable: false },
    { id: 'delete', label: Localization.delete, sortable: false },
    // { id: 'modtfy', label: '' },
  ];

  const [listData, setListData] = useState([]); // 전체 데이터
  const [data, setData] = useState(listData); // 보여지는 데이터
  const [searchWord, setSearchWord] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(charIndexs[0]);
  const [sortData, setSortData] = useState({ page: 1, order: 'asc', order_target: 'patient_id', search_word: '' });
  const [sortResponse, setSortResponse] = useState({ totalItems: null, totalPage: null, curPage: null });


  const handleSearch = (e) => {
    // 환자ID, 이름으로 검색
    if (e === true) {
     setSortData({ search_word: searchWord });
     setData(listData);
    }
  };

  const handleChangeSort = (value) => {
    setSortData(value);
  };

  const handleUpload = () => {
    // 신규 환자 등록
    // history.push(pagePaths.patientRegi);
    // 환자 업로드 
    history.push(pagePaths.upload);
  };
  const handleDetail = (patientId) => {
    // 환자 상세 보기
    // history.push(pagePaths.patientDetail.replace(':patientId', patientId));
    // 파일 viewer 

    for (let i = 0; i < data.length; i += 1 ) {
      const elem = data[i];
      if (elem.patient_id === patientId) {
        const patientInfo = globalContext.patientInfo;
        patientInfo.patient_id = patientId;
        patientInfo.image = elem.image;
        patientInfo.created_at  = new Date(elem.created_at.split('T')[0]);
        setCookie('image', patientInfo.image, { path: '/' } );
        setCookie('patient', patientInfo.patient_id, { path: '/' } );
        patientInfo.working_state = elem.working_state;
        globalContext.set(prev => ({ ...prev, patientInfo }));
        break;
      }
    }
    history.push(pagePaths.viewer.replace(':lowerProjectId', patientId));
  };

  const handleIndexing = (e) => {
    // 초성 선택 검색
    const textContent = e.target.textContent;
    const d = listData.filter((_d) => {
      if (textContent === charIndexs[0]) return _d; // All

      if (textContent === charIndexs[charIndexs.length - 1]) { // 기타
        // 공백 제거, 영문, 숫자 또는 빈칸
        return (_d.name.replace(/ /gi, '').match(initCharRegex[textContent]) || _d.name.replace(/ /gi, '') === '');
      }

      return (_d.name.replace(/ /gi, '').match(initCharRegex[textContent]));
    });
    setSelectedIndex(textContent);
    setData(d);
  };

  useEffect(() => {
    // 로그인 상태 아닌 경우 로그인 화면으로 이동
    if (!cookies.user) {
      history.push(pagePaths.login);
      return;
    }

    let patient = globalContext.patientInfo;
    if (!patient) {
        patient = cookies.patient;
        globalContext.set(prev => ({ ...prev, patient }));
    }

    // 새로고침 시 context 데이터 초기화 되어 데이터 다시 세팅
    let user = globalContext.user;
    if (!user._id) {
      user = cookies.user;
      globalContext.set(prev => ({ ...prev, user }));
    }

    patientAPI.list(user, cookies, sortData).then(response => {
      if (response.success) {
        setListData(response.data);
        setData(response.data);
        setSortResponse({ totalItems: response.totalItems, totalPage: response.totalPage, curPage: response.curPage });
      } else {
        console.log(response.data);
      }
    });
  }, [sortData]);

  return (
    <InnerContentLayout>
      <ListHeader>
        {/* <IndexButtons> ㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ기타 인덱싱 버튼
          {charIndexs.map((v, i) => (
            <IndexButton key={i} selected={selectedIndex === v} onClick={handleIndexing}>{v}</IndexButton>
          ))}
        </IndexButtons> */}
        <ListHeaderRight>
          <RegiButton onClick={handleUpload} />
          <SearchForm onChange={setSearchWord} onKeyDown={handleSearch} />
        </ListHeaderRight>
      </ListHeader>

      <ListBody listHead={listHead} listData={data} sortData={handleChangeSort} searchWord={searchWord} sortResponse={sortResponse} onClick={handleDetail} />
    </InnerContentLayout>
  );
};

export default withRouter(ListContainer);