import React, { useContext } from 'react';

import { ViewrCtrlMenuLayout, ViewerMenuGroup, ViewerMenuButton } from 'components/viewer/ViewerMenuGroup';
import GlobalContext from 'modules/context/GlobalContext';
import Localization from 'i18n';

const ViewerMenuContainer = () => {
  const globalContext = useContext(GlobalContext);

  return (
    <ViewrCtrlMenuLayout>
      <ViewerMenuGroup theme={globalContext.theme}>
        <ViewerMenuButton icon="tooth" title={Localization.viewTooth} />{/* View tooth */}
        <ViewerMenuButton icon="teethShape" title={Localization.viewShape} />{/* View shape */}
        <ViewerMenuButton icon="objectHide" title={Localization.hideObject} />{/* Hide object */}
        <ViewerMenuButton icon="objectShow" title={Localization.showAll} />{/* Show all object */}
      </ViewerMenuGroup>

      <ViewerMenuGroup theme={globalContext.theme}>
        <ViewerMenuButton icon="teeth" title={Localization.viewTeeth} />{/* View type of teeth */} {/* TODO:: make sublayer */}
        <ViewerMenuButton icon="toothCopy" title={Localization.showOriginTeeth} />{/* Show origin teeth */}
        <ViewerMenuButton icon="toothCheck" title={Localization.checkXsect} />{/* Check Xsect */}
        <ViewerMenuButton icon="toothBase" title={Localization.showBaseModel} />{/* Show base */}
      </ViewerMenuGroup>
    </ViewrCtrlMenuLayout>
  );
};

export default ViewerMenuContainer;
