import React from 'react';

const TeethDevide = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="22" height="15" viewBox="0 0 22 15">
    <defs>
      <path id="prauw522ma" d="M0 0H10V13H0z" />
      <path id="6ssle91f9c" d="M0 0H10V13H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1073 -25) translate(1073 25) translate(12 1)">
        <mask id="cs4qcrwgib" fill="#fff">
          <use xlinkHref="#prauw522ma" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#prauw522ma" />
        <path fill="#FFF" d="M16.122 11.449C14.9 12.61 10.662 13 9.5 13s-5.277-.292-6.623-1.551c-1.345-1.26-1.46-4.756-1.763-6.268-.31-1.546.022-3.097.886-4.15C2.537.376 3.222 0 3.879 0 5.567 0 6.493.38 7.31.715c.252.103.5.204.757.29l.419.17c1.452.588 3.26 1.321 4.761 1.321.202 0 .365-.163.365-.364 0-.201-.163-.364-.365-.364-.851 0-1.85-.276-2.82-.621.452-.1.85-.263 1.262-.432C12.506.38 13.433 0 15.121 0c.657 0 1.342.376 1.88 1.031.863 1.053 1.195 2.604.885 4.15-.302 1.512-.541 5.105-1.764 6.268z" mask="url(#cs4qcrwgib)" />
      </g>
      <g transform="translate(-1073 -25) translate(1073 25) translate(0 1)">
        <mask id="uh05glu9xd" fill="#fff">
          <use xlinkHref="#6ssle91f9c" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#6ssle91f9c" />
        <path fill="#FFF" d="M7.122 11.449C5.9 12.61 1.662 13 .5 13s-5.277-.292-6.623-1.551c-1.345-1.26-1.46-4.756-1.763-6.268-.31-1.546.022-3.097.886-4.15C-6.463.376-5.778 0-5.121 0c1.688 0 2.614.38 3.431.715.252.103.5.204.757.29l.419.17c1.452.588 3.26 1.321 4.761 1.321.202 0 .365-.163.365-.364 0-.201-.163-.364-.365-.364-.851 0-1.85-.276-2.82-.621.452-.1.85-.263 1.262-.432C3.506.38 4.433 0 6.121 0 6.778 0 7.463.376 8 1.031c.863 1.053 1.195 2.604.885 4.15-.302 1.512-.541 5.105-1.764 6.268z" mask="url(#uh05glu9xd)" />
      </g>
      <path stroke="#4398E0" strokeLinecap="round" d="M11.5 0.5L11.5 14.5" transform="translate(-1073 -25) translate(1073 25)" />
    </g>
  </svg>
);

export default TeethDevide;
