import React from 'react';

const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7">
    <g fill="none" fillRule="evenodd">
      <g fill="#3C3C3B">
        <path d="M578.987 456.993l3.716-3.573c.305-.337.383-.822.198-1.237-.184-.415-.596-.683-1.05-.683h-7.705c-.453.001-.863.269-1.047.682-.184.414-.108.898.195 1.235l3.716 3.576c.253.279.612.437.988.437.377 0 .735-.158.989-.437z" transform="translate(-573 -451)" />
      </g>
    </g>
  </svg>
);

export default ArrowDown;
