import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

// the vertical tooth axis with plane-constrained ajustment handles
// handles both the x-z plane adjust step ('Adjust tooth axis front') and the y-z plane step ('Adjust tooth axis side')

const ToothAxis = ({ viewerContext }) => {
    const labelData = viewerContext.getLabelData();
    const { gl } = useThree();
    const [lineRef, setLineRef] = useState();
    const [verticalRef, setVerticalRef] = useState();
    const [handleGroupRef, setHandleGroupRef] = useState();
    const topHandleRef = useRef();
    const topHandlePlaneRef = useRef();
    const rootHandlePlaneRef  = useRef();
    const rootHandleRef = useRef();
    const downAt = useRef();
    const curView = useRef(labelData.curView);
    const [verticalLength, setVerticalLength] = useState(labelData.toothAxisLocal ? labelData.toothAxisLength : 20);
    //
    const inToothAxisAdjustStep = viewerContext.labelingStepName.match(/Adjust tooth axis/);
    const inToothAxisSideAdjustStep = viewerContext.labelingStepName.match(/side/);

    const onPointerDown = (e) => {
        if (e.object.name === 'topHandle' || e.object.name === 'rootHandle') {
            // if we clicked on one of the axis handles, put us into a tracking state
            downAt.current = verticalRef.worldToLocal(e.point.clone());
        }
    };

    const onPointerMove = (e) => {
        // console.log('pointer move', downAt.current, e.object);
        if (downAt.current) {
            if (e.object.name === 'topHandlePlane' || e.object.name === 'rootHandlePlane') {
                setHandlePos(e.point, e.object);
            }
        }
    };

    const onPointerUp = (e) => {
        // exit handle tracking, capture current tooth axis attributes
        // console.log('pointer up');
        saveLabelData();
        downAt.current = null;
    };

    // the following useEffects() all fire as various 3js objects are instantiated, setting their pos & orientation as appropriate
    useEffect(() => {
        // the main parent group for the tooth-axis helpers, set from the camera vertical in step 1 and the midpoint between mesial & distal
        if (lineRef) {
            // mesial/distal vector
            lineRef.lookAt(labelData.mesial);
            lineRef.rotateX(Math.PI / 2);
        }
    }, [lineRef]);

    useEffect(() => {
        // the tooth axis vertical pole
        if (verticalRef) {
            // tooth vertical vector
            if (labelData.toothAxisCenter) {
                const center = labelData.toothAxisLocal.center;
                verticalRef.position.set(center.x, center.y, center.z);
                verticalRef.setRotationFromQuaternion(labelData.toothAxisLocal.quaternion);
                setVerticalLength(labelData.toothAxisLength);
            } else {
                verticalRef.position.set(0, 0, 0);
                verticalRef.up.set(labelData.vertical.x, labelData.vertical.y, labelData.vertical.z);
                verticalRef.lookAt(labelData.midPoint.clone().add(labelData.mdPlaneNormal));
            }
        }
    }, [verticalRef]);

    useEffect(() => {
        if (handleGroupRef) {
            // tooth vertical handle group,
            if (labelData.toothAxisLocal) {
                // set from saved state
                const group = labelData.toothAxisLocal.handleGroup;
                handleGroupRef.position.set(group.x, group.y, group.z);
                const topHandlePos = labelData.toothAxisLocal.top;
                topHandleRef.current.position.set(topHandlePos.x, topHandlePos.y, topHandlePos.z);
                const rootHandlePos = labelData.toothAxisLocal.root;
                rootHandleRef.current.position.set(rootHandlePos.x, rootHandlePos.y, rootHandlePos.z);
                if (inToothAxisSideAdjustStep) {
                    if (labelData.toothAxisLocal.groupSideQuaternion) {
                        handleGroupRef.setRotationFromQuaternion(labelData.toothAxisLocal.groupSideQuaternion);
                    } else if (verticalRef) {
                        handleGroupRef.position.copy(verticalRef.position);
                        handleGroupRef.quaternion.copy(verticalRef.quaternion);
                        handleGroupRef.rotateY(Math.PI / 2);
                        topHandleRef.current.position.set(0, -labelData.toothAxisLength / 2, 0);
                        rootHandleRef.current.position.set(0, labelData.toothAxisLength / 2, 0);
                    }
                } else {
                    handleGroupRef.setRotationFromQuaternion(labelData.toothAxisLocal.groupFrontQuaternion);
                }
            } else {
                // no save state yet, set intial orientation
                handleGroupRef.up.set(labelData.vertical.x, labelData.vertical.y, labelData.vertical.z);
                handleGroupRef.lookAt(labelData.midPoint.clone().add(labelData.mdPlaneNormal));
                topHandleRef.current.position.set(0, -10, 0);
                rootHandleRef.current.position.set(0, 10, 0);
            }
            if (verticalRef && !labelData.toothAxisLocal) {
                // save initial state first time through
                saveLabelData();
            }

            // handles 'v' keydown cycling between various side-views defined in 'Adjust tooth axis front' step update() fn
            const handleKey = (e) => {
                if (e.key === 'v') {
                    e.stopPropagation();
                    curView.current += 1;
                    if (curView.current >= labelData.views.length)
                        curView.current = 0;
                    const view = labelData.views[curView.current];
                    viewerContext.cameraRef.current.position.setScalar(0).add(view.position);
                    const up = view.up;
                    viewerContext.cameraRef.current.up.set(up.x, up.y, up.z);
                    viewerContext.trackballControlsRef.current.target = view.target;
                    viewerContext.trackballControlsRef.current.update();

                    if (curView.current === 3 && inToothAxisSideAdjustStep && viewerContext.clippingPlaneRef.current && labelData.toothAxisYZPlane) {
                        gl.localClippingEnabled = true;
                        const clipPoint = labelData.distal.clone().add(new THREE.Vector3(-1, 0, 0));
                        const clipNormal = labelData.toothAxisYZPlane.normal.clone().multiplyScalar(-1);
                        viewerContext.clippingPlaneRef.current.setFromNormalAndCoplanarPoint(clipNormal, clipPoint);
                    } else {
                        gl.localClippingEnabled = false;
                    }
                }
            };
            // acpture keydowns for above
            document.removeEventListener('keydown', handleKey);
            document.addEventListener('keydown', handleKey);
            return () => {
                document.removeEventListener('keydown', handleKey);
                gl.localClippingEnabled = false;
            };
        }
    }, [handleGroupRef, verticalRef]);

    // called at various step entry & exit points to save current tooth-axis state data
    const saveLabelData = () => {
        lineRef.updateWorldMatrix();
        handleGroupRef.updateWorldMatrix();
        verticalRef.updateWorldMatrix();
        rootHandleRef.current.updateWorldMatrix();
        const toothAxisCenter = verticalRef.localToWorld(new THREE.Vector3());
        const toothRoot = rootHandleRef.current.localToWorld(new THREE.Vector3());
        const groupFrontQuaternion = labelData.toothAxisLocal && labelData.toothAxisLocal.groupFrontQuaternion;
        Object.assign(labelData, {
            toothAxisLocal: {
                center: verticalRef.position.clone(),
                quaternion: verticalRef.quaternion.clone().normalize(),
                handleGroup: handleGroupRef.position.clone(),
                root: rootHandleRef.current.position.clone(),
                top: topHandleRef.current.position.clone(),
                toothAxisLength: verticalLength,
            },
            toothAxisLength: verticalLength,
            toothAxisCenter, toothRoot,
            toothAxisDirection: verticalRef.getWorldDirection(new THREE.Vector3()),
        });
        if (inToothAxisSideAdjustStep) {
            labelData.toothAxisLocal.groupSideQuaternion = handleGroupRef.quaternion.clone().normalize();
            labelData.toothAxisLocal.groupFrontQuaternion = groupFrontQuaternion; // restore front view quaternion
        } else {
            labelData.toothAxisLocal.groupFrontQuaternion = handleGroupRef.quaternion.clone().normalize();
            labelData.toothAxisYZPlane = new THREE.Plane().setFromCoplanarPoints(toothRoot, viewerContext.camSettings.position, toothAxisCenter);
        }
        viewerContext.update({ labels: viewerContext.labels });
    };


    // position handle, updating the tooth vertical to track the moves
    const setHandlePos = (pos, handle) => {
        const [from, to] = !handle || handle === topHandlePlaneRef.current ? [topHandleRef.current, rootHandleRef.current] : [rootHandleRef.current, topHandleRef.current];
        const toWorldPos = to.localToWorld(new THREE.Vector3());
        const length = pos.clone().distanceTo(toWorldPos);
        const dirNormal = toWorldPos.sub(pos).normalize();
        const newWorldCenter = pos.clone().add(dirNormal.multiplyScalar(length / 2));

        const newCenter = lineRef.worldToLocal(newWorldCenter.clone());
        verticalRef.position.set(newCenter.x, newCenter.y, newCenter.z);
        verticalRef.up.set(dirNormal.x, dirNormal.y, dirNormal.z);

        verticalRef.matrixAutoUpdate = false;
        verticalRef.lookAt(newWorldCenter.clone().add(inToothAxisSideAdjustStep ? labelData.toothAxisYZPlane.normal : labelData.mdPlaneNormal));
        setVerticalLength(length);
        verticalRef.matrixAutoUpdate = true;

        const newHandlePos = handleGroupRef.worldToLocal(pos.clone());
        from.position.set(newHandlePos.x, newHandlePos.y, newHandlePos.z);
    };

    return (
        <>
            <group ref={setLineRef} position={viewerContext.worldToMeshLocal(labelData.midPoint)}>
                {/* tooth axis pole */}
                <mesh ref={setVerticalRef}>
                    <meshPhongMaterial color={0x1515f5} opacity={0.4} />
                    <cylinderGeometry args={[0.05, 0.035, verticalLength, 8]} />
                </mesh>
                { inToothAxisAdjustStep &&
                    <group ref={setHandleGroupRef}>
                        {/* tooth axis endpoint handles */}
                        <mesh ref={topHandleRef} name="topHandle" onPointerDown={onPointerDown} onPointerMove={onPointerMove} onPointerUp={onPointerUp}>
                            <meshPhongMaterial color={0xff0000} />
                            <sphereGeometry args={[0.25, 16, 16]} />
                            <mesh ref={topHandlePlaneRef} name="topHandlePlane" onPointerMove={onPointerMove} onPointerUp={onPointerUp}>
                                <meshPhongMaterial visible={false} side={THREE.DoubleSide} />
                                <circleGeometry args={[10, 32]} />
                            </mesh>
                        </mesh>
                        <mesh ref={rootHandleRef} name="rootHandle" onPointerDown={onPointerDown} onPointerMove={onPointerMove} onPointerUp={onPointerUp}>
                            <meshPhongMaterial color={0x000000} />
                            <sphereGeometry args={[0.25, 16, 16]} />
                            <mesh ref={rootHandlePlaneRef} name="rootHandlePlane" onPointerMove={onPointerMove} onPointerUp={onPointerUp}>
                                <meshPhongMaterial visible={false} side={THREE.DoubleSide} />
                                <circleGeometry args={[10, 32]} />
                            </mesh>
                        </mesh>
                    </group>
                }
            </group>
        </>
    );
};

export default ToothAxis;
