import React from 'react';

const ArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9">
    <g fill="none" fillRule="evenodd">
      <g fill="#444A58">
        <path d="M943.997 1017.058c.002-.247-.092-.486-.263-.668l-2.788-2.89 2.788-2.888c.355-.377.355-.957 0-1.334-.165-.177-.398-.277-.643-.277-.244 0-.478.1-.643.277l-3.431 3.556c-.356.376-.356.957 0 1.332l3.428 3.557c.165.177.398.278.643.278s.478-.1.643-.278c.17-.18.264-.418.262-.665" transform="translate(-938 -1009) matrix(-1 0 0 1 1882.75 0)" />
      </g>
    </g>
  </svg>
);

export default ArrowRight;
