import React from 'react';

const ArrowNext = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9">
    <g fill="none" fillRule="evenodd">
      <g fill="#444A58">
        <path d="M5.116 1.404L2.805 3.688l6.896.031c.213.001.417.087.567.238.15.151.233.356.232.57-.001.212-.087.416-.238.566-.151.15-.356.233-.569.232l-6.894-.03L5.079 7.6c.15.151.234.356.233.569 0 .213-.086.417-.238.567-.315.312-.823.31-1.136-.005L.188 4.945c-.255-.26-.252-.677.008-.933l3.79-3.75c.315-.311.823-.31 1.135.006.15.151.234.356.233.569-.001.213-.087.417-.238.567z" transform="translate(-954 -1009) matrix(-1 0 0 1 964.5 1009)" />
      </g>
    </g>
  </svg>
);

export default ArrowNext;
