import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import ArrowDown from 'components/icons/ArrowDown';
import { palette } from 'modules/defines/styles';

const SelectFormWrapper = styled.div`
  position: relative;
  width: ${props => (props.width ? props.width : '100px')};
  height: 22px;
  padding-right: 13px;
  svg {
    position: absolute;
    right: 0;
    top: 7px;
  }
`;

const Selected = styled.div`
  height: 24px;
  padding: 0 12px;
  color: ${palette.black};
  font-size: 14px;
  cursor: pointer;
`;

const SelectList = styled.div`
  position: absolute;
  width: 100%;
  margin-top: 3px;
  padding: 5px 0;
  background-color: ${palette.form};
`;
const SelectItem = styled.div`
  height: 28px;
  line-height: 28px;
  padding: 0 12px;
  color: ${palette.black};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const SelectForm = (props) => {
  const { data } = props;
  if (!data || data.length === 0) return null;

  const [value, setValue] = useState(props.value || '선택');
  const [show, setShow] = useState(false);

  const handleSelect = (e) => {
    const selectedValue = e.target.firstChild.nodeValue;
    setValue(selectedValue);
    setShow(false);
    if (props.onChange) props.onChange(selectedValue);
  };

  const handleBodyClick = (e) => {
    if (e.target.dataset.prevent === 'click') return;
    setShow(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick, true);
    return (() => {
      document.body.removeEventListener('click', handleBodyClick, true);
    });
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <SelectFormWrapper width={props.width}>
      <Selected onClick={() => { setShow(true); }}>{value}</Selected><ArrowDown />
      {show && (
        <SelectList>
          {data.map((v, i) => (
            <SelectItem onClick={handleSelect} data-prevent="click" key={i}>{v}</SelectItem>  
          ))}
        </SelectList>
      )}
    </SelectFormWrapper>
  );
};

export default SelectForm;