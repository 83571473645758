import React from 'react';
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';

import App from 'app';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
);

// remove console.log on product mode
if (process.env.NODE_ENV === 'product') {
  console.log = () => {};
}