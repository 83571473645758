import React from 'react';

const Times = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12">
      <g fill="none" fillRule="evenodd">
        <g fill={props.color || '#FFF'}>
          <path d="M8.43.483L5.9 3.011 3.37.481c-.363-.378-.902-.53-1.408-.398C1.455.216 1.059.612.927 1.12c-.131.507.022 1.046.4 1.408l2.529 2.53-2.527 2.527c-.55.568-.542 1.472.016 2.03.56.56 1.463.567 2.031.018L5.9 7.1l2.528 2.529c.363.377.902.529 1.408.396.507-.132.902-.527 1.034-1.034.133-.506-.019-1.045-.396-1.408L7.95 5.058l2.527-2.527c.55-.568.542-1.472-.016-2.03C9.9-.06 8.998-.067 8.43.482z" transform="translate(-793 -232) translate(793 232.5)" />
        </g>
      </g>
    </svg>
  );
};

export default Times;