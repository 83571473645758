import React from 'react';
import styled from 'styled-components/macro';

import DefaultButton from 'components/button/DefaultButton';
import { palette, zIndex } from 'modules/defines/styles';
import Localization from 'i18n';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: ${zIndex.modal};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,.5);
`;
const ModalBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 352px;
  padding: 20px 30px;
  border: 1px solid ${palette.blue};
  border-radius: 5px;
  background-color: ${palette.white};
`;
const ModalText = styled.div`
  min-height: 25px;
  margin-bottom: 20px;
  color: ${palette.black};
  font-weight: 700;
  text-align: center;
`;
const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
`;

const ModalPopup = (props) => (
  <ModalWrapper>
    <ModalBody>
      <ModalText>{props.children}</ModalText>
      <ModalButtons>    
        <DefaultButton onClick={() => props.onConfirm && props.onConfirm()}>{props.confirmText || Localization.confirm}</DefaultButton>
        <DefaultButton theme="red" onClick={() => props.onClose && props.onClose()}>{props.cancelText || Localization.cancel}</DefaultButton>
      </ModalButtons>
    </ModalBody>
  </ModalWrapper>
);

export default ModalPopup;