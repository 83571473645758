import React from 'react';

const Calendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <g fill="none" fillRule="evenodd">
      <g fill="#3C3C3B">
        <path d="M3.947 3.947c.21 0 .41-.11.559-.304.148-.195.231-.459.23-.734V1.037C4.737.464 4.384 0 3.948 0c-.436 0-.79.464-.79 1.037V2.91c0 .275.084.54.232.734.148.195.349.304.558.304zM11.053 3.947c.21 0 .41-.11.558-.304.148-.195.231-.459.231-.734V1.037c0-.573-.353-1.037-.79-1.037-.435 0-.789.464-.789 1.037V2.91c0 .275.083.54.231.734.148.195.35.304.559.304z" transform="translate(-249 -1005) translate(249 1005)" />
        <path d="M13.573 1.586v1.55c.026 1.218-.87 2.263-2.083 2.43-.654.067-1.306-.145-1.795-.583-.488-.438-.767-1.06-.767-1.715V1.961c0-.211-.173-.382-.386-.382H6.458c-.212 0-.384.171-.384.382v1.172c.028 1.22-.868 2.265-2.083 2.433-.654.067-1.306-.145-1.795-.583-.488-.438-.767-1.06-.767-1.715V1.586C.631 1.624.003 2.276 0 3.07V13.51C0 14.332.672 15 1.5 15h12c.828 0 1.5-.668 1.5-1.491V3.07c-.003-.793-.63-1.445-1.427-1.484zM3.214 8.732c.592 0 1.072-.477 1.072-1.066 0-.588-.48-1.065-1.072-1.065-.591 0-1.071.477-1.071 1.065 0 .589.48 1.066 1.071 1.066zm0 3.195c.592 0 1.072-.477 1.072-1.065s-.48-1.065-1.072-1.065c-.591 0-1.071.477-1.071 1.065s.48 1.065 1.071 1.065zM7.5 8.732c.592 0 1.071-.477 1.071-1.066 0-.588-.48-1.065-1.071-1.065-.592 0-1.071.477-1.071 1.065 0 .589.48 1.066 1.071 1.066zm0 3.195c.592 0 1.071-.477 1.071-1.065S8.091 9.797 7.5 9.797c-.592 0-1.071.477-1.071 1.065s.48 1.065 1.071 1.065zm4.286-3.195c.591 0 1.071-.477 1.071-1.066 0-.588-.48-1.065-1.071-1.065-.592 0-1.072.477-1.072 1.065 0 .589.48 1.066 1.072 1.066zm0 3.195c.591 0 1.071-.477 1.071-1.065s-.48-1.065-1.071-1.065c-.592 0-1.072.477-1.072 1.065s.48 1.065 1.072 1.065z" transform="translate(-249 -1005) translate(249 1005)" />
      </g>
    </g>
  </svg>
);

export default Calendar;