import React from 'react';

const Grip = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <g transform="translate(-43 -180) translate(43.5 180)">
          <circle cx="1.5" cy="1.5" r="1.5" />
          <circle cx="1.5" cy="5.5" r="1.5" />
          <circle cx="6.5" cy="1.5" r="1.5" />
          <circle cx="6.5" cy="5.5" r="1.5" />
          <circle cx="11.5" cy="1.5" r="1.5" />
          <circle cx="11.5" cy="5.5" r="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default Grip;
