import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import DefaultButton from 'components/button/DefaultButton';
import IconFolder from 'components/icons/Folder';
import { fontEn, palette } from 'modules/defines/styles';
import Localization from 'i18n';

const FileUploadModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 352px;
  padding: 20px 30px;
  border: 1px solid ${palette.blue};
  border-radius: 5px;
  background-color: ${palette.white};
`;
const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 22px;
  padding-right: 20px;
`;
const ModalFormLabel = styled.label`
  margin-right: 10px;
  color: ${palette.black};
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
`;
const MadalFormWrap = styled.div`
  ${props => (props.theme === 'fix' ? (`
    flex-shrink: 0;
  `) : (`
    width: 100%;
  `))}
  position: relative;
  width: 237px;
  border-bottom: 1px solid ${palette.form};
  input {
    width: 100%;
  }
  input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 19px);
    height: 20px;
    cursor: pointer;
  }

  svg {
    position: absolute;
    right: -19px;
    top: 0;
  }
  &:hover svg g {
    fill: ${palette.blue};
  }
`;
const FileOutput = styled.div`
  height: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${fontEn};
`;
const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileUploadModal = (props) => {
  const [fileUpload, setFileUpload] = useState(props.data || {
    sectionName: '',
    upper: '',
    lower: '',
  });

  useEffect(() => {
    setFileUpload(prev => ({ ...prev, ...props.data }));
  }, [props.data]);

  return (
    <FileUploadModalWrapper>
      <ModalRow>
        <ModalFormLabel htmlFor="sectionName">{Localization.inputSTLName}</ModalFormLabel>
        <MadalFormWrap><input
          type="text" name="sectionName" id="sectionName" value={fileUpload.sectionName} placeholder={Localization.inputSetName}
          onChange={(e) => props.onChange && props.onChange(e)}
        />
        </MadalFormWrap>
      </ModalRow>
      <ModalRow>
        <ModalFormLabel htmlFor="upper">{Localization.maxillary}</ModalFormLabel>
        <MadalFormWrap theme="fix">
          <FileOutput>{fileUpload.upper}</FileOutput>
          <IconFolder />
          <input
            type="file" name="upper" id="upper" ref={props.$upper} placeholder={Localization.selectFile}
            onChange={(e) => props.onChange && props.onChange(e)}
          />
        </MadalFormWrap>
      </ModalRow>
      <ModalRow>
        <ModalFormLabel htmlFor="lower">{Localization.mandible}</ModalFormLabel>
        <MadalFormWrap theme="fix">
          <FileOutput>{fileUpload.lower}</FileOutput>
          <IconFolder />
          <input
            type="file" name="lower" id="lower" ref={props.$lower} placeholder={Localization.selectFile}
            onChange={(e) => props.onChange && props.onChange(e)}
          />
        </MadalFormWrap>
      </ModalRow>
      <ModalButtons>
        <DefaultButton theme="red" onClick={(e) => props.onCancel && props.onCancel(e)}>{Localization.cancel}</DefaultButton>
        <DefaultButton onClick={(e) => props.onSumit && props.onSumit(e)}>{Localization.upload}</DefaultButton>
      </ModalButtons>
    </FileUploadModalWrapper>
  );
};

export default FileUploadModal;