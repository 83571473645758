import { palette } from 'modules/defines/styles';
import React, { useEffect, useState } from 'react';

const Sort = ({ width, height, theme }) => {
  const [color, setColor] = useState('');

  useEffect(() => {
    setColor(palette.black);
  });
  
  
  return (
  <svg width={width || '18'} height={height || '18'} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* <path fill={theme === 'desc' ? color : '#C4C4C4'} d="M9.00012 4.3725L11.3776 6.75L12.4351 5.6925L9.00012 2.25L5.55762 5.6925L6.62262 6.75L9.00012 4.3725ZM9.00012 13.6275L6.62262 11.25L5.56512 12.3075L9.00012 15.75L12.4426 12.3075L11.3776 11.25L9.00012 13.6275Z" /> */}
    <path fill={theme === 'desc' ? color : '#C4C4C4'} d="M9 4.3725L11.3775 6.75L12.435 5.6925L9 2.25L5.5575 5.6925L6.6225 6.75L9 4.3725Z" />
    <path fill={theme === 'asc' ? color : '#C4C4C4'} d="M6.6225 11.25L9 13.6275L11.3775 11.25L12.4425 12.3075L9 15.75L5.565 12.3075L6.6225 11.25Z" />
  </svg>
);
};

export default Sort;