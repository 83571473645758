import React from 'react';

const Back = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M6.725 7.332v3.189c0 .492-.328.627-.731.303L.303 6.093C.112 5.953 0 5.735 0 5.505c0-.23.112-.448.303-.589l5.69-4.74c.405-.325.732-.188.732.304v3.186h.963c3.677 0 6.821 2.902 7.588 6.037.056.233.097.469.12.707.029.244-.096.298-.28.126 0 0-.052-.044-.097-.092-1.946-1.88-4.564-2.992-7.333-3.116h-.963l.002.004z" transform="translate(-547 -27) translate(547 27)" />
      </g>
    </g>
  </svg>
);

export default Back;
