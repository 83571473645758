import IconUserAdd from './UserAdd';
import IconUser from './User';
import IconImport from './Import';
import IconExport from './Export';
import IconBack from './Back';
import IconForward from './Forward';
import IconClose from './Close';
import IconShapeAuto from './ShapeAuto';
import IconShape from './Shape';
import IconShapeEdit from './ShapeEdit';
import IconShapeSlice from './ShapeSlice';
import IconTeethS from './TeethS';
import IconTeethDevide from './TeethDevide';
import IconTeethMove from './TeethMove';
import IconTooth from './Tooth';
import IconSideMapOff from './SideMapOff';
import IconSideShapeOff from './SideShapeOff';
import IconSideTeethAllOff from './SideTeethAllOff';
import IconSideTeethViewBaseOff from './SideTeethViewBaseOff';
import IconSideViewOff from './SideViewOff';
import IconSideTeethViewMoveOff from './SideTeethViewMoveOff';
import IconSideViewOutlineOff from './SideTeethViewOutlineOff';

import IconSideTeethBottomOff from './SideTeethBottomOff';
import IconSideTeethNoneOff from './SideTeethNoneOff';
import IconSideTeethTopOff from './SideTeethTopOff';

export {
  IconUserAdd, IconUser, IconImport, IconExport,
  IconBack, IconForward, IconClose, IconShapeAuto,
  IconShape, IconShapeEdit, IconShapeSlice, IconTeethS,
  IconTeethDevide, IconTeethMove, IconTooth, IconSideMapOff, IconSideShapeOff,
  IconSideTeethAllOff, IconSideTeethViewBaseOff, IconSideViewOff,
  IconSideTeethViewMoveOff, IconSideViewOutlineOff, IconSideTeethBottomOff,
  IconSideTeethNoneOff, IconSideTeethTopOff,
};
