import React, { useRef, useContext, useState, useEffect } from 'react';
import * as THREE from 'three';
import {
    extend, Canvas, useThree, useFrame,
} from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import ViewerContext from 'modules/context/ViewerContext';
import GlobalContext from 'modules/context/GlobalContext';

const Overview3DCanvas = () => {
    const viewerContext = useContext(ViewerContext);
    const globalContext = useContext(GlobalContext);
    const { display } = viewerContext;
    const checkDate = new Date('2023-03-31');
    const createDate = new Date(globalContext.patientInfo.created_at);

    const Scene = () => {

        useThree(({ camera }) => {
            camera.lookAt(new THREE.Vector3(0, 0, 0));
        });
        const camSettings = {
            position: new THREE.Vector3(0, (display.teeth === 'upper' ? -150 : 150), 0),
            fov: 35,
            up: new THREE.Vector3(0, 0, display.teeth === 'upper' ? 1 : -1),
        };
        console.log(camSettings, 'check');
        const dimmer = 0.75;

        return (
            <>
                <PerspectiveCamera makeDefault {...camSettings}>
                    <ambientLight intensity={0.15 * dimmer} />
                    <pointLight position={[-200, 200, 200]} intensity={0.45 * dimmer} />
                    <pointLight position={[200, 200, 200]} intensity={0.65 * dimmer} />
                    <pointLight position={[0, 200, -200]} intensity={1.0 * dimmer} />
                </PerspectiveCamera>
                { viewerContext.upperGeometry && (
                    <group>
                        <mesh geometry={display.teeth === 'upper' ? viewerContext.upperGeometry : viewerContext.lowerGeometry}  position={new THREE.Vector3(0, 0, 0)}>
                            <meshPhongMaterial type="MeshPhongMaterial" color="#eeeeee" emissive={0} specular="#bcb7a3" shininess={7.36} />
                        </mesh>
                        { Object.entries(viewerContext.labels[viewerContext.display.teeth]).map(([n, labelData]) => (labelData.origin && (
                            <mesh key={n} position={display.teeth === 'upper' ? labelData.origin.clone().add(new THREE.Vector3(0, -5, 0)) : labelData.origin.clone().add(new THREE.Vector3(0, 10, 0))} rotation={[Math.PI / 2, 0, 0]}>
                                <meshPhongMaterial color={n === viewerContext.toothNumber.toString() ? 0xff5555 : 0x55ff55} />
                                <torusGeometry args={[3, 0.25, 6, 32]} />
                            </mesh>
                        )))}
                    </group>
                )}
            </>
        );
    };

    return (
        <Canvas width={300} height={300}>
            <Scene />
        </Canvas>
    );
};

export default Overview3DCanvas;
