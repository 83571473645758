import React from 'react';
import styled from 'styled-components/macro';

import { palette } from 'modules/defines/styles';

const TitleWrapper = styled.div`
  h3 {
    color: ${palette.gray};
    font-size: 25px;
    font-weight: 300;
    span {
      color: ${palette.blue};
    }
  }
`;

const ContentTitle = (props) => (
  <TitleWrapper>
    <h3>{props.children}</h3>
  </TitleWrapper>
);

export default ContentTitle;

const SubTitleWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;

  h4 {
    color: #737373;
    font-size: 20px;
    font-weight: 300;
    span {
      color: ${palette.blue};
    }
  }
`;

export const SubTitle = (props) => (
  <SubTitleWrapper>
    <h4>{props.children}</h4>
  </SubTitleWrapper>
);