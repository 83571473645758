import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'modules/defines/names';

const ToggleWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 15px;
  border: 3px solid #333;
  border-radius: 7px;
  padding: 1px;
  cursor: pointer;

  .${theme.dark} & {
    border-color: #fff;
    background-color: #fff;
  }
`;

const ToggleCtrl = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #333;
  ${(props) => (props.theme === 'on' && 'margin-left: 8px;')}
  transition: margin 0.1s ease-out;
`;

const ToggleButton = (props) => {
  const [value, setValue] = useState(props.value || true);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <ToggleWrapper
      onClick={() => {
        setValue(!value);
        if (props.onChange) props.onChange(!value);
      }}
    >
      <ToggleCtrl theme={value ? 'on' : 'off'} />
    </ToggleWrapper>
  );
};

export default ToggleButton;
