import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import styled from 'styled-components/macro';
import * as THREE from 'three';
import LabelStepPanel from 'components/common/LabelStepPanel';
import ViewerContext from 'modules/context/ViewerContext';
import DefaultButton from 'components/button/DefaultButton';
import ArrowNext from 'components/icons/ArrowNext';
import ArrowPrev from 'components/icons/ArrowPrev';
import Localization from 'i18n';
import { IndexButton } from 'components/list/IndexButton';
import SideTeethBottom from 'components/icons/SideTeethBottom';
import SideTeethTop from 'components/icons/SideTeethTop';
import { palette } from 'modules/defines/styles';
import fileAPI from 'modules/api/file';
import GlobalContext from 'modules/context/GlobalContext';
import { useCookies } from 'react-cookie';
import ModalPopup from 'components/modal/ModalPopup';
import Check from 'components/icons/Check';


const ControlBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    background: #FFFFFF;
    border: 0.5px solid #B4B4B4;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
      
    & > span {
        font-size: 9px;
        color: rgba(140, 140, 140, 1);
        position: absolute;
        bottom: calc(100% + 4px);
    }
`;


const ToothBox = styled(ControlBox)`
    width: 50px;
    height: 42px;
`;

const ToothNumber = styled.input`
    color: black;
    font-size: 18px;
    text-align: center;
    border: none;
    width: 45px;
    height: 35px;
`;

const MeshSelect = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  `;

const Selector = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    color: ${props => (props.active ? palette.gray : palette.disabled)};
    fill: ${props => (props.active ? palette.blue : palette.disabled)};
    cursor: pointer;
    svg { 
        margin-right: 7px;
    }
    & + & {
        margin-top: 4px;
    }
`;

const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    }
`;

const StepBox = styled(ControlBox)`
    width: 190px;
    height: 42px;
`;

const ToothStepper = styled(IndexButton)`
    position: absolute;
    ${props => props.pos};
`;

const MissingStepper = styled(IndexButton)`
    position: absolute;
    ${props => props.pos};
    & > span {
        font-size: 9px;
        color: rgba(140, 140, 140, 1);
        position: absolute;
        left: -8px;
        bottom: calc(100% + 4px);
    }
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  border: thin solid #bebebe;
  border-radius: 6px;
  padding: 6px;
`;

const StepTitle = styled.div`
    font-size: 11px;
    text-align: center;
    color: ${props => (props.ctx.labelingStep === props.step ? 'red' : '#8b8b8b')};
`;

const ListSelect = styled.select`
    font-size: 13px;
    color: #000000;
    border: none;
    text-align: center;
`;

const DeleteBtn = styled(DefaultButton)`
    min-width: 50px;
    padding: 6px;
    margin-bottom: 5px;
    border-radius: 17px 17px 17px 17px;
`;

const BackBtn = styled(DefaultButton)`
    min-width: 45px;
    padding: 0;
    margin-right: 3px;
    border-radius: 17px 0 0 17px;
`;

const NextBtn = styled(DefaultButton)`
    min-width: 100px;
    padding: 0;
    border-radius: 0 17px 17px 0;
`;


const Steppers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LabelStep = () => {
    const history = useHistory();
    const viewerContext = useContext(ViewerContext);
    const globalContext = useContext(GlobalContext);
    const [display, setDisplay] = useState(viewerContext.step);
    const [selected, setSelected] = useState('');
    const [displaySaveModal, setDisplayModal] = useState(false);
    const [error, setError] = useState(false);
    const [saveCheck, setSaveCheck] = useState(false);
    const [deleted, setDelete] = useState(false);
    const cookies = useCookies();
    const selectList = ['None', 'Working', 'Crosscheck', 'Finish'];
    const upperLabels = viewerContext.labels.upper[viewerContext.toothNumber];
    const lowerLabels = viewerContext.labels.lower[viewerContext.toothNumber];
    const dataName = globalContext.patientInfo.patient_id;

    const downloadFile = async ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });
        const link = document.createElement('a');
        link.download = fileName;
        link.href = await URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
            view: window,
        
            bubbles: true,
            cancelable: true,
          });
          link.dispatchEvent(clickEvt);
          link.remove();
    };

    const nextStep = () => {
        viewerContext.labelingSteps[viewerContext.labelingStep].holdAtStep = false; // clear any prior step hold
        if (viewerContext.toothNumber === 27) {
            selectTeeth('lower');
            viewerContext.nextStep();
        } else if ( viewerContext.toothNumber === 47 && viewerContext.labelingStep === 6) {
            setSaveCheck(true);
        } else {
            viewerContext.nextStep();
        }
    };

    const backStep = () => {
        viewerContext.labelingSteps[viewerContext.labelingStep].holdAtStep = false; // clear any prior step hold
        viewerContext.backStep();
    };

    useEffect(() => {
        const handleKey = (e) => {
            if (e.key === 'ArrowRight' || e.key === 'x') {
                document.getElementById('next-step-btn').click(); // what a hack, bloody react is binding old copies of viewerContext, direct call fails
            } else if ( e.key === 'ArrowLeft' || e.key === 'z' ) {
                // if ( viewerContext.labelingSteps[viewerContext.labelingStep].title !== 'Pick mesial point' ) {
                document.getElementById('back-step-btn').click();
                // }
            }
        };
            document.addEventListener('keydown', handleKey);
        return () => {
            document.removeEventListener('keydown', handleKey);
        };
    }, [viewerContext.labelingStep]);

    useEffect(() => {
        let patient = globalContext.patientInfo.patient_id;
        if (!patient) {
            patient = cookies.patient;
            globalContext.set(prev => ({ ...prev, patient }));
        }
        setDisplay(viewerContext.step);
        setSelected(globalContext.patientInfo.working_state);
        const netWork = window.navigator.onLine;
        if ( netWork !== true) {
            setError(true);
        }

    }, [viewerContext.step, globalContext.patientInfo.working_state]);

    const handleBack = () => {
        history.goBack();
    };

    const handleSaveModal = () => {
        setDisplayModal(true);
    };
    const handleSave = (saveTitle) => {
        console.log('save working history >> ', saveTitle);
        setDisplayModal(false);
    };
    const handleCancel = () => {
        console.log('remove working history');
        setDisplayModal(false);
    };
    const handleClose = () => {
        setSaveCheck(false);
        history.push(pagePaths.viewer);
    };

    const handleDelete = () => {
        viewerContext.setDeleteLabel(viewerContext.toothNumber, viewerContext.display.teeth, true);
    };


    const handleSelect = (e) => {
        setSelected(e.target.value);
        const workingProcess = e.target.value;
        const workingId = cookies[0].patient;
        const updateVal = { working_state: workingProcess, patient_id: workingId };
        
        fileAPI.update(updateVal, cookies[0]).then(response => {
            if (response.success) {
                setSelected(updateVal.working_state);
            }
        });
    };

    const handleConfirm = () => {
        setError(false);
        window.location.reload();
      };

    const modeChange = e => { }; // this is not implemented yet, only stepping backwards is supported

    //const bumpTooth = incr => viewerContext.setToothNumber(viewerContext.toothNumber + incr);
    const incrTooth = incr => {
        viewerContext.labelingSteps[viewerContext.labelingStep].holdAtStep = false; // clear any prior step hold
        viewerContext.setToothNumber(viewerContext.toothNumber === 17 || viewerContext.toothNumber === 37 ? viewerContext.toothNumber + incr + 3 : viewerContext.toothNumber + incr);
    };
    const decrTooth = decr => {
        viewerContext.labelingSteps[viewerContext.labelingStep].holdAtStep = false; // clear any prior step hold
        viewerContext.setToothNumber(viewerContext.toothNumber === 21 || viewerContext.toothNumber === 41 ? viewerContext.toothNumber - decr - 3 : viewerContext.toothNumber - decr);
    };

    const missingTooth = () => {
        if ( viewerContext.toothNumber === 17 || viewerContext.toothNumber === 37 ) {
            viewerContext.setMissingTooth(viewerContext.toothNumber, viewerContext.display.teeth, true);
        } else if ( viewerContext.toothNumber === 47 ) {
            setSaveCheck(true);
        } else {
            viewerContext.setMissingTooth(viewerContext.toothNumber, viewerContext.display.teeth, true);
        }
        
    };

    const handleDownload = () => {
        setSaveCheck(false);
        downloadFile({ data: JSON.stringify(viewerContext.labels), 
            fileName: `${dataName}.json`,
            fileType: 'text/json',  
        });
        history.push(pagePaths.patientList);
    };

    const selectTeeth = (teeth) => {
        viewerContext.update({ camSettings: { ...viewerContext.camSettings, position: new THREE.Vector3(0, teeth === 'upper' ? -100 : 100, 0) } });
        viewerContext.updateDisplay({ teeth });
        viewerContext.setToothNumber(teeth === 'upper' ? 11 : 31, teeth);
    };
    
    const Tooth = () => (
        <ToothBox>
            <span>Tooth #</span>
            <ToothNumber ref={input => { if (input) input.value = viewerContext.toothNumber; }} />
            <ToothStepper disabled={viewerContext.toothNumber === 27 || viewerContext.toothNumber === 47} pos="right: -20px" onClick={() => incrTooth(1)}><ArrowNext /></ToothStepper>           
            <MissingStepper active={(upperLabels && upperLabels.missingStatus) || (lowerLabels && lowerLabels.missingStatus)} pos="right: -40px" onClick={() => missingTooth()}><Check /><span>missing</span></MissingStepper>
            <ToothStepper disabled={viewerContext.toothNumber <= 11 || viewerContext.toothNumber === 31} pos="left: -20px" onClick={() => decrTooth(1)}><ArrowPrev /></ToothStepper>
        </ToothBox>        
    );

    const Step = () => (
        <StepBox>
            <span>Step</span>
            <ListSelect value={viewerContext.labelingStep} onChange={modeChange}>
                { viewerContext.labelingSteps.map((o, i) => <option key={i} value={i}>{o.title}</option>) }
            </ListSelect>
        </StepBox>
    );



    const SelectBox = () => {
        return (
            <select onChange={handleSelect} value={selected}>
            {selectList.map((item) => (
                <option value={item} key={item}>{item}</option>
            ))}                
            </select>
        );
    };

    const Status = () => (
        <StatusWrapper>
           <SelectBox id="status-step" />
        </StatusWrapper>
    );

    return (

        <LabelStepPanel
            title="Labeling control" back={Localization.patientManagement} displayMode={display} modalState={displaySaveModal}
            onBack={handleBack} onOpenModal={handleSaveModal} onSave={handleSave} onCancel={handleCancel}
        >
            <Tooth />
            <DeleteBtn disabled={((upperLabels && !upperLabels.mesial) && viewerContext.toothNumber < 28) || ((lowerLabels && !lowerLabels.mesial) && viewerContext.toothNumber > 30)} active={(upperLabels && upperLabels.mesial) || (lowerLabels && lowerLabels.mesial)} onClick={() => handleDelete()}>Delete</DeleteBtn>
            <MeshSelect>
                <Selector active={viewerContext.display.teeth === 'upper'} onClick={() => selectTeeth('upper')}><SideTeethTop />Maxilliary </Selector>
                <Selector active={viewerContext.display.teeth === 'lower'} onClick={() => selectTeeth('lower')}><SideTeethBottom />Mandible</Selector>
            </MeshSelect>
            <Steps>
                { viewerContext.labelingSteps.map((ls, i) => (
                    <StepTitle key={i} step={i} ctx={viewerContext}>{ls.title}</StepTitle>
                ))}
            </Steps>
            {/*<Step />*/}
            <Steppers>
                <BackBtn id="back-step-btn" disabled={viewerContext.labelingStep < 1} onClick={backStep}>Back</BackBtn>
                {error && <ModalPopup onConfirm={handleConfirm}> 네트워크 연결 끊김 연결을 확인해주세요 </ModalPopup>}
                {saveCheck && <ModalPopup onConfirm={handleDownload} onClose={handleClose}> 파일을 저장하고 나가시겠습니까? </ModalPopup>}
                <NextBtn id="next-step-btn" disabled={viewerContext.nextStepDisabled()} onClick={nextStep}>Next</NextBtn>
            </Steppers>
            <span>Status #</span>
            <Status />
        </LabelStepPanel>

    );
};

export default LabelStep;