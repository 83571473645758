import React, { useEffect, useState } from 'react';
import * as THREE from 'three';

// a circular plane through the lingual, cusp tip and buccal points
const LingualTipBuccalPlane = ({ viewerContext }) => {
    const labelData = viewerContext.getLabelData();
    const [planeRef, setPlaneRef] = useState();

    useEffect(() => {
        if (planeRef) {
            const center = labelData.lingualBuccalPlaneCenter;
            planeRef.position.set(center.x, center.y, center.z);
            planeRef.lookAt(labelData.lingualBuccalPlaneLookAt);
        }
    }, [planeRef]);

    return (
        <mesh ref={setPlaneRef} position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]}>
            <meshPhongMaterial color={0xffffff} opacity={0.65} transparent side={THREE.DoubleSide} />
            <circleGeometry args={[7, 32]} />
        </mesh>
    );
};

export default LingualTipBuccalPlane;
