import React from 'react';
import styled from 'styled-components/macro';
import { size } from 'modules/defines/styles';

const ContentsLayoutWrapper = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  ${props => props.width && (`
    margin: auto;
  `)}
  box-sizing: content-box;
  padding: 105px 280px ${size.header};
`;

const InnerContentLayout = (props) => (
  <ContentsLayoutWrapper width={props.width}>
    {props.children}
  </ContentsLayoutWrapper>
);

export default InnerContentLayout;