import React from 'react';

const SideMapOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24" height="23" viewBox="0 0 24 23"
    className={props.className}
  >
    <defs>
      <path id="f8q8spuila" d="M0 0L19 0 19 17 0 17z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M10 6c1.105 0 2-.448 2-1s-.895-1-2-1-2 .448-2 1 .895 1 2 1" transform="translate(-38 -306) translate(38.5 306)" />
      <g transform="translate(-38 -306) translate(38.5 306)">
        <mask id="ysq9loy6nb" fill="#fff">
          <use xlinkHref="#f8q8spuila" />
        </mask>
        <path fill="#FFF" d="M9.5 1.322c3.82 0 8.078 1.403 8.078 3.416S13.321 8.154 9.5 8.154c-3.82 0-8.078-1.403-8.078-3.416S5.679 1.322 9.5 1.322m0 14.356c-3.82 0-8.078-1.403-8.078-3.416V7.264C3.083 8.602 6.049 9.476 9.5 9.476c3.452 0 6.417-.874 8.078-2.212v4.998c0 2.013-4.257 3.416-8.078 3.416m9.5-3.416V4.738C19 2.08 14.827 0 9.5 0S0 2.081 0 4.738v7.524C0 14.92 4.173 17 9.5 17s9.5-2.08 9.5-4.738z" mask="url(#ysq9loy6nb)" />
        <path fill="#FFF" d="M0.951 6.679L0.951 12.913 3.432 15.177 7.6 16.036 10.718 16.036 14.874 15.177 16.665 14.259 17.578 13.278 18.137 12.176 18.137 6.923 16.231 7.63 13.468 8.5 10.718 9.213 7.6 8.817 4.597 8.154 2.067 7.264z" mask="url(#ysq9loy6nb)" />
      </g>
      <path fill="#A7A7A7" d="M17 14c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 1.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm0-4.5c.414 0 .75.336.75.75v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.75c0-.414.336-.75.75-.75zm0 9.75c.414 0 .75.336.75.75v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.75c0-.414.336-.75.75-.75zm-5.25-4.5h.75c.414 0 .75.336.75.75s-.336.75-.75.75h-.75c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zm9.75 0h.75c.414 0 .75.336.75.75s-.336.75-.75.75h-.75c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zm-8.743-3.495c.293-.293.768-.293 1.06 0l.531.532c.207.186.294.47.227.74-.067.27-.278.48-.548.547-.27.067-.555-.022-.74-.229l-.53-.525c-.292-.295-.292-.77 0-1.065zm6.895 6.9c.293-.293.768-.293 1.06 0l.53.525c.295.293.296.769.003 1.063-.293.294-.769.295-1.063.002l-.53-.533c-.14-.14-.22-.33-.22-.528 0-.199.08-.39.22-.529zm-6.895.525l.53-.525c.185-.207.47-.296.74-.23.27.067.48.278.548.547.067.27-.02.555-.227.74l-.53.533c-.294.293-.77.292-1.063-.002-.293-.294-.292-.77.002-1.063zm6.895-6.893l.53-.532c.19-.19.467-.263.727-.193.259.07.46.273.53.533.069.259-.006.536-.196.725l-.53.525c-.185.207-.47.296-.74.23-.27-.067-.48-.278-.548-.547-.067-.27.02-.555.227-.74z" transform="translate(-38 -306) translate(38.5 306)" />
    </g>
  </svg>
);

export default SideMapOff;