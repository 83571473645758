import React, { useState, useEffect, useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-date-picker';
import ReactHtmlParser from 'react-html-parser';

import InnerContentLayout from 'components/layout/InnerContentLayout';
import ContentTitle, { SubTitle } from 'components/common/ContentTitle';
import DefaultButton from 'components/button/DefaultButton';
import IconTimes from 'components/icons/Times';
import IconCheck from 'components/icons/Check';
import IconCalendar from 'components/icons/Calendar';
import IconImport from 'components/icons/Import';
import { Forms, FormGroups, FormItem, Label, FormButtons, FormButtonInner, FormArea, TextError } from 'components/forms/Forms';
import SelectForm from 'components/forms/SelectForm';
import FileUploadModal from 'components/forms/FileUploadModal';
import FileList from 'components/list/FileList';
import SquareTrashButton from 'components/button/SquareTrashButton';

import { pagePaths } from 'modules/defines/paths';
import { selectGender } from 'modules/defines/names';
import patientAPI from 'modules/api/patient';
import fileAPI from 'modules/api/file';
import GlobalContext from 'modules/context/GlobalContext';
import Localization from 'i18n';

const ViewContainer = (props) => {
  const { history, theme, patientId, onBack } = props;
  const [cookies] = useCookies();
  const globalContext = useContext(GlobalContext);
  const $upper = useRef();
  const $lower = useRef();

  const defaultData = {
    name: '',
    chart_id: '',
    date_of_birth_year: '',
    date_of_birth_month: '',
    date_of_birth_day: '',
    created_at: new Date(),
    gender: '',
    remarks: '',
    company_id: '',
    hospital_name: '',
  };
  const [formData, setFormData] = useState(defaultData);
  const [fileData, setFileData] = useState([]);
  const [focus, setFocus] = useState({
    name: false,
    chart_id: false,
    hospital_name: false,
  });
  const [showModal, setFileModal] = useState(false);
  const [fileUpload, setFileUpload] = useState({
    sectionName: '',
    upper: '',
    lower: '',
  });
  const [error, setError] = useState({
    display: false,
    message: '',
  });

  useEffect(() => {
    if (!cookies.user) {
      history.push(pagePaths.login);
      return;
    }

    // 새로고침 시 context 데이터 초기화 되어 데이터 다시 세팅
    let user = globalContext.user;
    if (!user.id) {
      user = cookies.user;
      globalContext.set(prev => ({ ...prev, user }));
    }

    if (patientId) {
      requestPatientInfo(user);
    } else {
      setFormData(prev => ({ ...prev,
        gender: selectGender[0],
        company_id: user.CompanyInformation.id,
        hospital_name: user.CompanyInformation.hospital_name }));
    }

    return () => {
      globalContext.closeModal();
    };
  }, []);

  const requestPatientInfo = (user) => {
    patientAPI.detail(user.id, patientId).then(response => {
      if (response.success) {
        const { data } = response;
        setFormData(prev => ({ ...prev,
          id: data.id,
          chart_id: data.chart_id,
          name: data.name,
          gender: data.gender,
          date_of_birth_year: data.date_of_birth.split('-')[0],
          date_of_birth_month: data.date_of_birth.split('-')[1],
          date_of_birth_day: data.date_of_birth.split('-')[2],
          created_at: data.created_at.split('T')[0],
          remarks: data.remarks || '',
          company_id: user.CompanyInformation.id,
          hospital_name: user.CompanyInformation.hospital_name }));
        setFileData(data.AshleySTLProjects);
      } else {
        history.push(pagePaths.patientList.replace(':userId', user.id));
      }
    });
  };

  const handleDatePicker = (name, value) => {
    const obj = {};
    obj[name] = value;
    setFormData(prev => ({ ...prev, ...obj }));
  };

  // form value change
  const handleChange = (e) => {
    const obj = {};
    const name = e.target.name;
    let value = e.target.value;

    // 생년월일 input에 숫자만 입력 허용
    if (name.indexOf('date_of_birth') !== -1) {
      value = value.replace(/[^0-9]/g, '');
    }

    obj[name] = value;
    setFormData(prev => ({ ...prev, ...obj }));

    if (name === 'date_of_birth_year' && value.length === 4) {
      document.querySelector('[name=date_of_birth_month]').focus();
    }
    if (name === 'date_of_birth_month' && value.length === 2) {
      document.querySelector('[name=date_of_birth_day]').focus();
    }

    if (name === 'chart_id') {
      setError({ ...error, display: false, message: '' });
    }
  };

  // input focus
  const handleFocus = (e) => {
    const newObj = {};
    newObj[e.target.name] = true;
    setFocus(prev => ({ ...prev, ...newObj }));
  };

  // input blur
  const handleBlur = (e) => {
    const newObj = {};
    newObj[e.target.name] = false;
    setFocus(prev => ({ ...prev, ...newObj }));
  };

  // 성별 셀렉트 폼
  const handleSelect = (value) => {
    setFormData(prev => ({ ...prev, gender: value }));
  };

  // 저장/생성
  const handleSubmit = () => {
    if (formData.chart_id.trim() === '') {
      setError({ ...error, display: true, message: Localization.chartIdRequred });
      return;
    }

    const rqData = formData;
    rqData.date_of_birth = `${formData.date_of_birth_year}-${formData.date_of_birth_month}-${formData.date_of_birth_day}`;
    delete rqData.date_of_birth_year;
    delete rqData.date_of_birth_month;
    delete rqData.date_of_birth_day;

    delete rqData.hospital_name;
    delete rqData.CompanyInformation;
    rqData.chart_id = formData.chart_id.trim();

    if (rqData.gender === selectGender[0]) rqData.gender = '';

    if (patientId) {
      // update patient
      patientAPI.update(rqData).then(response => {
        if (response.success) {
          // setPatientState(defaultData);
          history.push(pagePaths.patientList);
        } else {
          // handle error
          console.log(response);
        }
      });
    } else {
      // create
      delete rqData.remarks;
      rqData.user_id = globalContext.user.id;

      patientAPI.create(rqData).then(response => {
        if (response.success) {
          history.push(pagePaths.patientList);
        } else {
          // handle error
          setError({ ...error, display: true, message: response.data });
        }
      });
    }
  };

  // 파일 선택
  const handleFileChange = (e) => {
    const newObj = {};
    newObj[e.target.name] = e.target.value;
    setFileUpload({ ...fileUpload, ...newObj });
  };
  // 파일 올리기 취소
  const handleFileCancel = () => {
    setFileModal(false);
    setFileUpload({
      sectionName:'',
      upper:'',
      lower: '',
    });
  };
  // 파일 올리기
  const handleFileUpload = () => {
    const form = new FormData();
    form.append('user_id', globalContext.user.id);
    form.append('patient_id', formData.id);
    form.append('projectName', fileUpload.sectionName);
    form.append('stl', $upper.current.files[0]);
    form.append('stl', $lower.current.files[0]);

    fileAPI.upload(form).then(response => {
      if (response.success) {
        handleFileCancel();
        requestPatientInfo(globalContext.user);
      }
    });
  };

  // 파일 열기
  const handleFileOpen = (lowerProjectId) => {
    history.push(pagePaths.viewer.replace(':lowerProjectId', lowerProjectId));
  };

  // 파일 삭제 // TODO: API is not yet
  const handleFileDelete = (STLProjectId, lowerProjectId) => {
    fileAPI.delete({
      user_id: globalContext.user.id,
      stlProject_id: STLProjectId,
      lowerProject_id: lowerProjectId,
    }).then(response => {
      if (response.success) {
        // window.location.reload(); // temporary action
      }
    });
  };

  // 환자 삭제
  const handleDeletePatient = () => {
    globalContext.setModal({
      display: true,
      message: Localization.confirmDeletePatient,
      onConfirm: () => {
        patientAPI.delete({ id: formData.id }).then(response => {
          if (response.success) {
            if (onBack) onBack();
          } else {
            console.log('환자 삭제를 실패하였습니다.');
          }
        });
        globalContext.closeModal();
      },
    });
  };

  return (
    <InnerContentLayout width="520px" theme={theme}>
      {patientId ? (
        <ContentTitle>{ReactHtmlParser(Localization.patientManagementSpan)}</ContentTitle>
      ) : (
        <ContentTitle>{ReactHtmlParser(Localization.patientRegistrationSpan)}</ContentTitle>
      )}

      <Forms>
        <FormGroups>
          <FormItem focus={focus.name}>
            <Label theme="hidden" htmlFor="name">Name</Label>
            <input
              type="text" id="name" name="name" placeholder={Localization.inputName}
              maxLength="30" autoComplete="off"
              value={formData.name} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
            />
          </FormItem>
          <FormItem focus={focus.chart_id}>
            <Label theme="hidden" htmlFor="chart_id">Patient Id</Label>
            <input
              type="text" id="chart_id" name="chart_id" lang="en" placeholder="ID - 0000000"
              maxLength="30" autoComplete="off"
              value={formData.chart_id} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor="date_of_birth">Birth</Label>
            <input
              type="text" maxLength="4" name="date_of_birth_year" placeholder={Localization.year}
              value={formData.date_of_birth_year} onChange={handleChange}
            />
            <span>-</span>
            <input
              type="text" maxLength="2" name="date_of_birth_month" placeholder={Localization.month}
              value={formData.date_of_birth_month} onChange={handleChange}
            />
            <span>-</span>
            <input
              type="text" maxLength="2" name="date_of_birth_day" placeholder={Localization.day}
              value={formData.date_of_birth_day} onChange={handleChange}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor="created_at">Registration</Label>
            <DatePicker
              id="created_at"
              onChange={date => { handleDatePicker('created_at', date); }}
              value={formData.created_at}
              calendarIcon={<IconCalendar />}
              format="y-M-d"
              clearIcon={null}
              next2Label={null}
              prev2Label={null}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor="gender">Gender</Label>
            <SelectForm onChange={handleSelect} value={formData.gender} data={Object.values(selectGender)} />
          </FormItem>
          <FormItem focus={focus.hospital_name}>
            <Label htmlFor="hospital_name">Hospital</Label>
            <input
              type="text" id="hospital_name" name="hospital_name" maxLength="30" placeholder={Localization.hospital}
              value={formData.hospital_name} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
            />
          </FormItem>

          {patientId && (
            <SquareTrashButton onClick={handleDeletePatient}>{Localization.patientDelete}</SquareTrashButton>
          )}
        </FormGroups>

        {patientId && (
          <>
            <SubTitle>{ReactHtmlParser(Localization.imageHistory)}</SubTitle>

            <FileList data={fileData} onOpen={handleFileOpen} onDelete={handleFileDelete} />

            <FormButtons>
              <DefaultButton onClick={() => setFileModal(true)}><IconImport />{Localization.newImage}</DefaultButton>
            </FormButtons>

            <FormArea>
              <textarea
                rows="5" cols="50" placeholder={Localization.memo} name="remarks" id="remarks"
                onChange={handleChange} defaultValue={formData.remarks || ''}
              />
            </FormArea>
          </>
        )}

        <FormButtons>
          <FormButtonInner>
            <DefaultButton theme="gray" onClick={() => { if (onBack) onBack(); }}><IconTimes />{Localization.cancel}</DefaultButton>
            {error.display && <TextError>{error.message}</TextError>}
            {patientId ? (
              <DefaultButton onClick={handleSubmit}><IconCheck />{Localization.save}</DefaultButton>
            ) : (
              <DefaultButton onClick={handleSubmit}><IconCheck />{Localization.registration}</DefaultButton>
            )}
          </FormButtonInner>
        </FormButtons>
      </Forms>

      {showModal && (
        <FileUploadModal
          data={fileUpload} $lower={$lower} $upper={$upper}
          onChange={handleFileChange} onCancel={handleFileCancel} onSumit={handleFileUpload}
        />
      )}
    </InnerContentLayout>
  );
};

export default withRouter(ViewContainer);