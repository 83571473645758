import React from 'react';
import styled from 'styled-components/macro';

import { palette, fontEn } from 'modules/defines/styles';
import bg from 'images/log-in-bg.svg';

const LoginFormContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 527px;
  height: 244px;
  padding: 46px 50px 45px;
  background: url(${bg});
`;

const Title = styled.div`
  color: ${palette.blue};
  font-size: 20px;
  font-weight: 800;
  font-family: ${fontEn};
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const InputForm = styled.div`
  width: 100%;
`;
export const InputItem = styled.div`
  display: flex;
  height: 24px;
  & + & {
    margin-top: 18px;
  }
`;
export const Label = styled.div`
  flex-shrink: 0;
  width: 88px;
  color: ${palette.gray};
  font-family: ${fontEn};
  font-size: 15px;
  font-weight: 500;
`;
export const Input = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${palette.border};
  input {
      width: 100%;
      height: 19px;
      font-family: ${fontEn};
      background-color: transparent;
  }
`;
export const ShowButton = styled.button`
  flex-shrink: 0;
  width: 14px;
  ${props => ((props.active) ? (`
    svg path {
      fill: ${palette.blue};
    }
  `) : (`
    svg path {
      fill: ${palette.gray};
    }
  `))}
`;
export const InputButton = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  width: 94px
`;
export const FormErrorMessage = styled.div`
  height: 17px;
  margin-left: 88px;
  margin-top: 4px;
  color: ${palette.red};
  font-size: 12px;
  white-space: nowrap;
`;
export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0 0 88px;
`;
export const FooterLinks = styled.div`
  color: ${palette.gray};
  font-size: 11px;
  a + a {
    margin-left: 4px;
    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 11px;
      margin-top: -2px;
      margin-right: 4px;
      background-color: ${palette.gray};
      vertical-align: middle;
    }
  }
  a:hover {
    text-decoration: underline;
  }
`;
export const FormRemember = styled.div`
  color: ${palette.gray};
  font-size: 11px;
`;

const LoginForm = (props) => (
  <LoginFormContainer>
    <Title>Log In</Title>
    {props.children}
  </LoginFormContainer>
);
export default LoginForm;
