import React from 'react';

const Target = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#D8D8D8">
        <path d="M22.5 13.5h-1.635c-.641 3.767-3.596 6.716-7.365 7.35v1.65c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-1.637c-3.768-.64-6.717-3.594-7.35-7.363H1.5C.672 13.5 0 12.828 0 12s.672-1.5 1.5-1.5h1.65c.633-3.769 3.582-6.724 7.35-7.364V1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v1.65c3.769.634 6.724 3.583 7.365 7.35H22.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5zm-9-7.288V7.5c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5V6.212c-2.102.545-3.744 2.186-4.29 4.288H7.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5H6.21c.546 2.102 2.188 3.743 4.29 4.288V16.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v1.288c2.099-.55 3.739-2.19 4.29-4.288H16.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h1.29c-.551-2.099-2.191-3.738-4.29-4.288z" transform="translate(-386 -474) translate(386 474)" />
      </g>
    </g>
  </svg>
);

export default Target;
