import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Logo from 'components/common/Logo';
import Copyright from 'components/common/Copyright';
import ToggleButton from 'components/button/ToggleButton';
import { ThemeButtonWrapper } from 'components/layout';
import UserInfo from 'components/common/UserInfo';
import GlobalContext from 'modules/context/GlobalContext';
import { theme } from 'modules/defines/names';
import { pagePaths } from 'modules/defines/paths';
import authAPI from 'modules/api/auth';


const GlobalContainer = () => {
  const cookies = useCookies();
  const globalState = useContext(GlobalContext);
  const history = useHistory();

  // Theme 변경
  const setBodyClass = (value) => {
    if (value) {
      document.body.classList.remove(theme.dark);
      localStorage.setItem('theme', theme.light);
    } else {
      document.body.classList.add(theme.dark);
      localStorage.setItem('theme', theme.dark);
    }
  };

  // 로그아웃
  const handleLogout = () => {
    const removeCookie = cookies[2];
    authAPI.logout(cookies[0]).then(response => {
      if (response.success) {
        globalState.clear();
        removeCookie('user');
        removeCookie('image');
        if (history.location.pathname === pagePaths.download) {
          history.push(pagePaths.admin);
        }else{
          history.push(pagePaths.login);
        }
      } else {
        console.log(response);
      }
    });
  };

  useEffect(() => {
    setBodyClass(globalState.theme === theme.light);
  }, []);

  return (
    <>
      <header>
        <Logo />
        {history.location.pathname !== pagePaths.main
          && history.location.pathname !== pagePaths.viewer
          && history.location.pathname !== pagePaths.login && (
          <UserInfo onLogout={handleLogout} />
        )}
      </header>

      {/* {(history.location.pathname === pagePaths.main 
        || history.location.pathname === pagePaths.viewer) && (
        <ThemeButtonWrapper>
          <ToggleButton
            value={globalState.theme === theme.light}
            onChange={(value) => {
              setBodyClass(value);
            }}
          />
        </ThemeButtonWrapper>
      )} */}

      <Copyright>COPYRIGHT &copy; DDH ALL RIGHTS RESERVED</Copyright>
    </>
  );
};

export default GlobalContainer;
