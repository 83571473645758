import React from 'react';

const User = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <path d="M11.143 4.375C11.143 1.959 9.064 0 6.5 0 3.936 0 1.857 1.959 1.857 4.375c0 1.33.63 2.522 1.624 3.324C1.411 8.724 0 10.77 0 13.125c0 .483.416.875.929.875.512 0 .928-.392.928-.875 0-2.416 2.079-4.375 4.643-4.375 2.564 0 4.643 1.959 4.643 4.375 0 .483.416.875.928.875.513 0 .929-.392.929-.875 0-2.356-1.412-4.401-3.481-5.426.994-.802 1.624-1.994 1.624-3.324zM6.5 7C5.12 7 4 5.88 4 4.5S5.12 2 6.5 2 9 3.12 9 4.5 7.88 7 6.5 7z" transform="translate(-341 -26) translate(341 26)" />
      </g>
    </g>
  </svg>
);

export default User;
