import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';

import Hud from 'components/viewer/Hud';
import { palette } from 'modules/defines/styles';

const HudLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10px;
  left: calc(10% - 450px);
  width: 900px;
  height: 60px;
  padding-left: 400px;
  z-index: 100;
`;

const HudContainer = () => {
    return (
        <HudLayout>
            <Hud />
        </HudLayout>
    );
};

export default HudContainer;
