import React from 'react';

const SideTeethViewBaseOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23" className={props.className}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M12 0L19.926 4.555 24 17.259 17.341 23 6.659 23 0 17.259 4.074 4.555z" transform="translate(-305 -708) translate(305.5 708)" />
      <path stroke="#979797" strokeLinecap="round" strokeWidth="2" d="M6 16c4.088-12 8.088-12 12 0" transform="translate(-305 -708) translate(305.5 708)" />
    </g>
  </svg>
);

export default SideTeethViewBaseOff;