import LocalizedStrings from 'react-localization';

import en from './en.json';
import ko from './ko.json';
import zhCN from './zh_CN.json';
import zhTW from './zh_TW.json';

const locals = {
  'en':en,
  'ko':ko,
  'zh_CN':zhCN,
  'zh_TW':zhTW };

const Localization = new LocalizedStrings(locals);

export default Localization;