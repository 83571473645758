import React from 'react';
import styled from 'styled-components/macro';

import { palette, fontkoKR } from 'modules/defines/styles';
import Localization from 'i18n';

const Button = styled.button`
  width: 64px;
  height: 67px;
  background-color: ${palette.blue};
  border: 1px solid ${palette.blue};
  border-radius: 5px;
  color: ${palette.white};
  font-family: ${fontkoKR};
  font-size: 11px;
  font-weight: 700;

  &:hover {
    background-color: ${palette.bg};
    color: ${palette.blue};
  }

  &:disabled {
    border-color: ${palette.bg};
    background-color: ${palette.bg};
    color: ${palette.disabled};
  }
`;

const LoginButton = (props) => (
  <Button type="button" {...props}>{Localization.login}</Button>
);

export default LoginButton;