import React from 'react';
import styled from 'styled-components/macro';

import IconTrash from 'components/icons/Trash';
import { palette } from 'modules/defines/styles';

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: ${palette.black};
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;

  svg {
    margin-bottom: 5px;
  }
  &:hover {
    color: ${palette.blue};

    svg g {
      fill: ${palette.blue};
    }
  }
`;

const SquareTrashButton = (props) => (
  <Button type="button" className="btn-delete" {...props}><IconTrash />{props.children}</Button>
);

export default SquareTrashButton;