import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Admin, Login, Main, Patient } from './pages';
import { pagePaths } from './modules/defines/paths';
import Viewer from './pages/Viewer';
import Upload from './pages/Upload';
import Download from './pages/Download';

const Routes = () => (
  <Switch>
    <Route path={pagePaths.login} component={Login} exact />
    <Route path={pagePaths.admin} component={Admin} exact />
    <Route path={pagePaths.main} component={Main} />
    <Route path={pagePaths.patientList} component={Patient} exact />
    <Route path={pagePaths.patientRegi} component={Patient} exact />
    <Route path={pagePaths.patientDetail} component={Patient} exact />
    <Route path={pagePaths.viewer} component={Viewer} />{/* temporary */}
    <Route path={pagePaths.upload} component={Upload} />
    <Route path={pagePaths.download} component={Download} />
    <Route component={() => <Redirect to={pagePaths.login} />} />
  </Switch>
);

export default Routes;
