import React from 'react';

const ShapeEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <path id="i5z2dnzf2a" d="M0 16L16 16 16 0 0 0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-867 -24) translate(867 24)">
        <path fill="#FFF" d="M1 12H0C0 5.383 5.383 0 12 0v1C5.934 1 1 5.934 1 12" />
        <mask id="kemwoqmuib" fill="#fff">
          <use xlinkHref="#i5z2dnzf2a" />
        </mask>
        <path fill="#FFF" d="M11 1L15 1 15 0 11 0zM0 15L1 15 1 11 0 11zM15.336 16L9 9.664 9.664 9 16 15.336zM6.781 6.781l.876 2.337 1.46-1.46-2.336-.877zM7.25 11L5 5l6 2.25L7.25 11zM2 15L3 15 3 11 2 11zM11 3L15 3 15 2 11 2z" mask="url(#kemwoqmuib)" />
      </g>
    </g>
  </svg>
);

export default ShapeEdit;
