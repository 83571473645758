import React from 'react';

const SideViewOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" className={props.className}>
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M12 6c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm0 3c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3zm0-9c.828 0 1.5.672 1.5 1.5V3c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5V1.5c0-.828.672-1.5 1.5-1.5zm0 19.5c.828 0 1.5.672 1.5 1.5v1.5c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5V21c0-.828.672-1.5 1.5-1.5zm-10.5-9H3c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5H1.5C.672 13.5 0 12.828 0 12s.672-1.5 1.5-1.5zm19.5 0h1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5H21c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zM3.514 3.51c.586-.586 1.536-.586 2.122 0l1.06 1.065c.414.371.589.94.454 1.48-.135.54-.556.96-1.096 1.093s-1.11-.043-1.479-.458l-1.06-1.05c-.585-.59-.585-1.54 0-2.13zm13.79 13.8c.586-.586 1.535-.586 2.121 0l1.06 1.05c.589.586.59 1.537.005 2.125-.586.589-1.537.59-2.125.005l-1.061-1.065c-.282-.28-.44-.66-.44-1.058 0-.397.158-.777.44-1.057zm-13.79 1.05l1.061-1.05c.37-.415.939-.591 1.479-.458.54.133.961.554 1.096 1.093.135.54-.04 1.109-.454 1.48l-1.06 1.065c-.589.586-1.54.584-2.126-.005-.586-.588-.584-1.54.005-2.125zm13.79-13.785l1.06-1.065c.381-.379.935-.526 1.453-.386s.923.546 1.06 1.065c.138.519-.011 1.072-.392 1.451l-1.06 1.05c-.37.415-.939.591-1.479.458-.54-.133-.961-.554-1.096-1.093-.135-.54.04-1.109.454-1.48z" transform="translate(-38 -359) translate(38.5 359)" />
      </g>
    </g>
  </svg>
);

export default SideViewOff;