import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useCookies } from 'react-cookie';

import Overview3DCanvas from 'components/viewer/3d/Overview3DCanvas';
import GlobalContext from 'modules/context/GlobalContext';
import ViewerContext from 'modules/context/ViewerContext';

import { pagePaths } from 'modules/defines/paths';
import { fontkoKR, palette } from 'modules/defines/styles';

const OverviewLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 560px;
  left: 20px;
  width: 300px;
  height: 300px;
  // padding: 11px 14px 16px;
  // border-radius: 20px;
  // box-shadow: 0 10px 30px 0 rgba(19, 23, 31, 0.2);
  // background-color: ${palette.white};
  // z-index: 100;
`;

const OverviewContainer = (props) => {
    const { history, lowerProjectId } = props;
    const [cookies] = useCookies();
    const globalContext = useContext(GlobalContext);
    const viewerContext = useContext(ViewerContext);

    useEffect(() => {
        if (!cookies.user) {
            history.push(pagePaths.login);
            return;
        }

        // 새로고침 시 context 데이터 초기화 되어 데이터 다시 세팅
        let user = globalContext.user;
        if (!user.id) {
            user = cookies.user;
            globalContext.set(prev => ({ ...prev, user }));
        }

    }, []);


    return (
        <OverviewLayout>
            <Overview3DCanvas />
        </OverviewLayout>
    );
};

export default OverviewContainer;
