import React from 'react';

const Folder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <g fill={props.color || '#3C3C3B'}>
        <path d="M1802.259 1012.625l-4.03-.009-1.336-1.336c-.27-.253-.623-.397-.993-.405h-4.157c-.964.004-1.742.786-1.743 1.75v8.75c.004.967.789 1.75 1.756 1.75h10.488c.465.002.912-.181 1.242-.51.33-.328.515-.775.514-1.24v-7c0-.963-.778-1.745-1.741-1.75zm-.406 7.453c.001.297-.116.582-.326.793-.21.21-.494.329-.791.329h-7.472c-.617 0-1.116-.5-1.117-1.117v-2.944c0-.617.5-1.116 1.117-1.117h7.472c.617 0 1.116.5 1.117 1.117v2.939z" transform="translate(-1790 -1010)" />
      </g>
    </g>
  </svg>
);

export default Folder;