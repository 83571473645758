import React from 'react';
import styled from 'styled-components/macro';
import { fontkoKR, palette } from 'modules/defines/styles';

const Button = styled.button`
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => (props.theme === 'icon' ? '29px' : '61px')};
  height: ${props => (props.theme === 'icon' ? '17px' : '25px')};
  ${props => (props.theme !== 'icon' && 'padding: 0 15px 2px')};
  border-radius: 17px;
  border: 1px solid ${props => props.color};
  background-color: ${props => props.color};
  font-family: ${fontkoKR};
  font-size: 11px;
  font-weight: 500;
  color: ${palette.white};
  white-space: nowrap;

  svg {
    ${props => (props.theme === 'icon' ? (
    `path { fill: ${palette.white}; }`
  ) : (
    'margin: 0 7px 0 0;'
  ))}
  }

  &:disabled {
    border-color: ${palette.bg};
    background-color: ${palette.bg};
    color: ${palette.disabled};
    svg path {
      fill: ${palette.disabled};
    }
  }

  &:hover:not(:disabled) {
    color: ${props => props.color};
    background-color: ${palette.bg};
    svg path {
      fill: ${props => props.color};
    }
  }
`;
/*
 * @theme : none | 'gray' | 'red'
 */
const DefaultButton = (props) => {
  const { theme } = props;
  let color = palette.blue;
  if (theme === 'gray') color = palette.disabled;
  else if (theme === 'red') color = palette.red;

  return (
    <Button type="button" {...props} color={color}>
      {props.children}
    </Button>
  );
};

export default DefaultButton;
