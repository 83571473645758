import React from 'react';

const SideTeethTopOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-793 -528) translate(793 528) translate(0 4) translate(6)">
        <path fill={props.color || '#fff'} d="M7.86 1.885l-.738 1.072c-.45.656-.69 1.423-.69 2.218v1.38c0 .192.155.348.345.348h3.03c.19 0 .345-.156.345-.347V2.599c0-.693-.564-1.258-1.257-1.258-.413 0-.8.204-1.035.544M9.807 7.88h-3.03c-.729 0-1.324-.595-1.324-1.325V5.175c0-.994.3-1.953.863-2.772l.738-1.072C7.471.725 8.16.363 8.895.363c1.233 0 2.236 1.002 2.236 2.236v3.957c0 .73-.594 1.325-1.324 1.325" />
        <path fill={props.color || '#fff'} d="M5.108 7.881H2.08c-.73 0-1.324-.595-1.324-1.325V2.599C.755 1.365 1.758.362 2.99.362c.736 0 1.425.363 1.842.969l.738 1.071c.402.584.734 1.565.854 2.233.048.266-.129.52-.395.568-.27.043-.52-.129-.568-.394-.1-.559-.389-1.404-.698-1.852l-.737-1.072c-.235-.34-.622-.544-1.036-.544-.693 0-1.256.564-1.256 1.258v3.957c0 .191.155.346.345.346h3.03c.19 0 .344-.155.344-.346 0-.271.22-.489.49-.489s.49.218.49.489c0 .73-.595 1.325-1.325 1.325M13.193 7.012h-2.551c-.271 0-.49-.22-.49-.49s.219-.49.49-.49h2.55c.16 0 .293-.13.293-.291V3.355c0-.44-.186-.826-.521-1.092-.347-.273-.801-.38-1.213-.28-.261.068-.527-.1-.59-.362-.062-.262.1-.526.363-.59.7-.167 1.466.005 2.046.464.576.455.893 1.115.893 1.86V5.74c0 .702-.57 1.27-1.27 1.27" />
        <path fill={props.color || '#fff'} d="M16.57 6.122h-2.55c-.27 0-.49-.219-.49-.489s.22-.49.49-.49h2.55c.16 0 .292-.13.292-.292V2.465c0-.582-.322-1.065-.86-1.292-.41-.173-1.013-.198-1.477.255-.195.19-.504.183-.693-.009-.188-.194-.184-.504.01-.693.676-.658 1.651-.832 2.541-.455.9.38 1.458 1.22 1.458 2.194v2.386c0 .701-.57 1.271-1.27 1.271" />
        <path fill={props.color || '#fff'} d="M16.842 1.34c-.023 0-.046-.001-.07-.004-.267-.038-.453-.285-.415-.553.079-.558.162-1.134.066-1.662-.073-.398-.301-.891-.724-1.085-.417-.19-.95-.08-1.553.074L8.296-.418c-1.836.465-2.33.588-4.66 0l-5.848-1.471c-.603-.154-1.135-.265-1.554-.075-.423.194-.651.687-.724 1.085-.095.528-.013 1.104.066 1.662.039.268-.148.515-.415.553-.271.033-.515-.147-.554-.415-.086-.605-.184-1.292-.06-1.976.15-.831.63-1.503 1.282-1.8.763-.347 1.592-.138 2.198.015l5.848 1.474c2.092.526 2.41.445 4.177 0l5.854-1.474c.605-.151 1.435-.361 2.198-.014.652.296 1.13.968 1.282 1.799.124.684.026 1.37-.06 1.976-.035.244-.244.42-.484.42" mask="url(#lix40dnbhd)" />
      </g>
      <path fill={props.color || '#fff'} d="M3.821 6.122h-2.55c-.7 0-1.27-.57-1.27-1.27V2.464C0 1.536.52.713 1.36.315c.835-.4 1.788-.295 2.484.275.21.171.241.48.07.69-.17.207-.479.238-.689.07-.399-.329-.952-.384-1.447-.15-.5.237-.8.71-.8 1.265v2.386c0 .161.131.292.291.292h2.551c.271 0 .49.22.49.49s-.219.49-.49.49" transform="translate(-793 -528) translate(793 528) translate(0 4)" />
      <path fill={props.color || '#fff'} d="M7.199 7.012h-2.55c-.701 0-1.271-.57-1.271-1.271V3.355c0-.732.307-1.385.866-1.84.563-.456 1.314-.643 2.01-.498.265.056.435.315.38.58-.055.264-.318.437-.58.378-.413-.09-.856.027-1.192.302-.326.264-.505.646-.505 1.078V5.74c0 .162.13.292.292.292h2.55c.27 0 .49.219.49.49 0 .27-.22.489-.49.489" transform="translate(-793 -528) translate(793 528) translate(0 4)" />
    </g>
  </svg>

);

export default SideTeethTopOff;