import Login from './Login';
import Admin from './Admin';
import Main from './Main';
import Patient from './Patient';
import Upload from './Upload';
import Download from './Download';

export {
  Login,
  Admin,
  Main,
  Patient,
  Upload,
  Download,
};
