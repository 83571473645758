import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import ViewerLayout from 'components/layout/ViewerLayout';
import ViewerCtrlGroup, { ViewerCtrlButton } from 'components/viewer/ViewerCtrlGroup';
import Main3DCanvas from 'components/viewer/3d/Main3DCanvas';
import GlobalContext from 'modules/context/GlobalContext';
import ViewerContext, { viewerStateInit } from 'modules/context/ViewerContext';
import projectAPI from 'modules/api/project';
import fileAPI from 'modules/api/file';
import { pagePaths } from 'modules/defines/paths';
import * as THREE from 'three';
import { Vector3, Quaternion, Plane } from 'three';
import patientAPI from 'modules/api/patient';
import ModalPopup from 'components/modal/ModalPopup';

const _ = require('lodash');

const ViewerContainer = (props) => {
    const { history, lowerProjectId } = props;
    const [cookies] = useCookies();
    const globalContext = useContext(GlobalContext);
    const viewerContext = useContext(ViewerContext);
    const [error, setError] = useState(false);
    const loadData = {
        image_id: '',
    };

    const handleConfirm = () => {
        setError(false);
        window.location.reload();
      };

    useEffect(() => {
        if (!cookies.user) {
            history.push(pagePaths.login);
            return;
        }

        // 새로고침 시 context 데이터 초기화 되어 데이터 다시 세팅
        let user = globalContext.user;
        if (!user) {
            user = cookies.user;
            globalContext.set(prev => ({ ...prev, user }));
        }

        let patient = globalContext.patientInfo.patient_id;
        if (!patient) {
            patient = cookies.patient;
            globalContext.set(prev => ({ ...prev, patient }));
        }


        let images = globalContext.patientInfo.image;
        if (images.length === 0) {
            images = cookies.image;
            globalContext.set(prev => ({ ...prev, images }));
        }
            
        const networkCheck = window.navigator.onLine;
        if (networkCheck !== true) {
            setError(true);
            return;
        }

                
        if (images) {
            
            const promiseList = new Array(2);
            images.forEach((image, index) => {
                fileAPI.getSTL(image.path).then(geom => {
                    const meshUpdate = (image.image_position === 'u') ? { upperGeometry: geom, upperImage_id: image._id } : { lowerGeometry: geom, lowerImage_id: image._id };
                    viewerContext.update(meshUpdate);
                });

                if (viewerContext.meshGroup) {
                    // get labeling data
                    if ( image._id && image.image_position === 'u') {
                        loadData.image_id = image._id;
                        promiseList[0] = (fileAPI.load(loadData));
                    } else {
                        loadData.image_id = image._id;
                        promiseList[1] = (fileAPI.load(loadData));
                    }

                    let updateSet = _.clone(viewerStateInit);

                    const upperLabels = {};
                    const lowerLabels = {};
                    let upperInfoData = [];
                    let lowerInfoData = [];

                    const parseObjectProperties = (obj, buildObj) => {
                        Object.keys(obj).map((k) => {
                            if (typeof obj[k] === 'object' && obj[k] !== null && obj[k] !== undefined && Object.keys(obj[k]).length !== 0) {
                                // console.log('name:', k);
                                buildObj[k] = {};
                                if ( 'isVector3' in obj[k] ) {
                                    // console.log('vector3', obj[k]);
                                    buildObj[k] = new Vector3(obj[k].x, obj[k].y, obj[k].z);
                                } else if ('isQuaternion' in obj[k]) {
                                    buildObj[k] = new Quaternion(obj[k]._x, obj[k]._y, obj[k]._z, obj[k]._w);
                                } else if ('isPlane' in obj[k]) {
                                    const planeVec = new Vector3(obj[k].normal.x, obj[k].normal.y, obj[k].normal.z);
                                    buildObj[k] = new Plane(planeVec, obj[k].constant);
                                    // console.log(k, obj[k]);
                                } else {
                                    parseObjectProperties(obj[k], buildObj[k]);
                                }
                            } else {
                                buildObj[k] = obj[k];
                            }
                        });
                    };

                    if ( promiseList[0] !== undefined && promiseList[1] !== undefined) {
                        Promise.all(promiseList).then((values) => {
                            if (values[0].success !== true || values[1].success !== true) {
                                throw new Error('Something bad happened.');
                            } else {
                                const upperInfo = values[0].data;
                                const lowerInfo = values[1].data;

                                if (upperInfo.length > 0) {
                                    upperInfoData = upperInfo;
                                    upperInfo.forEach((upper) => {
                                        upperLabels[upper.categories] = {};
                                        parseObjectProperties(upper.annotation, upperLabels[upper.categories]);
                                    });
                                }
                                
                                if (lowerInfo.length > 0) {
                                    lowerInfoData = lowerInfo;
                                    lowerInfo.forEach((lower) => {
                                        lowerLabels[lower.categories] = {};
                                        parseObjectProperties(lower.annotation, lowerLabels[lower.categories]);
                                    });
                                }
                            }
                            updateSet.upperDataInfo = upperInfoData;
                            updateSet.lowerDataInfo = lowerInfoData;
                            updateSet.labels.upper = upperLabels;
                            updateSet.labels.lower = lowerLabels;
                            updateSet = viewerContext.getStateUpdateForTooth(viewerContext.toothNumber, viewerContext.display.teeth, updateSet);
                            viewerContext.update(updateSet);
                        });
                    }
                }
            });

            

            
        } else {
            viewerContext.clear();
        }

        // let checkData = true;
        // let noneData = true;
        // if (images.image_id === dataImages) {

        // }

        // STL 파일 요청
        // retrieve mesh files & install in viewerContext 

        // if (images === undefined || images == null)
        //     viewerContext.clear();
            
        // else {
        //     // retrieve mesh files & install in viewerContext
        //     images.forEach(image => {
        //         // const reqParam = {
        //         //     path: image.path,
        //         // }
        //         fileAPI.getSTL(image.path).then(geom => {
        //             const meshUpdate = (image.image_position === 'u') ? { upperGeometry: geom } : { lowerGeometry: geom };
        //             viewerContext.update(meshUpdate);
        //         });
        //     });
        // }
        // STL 파일 요청 }
     }, [viewerContext.meshGroup]);

     const nextOrientation = () => {
            // step through canonical display orientations
            const nextOrientation = {
                front: 'left',
                top: 'bottom',
                bottom: 'front',
                left: 'right',
                right: 'back',
                back: 'top',
            }[viewerContext.display.orient];
            viewerContext.updateDisplay({ orient: nextOrientation });
            // and set the new orientation explicitly
            // viewerContext.setOrientation(nextOrientation);
            viewerContext.setCameraOrientation(nextOrientation);
        };

    return (
        <>
            <ViewerLayout>
                <Main3DCanvas />
            </ViewerLayout>
            <ViewerCtrlGroup>
                {/*<ViewerCtrlButton icon="arrow" theme={globalContext.theme} />*/}
                <ViewerCtrlButton icon="target" onClick={nextOrientation} theme={globalContext.theme} />
                {/*<ViewerCtrlButton icon="play" theme={globalContext.theme} />*/}
            </ViewerCtrlGroup>
            {error && <ModalPopup onConfirm={handleConfirm}> 네트워크 연결 끊김 연결 후 확인을 눌러주세요 </ModalPopup>}

        </>
    );
};

export default withRouter(ViewerContainer);
