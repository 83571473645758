import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { palette } from 'modules/defines/styles';
import IconCheck from 'components/icons/Check';

const CheckboxWrapper = styled.div`
  cursor: pointer;
  white-space: nowrap;
  color: ${palette.gray};
  font-size: 11px;
`;
const Box = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: -2px;
  border: ${props => (props.checked ? '0' : `1px solid ${palette.border}`)};
  background-color: ${props => (props.checked ? palette.blue : palette.white)};
  border-radius: 5px;
  vertical-align: middle;
`;

const Checkbox = (props) => {
  const [checked, setChecked] = useState(props.checked || false);
  const handleClick = () => {
    setChecked(!checked);
    if (props.onChange) props.onChange(!checked);
  };

  return (
    <CheckboxWrapper onClick={handleClick}>
      <Box checked={checked}>{checked && <IconCheck width="9" height="7" />}</Box>
      {props.children}
    </CheckboxWrapper>
  );
};

export default Checkbox;