import React from 'react';
import styled from 'styled-components/macro';

const ListHeadertWrapper = styled.div`
  display: inline-block;
  float: right;
  justify-content: space-between;
  height: 34px;
  margin-bottom: 18px;
`;

const ListHeader = (props) => (
  <ListHeadertWrapper>{props.children}</ListHeadertWrapper>
);

export default ListHeader;

export const ListHeaderRight = styled.div`
  display: flex;
  & > button {
    margin-right: 14px;
  }
`;