export const initCharRegex = {
  'ㄱ': '^[가-깋][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㄴ': '^[나-닣][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㄷ': '^[다-딯][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㄹ': '^[라-맇][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅁ': '^[마-밓][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅂ': '^[바-빟][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅅ': '^[사-싷][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅇ': '^[아-잏][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅈ': '^[자-짛][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅊ': '^[차-칳][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅋ': '^[카-킿][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅌ': '^[타-팋][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅍ': '^[파-핗][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  'ㅎ': '^[하-힣][가-힣]*[0-9]*[A-Z]*\\s?[가-힣]*[0-9]*[A-Z]*',
  '기타': /^[a-zA-Z0-9]+$/,
};

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;