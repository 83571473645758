import * as React from 'react';

const Checkgreen = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.83052C4.38505 1.83052 1.45455 4.76102 1.45455 8.37598C1.45455 11.9909 4.38505 14.9214 8 14.9214C11.615 14.9214 14.5455 11.9909 14.5455 8.37598C14.5455 4.76102 11.615 1.83052 8 1.83052ZM0 8.37598C0 3.9577 3.58172 0.375977 8 0.375977C12.4183 0.375977 16 3.9577 16 8.37598C16 12.7943 12.4183 16.376 8 16.376C3.58172 16.376 0 12.7943 0 8.37598Z"
      fill="#48B16E"
    />
    <path
      d="M12.28 4.37598L5.88 12.376"
      stroke="#48B16E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7.25586L5.88 12.3759"
      stroke="#48B16E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkgreen;
