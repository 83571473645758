import React from 'react';

const SideShapeOff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" className={props.className}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M21.35 17.733C19.622 19.432 13.64 20 12 20c-1.64 0-7.45-.427-9.35-2.267C.751 15.892.588 10.782.16 8.572c-.436-2.259.031-4.526 1.251-6.065C2.171 1.55 3.138 1 4.064 1c2.383 0 3.691.555 4.845 1.045.356.151.704.299 1.068.425.192.08.389.161.59.246 2.051.86 4.603 1.932 6.724 1.932.283 0 .514-.238.514-.532 0-.294-.23-.532-.514-.532-1.203 0-2.613-.403-3.983-.908.64-.147 1.202-.384 1.783-.631C16.244 1.555 17.552 1 19.935 1c.927 0 1.895.55 2.654 1.507 1.22 1.54 1.687 3.806 1.25 6.065-.427 2.21-.764 7.462-2.49 9.16z" transform="translate(-38 -255) translate(38.5 255)" />
      <g stroke="#D8D8D8" strokeLinecap="round" strokeLinejoin="round">
        <path d="M0 6.382L6.246 8.45 13.172 9 20.718 7.198 24 5" transform="translate(-38 -255) translate(38.5 255)" />
        <path d="M1 12.948L6.049 9.077 8.824 14 13.179 9.532 17.779 13.789 20.527 8 24 12.948" transform="translate(-38 -255) translate(38.5 255)" />
        <path d="M1 12.585L8.834 14 18.136 14 23 12M3 17.652L9.065 14.431 14.007 20 17.955 14 20 18.933M5 .706L6.57 7.58 11.326 1.584 13.404 9 17.933 7.813 21 6.931 19.734 0" transform="translate(-38 -255) translate(38.5 255)" />
      </g>
    </g>
  </svg>
);

export default SideShapeOff;