import React from 'react';

const ArrowBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L16 0 16 16 0 16z" transform="translate(-1524 -1010) translate(1524 1010)" />
      <path fill="#BFBFBF" fillRule="nonzero" d="M4.471 10.471c-.26.26-.682.26-.942 0-.26-.26-.26-.682 0-.942l4-4c.252-.253.658-.262.921-.02l4 3.666c.272.249.29.67.041.942-.248.272-.67.29-.941.041L8.02 6.923 4.471 10.47z" transform="translate(-1524 -1010) translate(1524 1010) matrix(0 1 1 0 0 0)" />
    </g>
  </svg>
);

export default ArrowBack;
