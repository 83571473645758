import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import ViewerContext from 'modules/context/ViewerContext';

const HudWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    font-size: 16px;
    color: black;
    opacity: 0.5;
    transform: scale(0.7);
`;

const Tooth = styled.div`
    margin-right: 12px;
    span {
        font-size: 35px;
        margin: 0 6px 0 6px;
    }
`;
const Step = styled.div`
    span {
        font-size: 40px;
        margin: 0 6px 0 6px;
    }
`;


const Hud = () => {
    const viewerContext = useContext(ViewerContext);
    return (
        <HudWrapper>
            <Tooth>Tooth<span>{viewerContext.toothNumber}</span></Tooth>
            <Step>Step<span>{viewerContext.labelingSteps[viewerContext.labelingStep].title}</span></Step>
        </HudWrapper>
    );
};

export default Hud;
