import { createGlobalStyle } from 'styled-components/macro';
import { theme } from 'modules/defines/names';

import Arrow from 'components/icons/Arrow.svg';

export const fontkoKR = 'Noto Sans KR';
export const fontEn = 'Montserrat';

export const palette = {
  white: '#fff',
  black: '#3C3C3B',
  blue: '#008fd5',
  red: '#d95e2d',
  gray: '#8b8b8b',
  lightgray: '#C4C4C4',
  bg: '#f8f8f8',
  disabled: '#bfbfbf',
  border: '#979797',
  form: '#ededed',
};
export const size = {
  width: '1200px',
  height: '800px',
  header: '50px',
};

export const zIndex = {
  floatingMenu: 2,
  calendar: 100,
  modal: 200,
};

const GlobalStyling = createGlobalStyle`
  html {
    height: 100%;
  }
  
  body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fcfcfc;
    color: ${palette.black};
    font-weight: 400;
    font-family: ${fontkoKR};
    font-size: 12px;

    &.${theme.dark} {
      background-color: #3f3f3f;
    }
  }

  button {
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    color: inherit;

    svg {
      pointer-events: none;
    }
    &:disabled {
      cursor: default;
    }
  }
  
  input[type='email'],
  input[type='text'],
  input[type='password'] {
    border: 0;
    outline: 0;
    padding: 0;
    border-radius: 0;
    color: ${palette.black};
    font-size: 12px;
  }

  ::placeholder {
    color: ${palette.gray};
  }

  input[type='email'] {
    font-family: ${fontEn};
  }
  textarea {
    resize: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    color: ${palette.black};
    font-family: ${fontkoKR};
    font-size: 12px;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 400;
  }
  
  * {
    box-sizing: border-box;
  }
  
  *:focus {
    outline: 0;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    padding: 0;
    margin: 0;
  }

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  dl, dt, dd {
    padding: 0;
    margin: 0;
  }

  em, i {
    font-style: normal;
  }

  // remove autofill background-color
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  [lang='ko'] {
    font-family: ${fontkoKR};
  }

  [lang='en'] {
    font-family: ${fontEn};
  }

  // react-custom-scroll
  .rcs-custom-scroll .rcs-custom-scrollbar {
    opacity: 1;
    right: 10px;
    width: 5px;
  }
  .rcs-custom-scroll .rcs-inner-handle {
    background-color: #7a7a7a;
    border-radius: 4px;
  }

  // react-data-picker
  .react-date-picker__wrapper {
    border: 0 !important;
  }
  .react-date-picker__inputGroup__input {
    min-width: 16px;
    color: ${palette.black};
    font-family: ${fontEn};
    font-size: 14px;
    text-align: center;

    &:first-child {
      min-width: 30px;
    }
  }
  .react-date-picker__inputGroup__divider {
    margin: 0 2px;
  }
  .react-date-picker__button {
    margin-left: 4px;
    &:hover {
      svg path {
        fill: ${palette.blue};
      }
    }
  }

  .react-date-picker__calendar{
    width: 184px;
    z-index: ${zIndex.calendar};
  }

  .react-calendar {
    padding: 16px 12px 12px 13px;
    border-radius: 15px;
    box-shadow: 0 10px 30px 0 rgba(19, 23, 31, 0.2);
    background-color: #f3f3f3;
    border: 0;
  }
  .react-calendar__navigation {
    height: 20px;
    margin: 0 0 10px;
  }
  .react-calendar__navigation .react-calendar__navigation__label {
    // min-width: 0;
    // width: 20px;
    height: 20px;
    color: rgba(57,57,57,.8);
    font-size: 9px;
    font-family: ${fontEn};
  }
  .react-calendar__navigation__arrow {
    position: relative;
    min-width: 20px !important;
    width: 20px;
    height: 20px;
    text-indent: -100px;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: url(${Arrow}) no-repeat 50% 50% !important;
    }
    &.react-calendar__navigation__next-button {
      &:before {
        transform: rotate(-180deg);
      }
    }
  }

  .react-date-picker__inputGroup__input:invalid {
    background: none;
  }
  .react-calendar button {
    padding: 0;
  }
  .react-calendar abbr {
    text-decoration: none;
  }
  .react-calendar__tile {
    width: 18px !important;
    height: 18px !important;
    padding: 0;
    font-size: 9px;
    color: rgba(57,57,57,.8);
    font-family: ${fontEn};
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    max-width: 30% !important;
    height: auto !important;
    padding: 1em 1px;
    box-sizing: content-box;
  }
  .react-calendar__month-view__days {
    
  }
  .react-calendar__month-view__weekdays__weekday {
    height: 18px;
    padding: 0;
  }
  .react-calendar__month-view__weekdays {
    justify-content: space-between;
    padding: 0;
    color: rgba(57,57,57,.8);
    font-weight: 300;
    font-family: ${fontEn};
    text-transform: capitalize;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    padding: 0;
    color: rgba(57,57,57,0.5);
    font-size: 9px;
    font-family: ${fontEn};
  }
  .react-calendar__tile--now:enabled:hover {
    font-weight: 700;
    background: #e6e6e6;
  }
  .react-calendar__tile--now:enabled:focus,
  .react-calendar__tile--now {
    font-weight: 700;
    background: none;
  }
  .react-calendar__tile--hasActive,
  .react-calendar__tile--active {
    border-radius: 4px;
    background-color: ${palette.blue};
    color: #fff;
    box-sizing: border-box !important;
  }
}`;

export default GlobalStyling;
