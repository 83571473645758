/* eslint-disable no-plusplus */
/*
 * Usage
 * Strings(`전체 환자 {0}명`,"12")
 * return
 *`전체 환자 12명`
 * command
 * 0부터 n개의 인자 사용 가능
 * Strings(`${0} / ${1} / ${2}`, '0번', '1번', '2번')
 */
const Strings = (...arg) => {
  if (arg.length < 2) return arg[0];

  let result = arg[0];
  for (let i = 1; i <= arg.length; i++) {
    result = result.replace(`{${i - 1}}`, arg[i]);
  }
  return result;
};

export { Strings };