import React from 'react';
import ContentsLayout from 'components/layout/ContentsLayout';
import UploadContainer from 'containers/patient/UploadContainer';

const Upload = () => {
  return (
    <ContentsLayout>
      <UploadContainer />
    </ContentsLayout>
  );
};

export default Upload;