import React from 'react';

const ArrowAlt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
    <g fill="none" fillRule="evenodd">
      <g fill="#D8D8D8">
        <path d="M7.093 9.436c.284.28.444.662.444 1.06 0 .399-.16.78-.444 1.06-.597.587-1.553.587-2.15 0L.666 7.588C.24 7.168 0 6.596 0 5.998c0-.598.24-1.17.666-1.59L4.943.44c.597-.587 1.553-.587 2.15 0 .284.28.444.661.444 1.06 0 .398-.16.78-.444 1.06L4.874 4.496h14.252L16.907 2.56c-.284-.28-.444-.662-.444-1.06 0-.399.16-.78.444-1.06.597-.587 1.553-.587 2.15 0l4.277 3.968c.426.42.666.992.666 1.59 0 .598-.24 1.17-.666 1.59l-4.288 3.967c-.596.586-1.553.586-2.15 0-.284-.28-.444-.662-.444-1.06 0-.399.16-.78.445-1.06l2.221-1.941H4.858l2.235 1.942z" transform="translate(-337 -480) translate(337 480)" />
      </g>
    </g>
  </svg>
);

export default ArrowAlt;
