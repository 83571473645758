import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import ContentsLayout from 'components/layout/ContentsLayout';
import GlobalContainer from 'containers/common/GlobalContainer';
import StepPanel, { ContentLabel } from 'components/common/StepPanel';
import ListContainer from 'containers/patient/ListContainer';
import ViewContainer from 'containers/patient/ViewContainer';
import { pagePaths } from 'modules/defines/paths';
import { theme } from 'modules/defines/names';
import Localization from 'i18n';

const Patient = (props) => {
  const { history } = props;
  const [cookies] = useCookies();

  const listPage = props.match.path === pagePaths.patientList;
  const regiPage = props.match.path === pagePaths.patientRegi;
  const detailPage = props.match.path === pagePaths.patientDetail;

  const handleBack = () => {
    if (listPage) return;
    history.push(pagePaths.patientList.replace(':userId', cookies.user.id));
  };

  useEffect(() => {
    document.body.id = 'patient-page';
    document.body.classList.remove(theme.dark);
  }, []);

  return (
    <ContentsLayout>
      <GlobalContainer />

      <StepPanel step="1" title={Localization.patientManagement} back={!listPage && Localization.patientSelection} onBack={handleBack}>
        <ContentLabel>
          {listPage && Localization.existingPatient}
          {regiPage && Localization.patientRegistration}
          {detailPage && Localization.patientManagement}
        </ContentLabel>
      </StepPanel>

      {/* 환자 목록 */}
      {listPage && (<ListContainer />)}

      {/* 환자 등록 */}
      {regiPage && (<ViewContainer onBack={handleBack} />)}

      {/* 환자 상세 */}
      {detailPage && (<ViewContainer patientId={props.match.params.patientId} onBack={handleBack} />)}

    </ContentsLayout>
  );
};

export default Patient;
