import React from 'react';

const Trash = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '18'} height={props.height || '19'} viewBox="0 0 18 19">
    <g fill="none" fillRule="evenodd">
      <g fill={props.color || '#444A58'} fillRule="nonzero">
        <path d="M17.178 1.29h-4.824v-.267C12.354.155 11.731 0 10.969 0H7.006c-.76 0-1.379.155-1.379 1.023v.267H.822c-.218 0-.427.098-.582.276-.154.177-.24.417-.24.667v1.823C0 4.577.368 5 .822 5h16.356c.454 0 .822-.423.822-.944V2.233c0-.52-.368-.943-.822-.943zM16.096 7H1.898c-.259.002-.504.101-.674.273-.17.171-.249.399-.217.625l1.456 9.727c.112.782.863 1.37 1.756 1.375h9.556c.895-.002 1.65-.59 1.763-1.375l1.455-9.727c.032-.227-.047-.456-.219-.628-.171-.171-.418-.27-.678-.27z" transform="translate(-883 -146) translate(883 146)" />
      </g>
    </g>
  </svg>
);

export default Trash;
