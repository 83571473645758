import React from 'react';

const SideTeethViewOutlineOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="27" height="19" viewBox="0 0 27 19"
    className={props.className}
  >
    <defs>
      <path id="7f5xtfog7a" d="M0 0H13V17H0z" />
      <path id="4ovf71bb0c" d="M0 0H13V17H0z" />
      <path id="iwb2qy19ve" d="M0 0H13V17H0z" />
      <path id="3pbw1q6m1g" d="M0 0H13V17H0z" />
      <path id="1f4brji2yi" d="M0 0H13V17H0z" />
      <path id="np01ym49lk" d="M0 0H13V17H0z" />
      <path id="05cjj2y8fm" d="M0 0H13V17H0z" />
      <path id="tvlwiioumo" d="M0 0H13V17H0z" />
      <path id="85zu4vrz3q" d="M0 0H13V19H0z" />
      <path id="hhqx8ittcs" d="M0 0H13V19H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-304 -659) translate(304.5 659) translate(0 1)">
        <mask id="n68dc560nb" fill="#fff">
          <use xlinkHref="#7f5xtfog7a" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#7f5xtfog7a" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#n68dc560nb)" />
        <mask id="89j7c5wt2d" fill="#fff">
          <use xlinkHref="#4ovf71bb0c" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#4ovf71bb0c" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#89j7c5wt2d)" />
      </g>
      <g transform="translate(-304 -659) translate(304.5 659) matrix(-1 0 0 1 26 1)">
        <mask id="csjpkpnbqf" fill="#fff">
          <use xlinkHref="#iwb2qy19ve" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#iwb2qy19ve" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#csjpkpnbqf)" />
        <mask id="552j00so6h" fill="#fff">
          <use xlinkHref="#3pbw1q6m1g" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#3pbw1q6m1g" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#552j00so6h)" />
      </g>
      <g transform="translate(-304 -659) translate(304.5 659) translate(0 1)">
        <mask id="53d219302j" fill="#fff">
          <use xlinkHref="#1f4brji2yi" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#1f4brji2yi" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#53d219302j)" />
        <mask id="5hbw6d5eul" fill="#fff">
          <use xlinkHref="#np01ym49lk" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#np01ym49lk" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#5hbw6d5eul)" />
      </g>
      <g transform="translate(-304 -659) translate(304.5 659) matrix(-1 0 0 1 26 1)">
        <mask id="i3e6owr9mn" fill="#fff">
          <use xlinkHref="#05cjj2y8fm" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#05cjj2y8fm" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#i3e6owr9mn)" />
        <mask id="33rrmqeprp" fill="#fff">
          <use xlinkHref="#tvlwiioumo" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#tvlwiioumo" />
        <path fill="#FFF" d="M9.57 14.971C7.988 16.491 2.504 17 1 17c-1.504 0-6.83-.382-8.57-2.029-1.742-1.647-1.891-6.219-2.283-8.196-.4-2.02.028-4.05 1.147-5.427C-8.011.491-7.124 0-6.274 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C4.891.497 6.09 0 8.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" mask="url(#33rrmqeprp)" />
      </g>
      <g transform="translate(-304 -659) translate(304.5 659) translate(13)">
        <mask id="tqjgu7v9tr" fill="#fff">
          <use xlinkHref="#85zu4vrz3q" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#85zu4vrz3q" />
        <g stroke="#979797" strokeDasharray="2 2" strokeLinecap="round" strokeLinejoin="round" mask="url(#tqjgu7v9tr)">
          <path d="M19.57 14.971C17.988 16.491 12.504 17 11 17c-1.504 0-6.83-.382-8.57-2.029C.688 13.324.539 8.752.147 6.775c-.4-2.02.028-4.05 1.147-5.427C1.989.491 2.876 0 3.726 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C14.891.497 16.09 0 18.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" transform="matrix(-1 0 0 1 23 1)" />
        </g>
      </g>
      <g transform="translate(-304 -659) translate(304.5 659) matrix(-1 0 0 1 13 0)">
        <mask id="o3n6nvqzft" fill="#fff">
          <use xlinkHref="#hhqx8ittcs" />
        </mask>
        <use fill="#D8D8D8" fillOpacity="0" xlinkHref="#hhqx8ittcs" />
        <g stroke="#979797" strokeDasharray="2 2" strokeLinecap="round" strokeLinejoin="round" mask="url(#o3n6nvqzft)">
          <path d="M19.57 14.971C17.988 16.491 12.504 17 11 17c-1.504 0-6.83-.382-8.57-2.029C.688 13.324.539 8.752.147 6.775c-.4-2.02.028-4.05 1.147-5.427C1.989.491 2.876 0 3.726 0c2.184 0 3.383.497 4.44.935.327.135.646.267.98.38l.541.22c1.88.77 4.219 1.73 6.163 1.73.26 0 .47-.214.47-.477s-.21-.476-.47-.476c-1.103 0-2.396-.36-3.651-.813.586-.13 1.101-.343 1.634-.564C14.891.497 16.09 0 18.274 0c.85 0 1.737.492 2.432 1.348 1.119 1.377 1.547 3.406 1.147 5.427-.392 1.977-.7 6.676-2.283 8.196z" transform="matrix(-1 0 0 1 23 1)" />
        </g>
      </g>
    </g>
  </svg>
);

export default SideTeethViewOutlineOff;