import { apiPaths } from 'modules/defines/paths';
import http from 'modules/api/axios';

class AuthAPI {
  constructor() {
    // this.host = process.env.NODE_ENV === 'development' ? 'https://test.node.3d-labeling.ddhinc.net' : 'https://test.node.3d-labeling.ddhinc.net';  // 추후 dev 경우 url 변경되어야함 ADD test.
    this.host = process.env.NODE_ENV === 'development' ? 'https://node.3d-labeling.ddhinc.net' : 'https://node.3d-labeling.ddhinc.net';
  }

  signup(rqData) {
    return new Promise((resolve, reject) => {
      http(this.host, apiPaths.signup, {
        method: 'POST',
        data: rqData })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  }

  login(rqData) {
    return new Promise((resolve, reject) => {
      http(this.host, apiPaths.login, { 
        method: 'POST',
        data: rqData })
        .then((response) => { resolve(response);})
        .catch(e => { reject(e); });
    });
  }

  adminLogin(rqData) {
    return new Promise((resolve, reject) => {
      http(this.host, apiPaths.adminLogin, { 
        method: 'POST',
        data: rqData })
        .then((response) => { resolve(response);})
        .catch(e => { reject(e); });
    });
  }

  logout(cookies) {
    return new Promise((resolve, reject) => {
      http(this.host, apiPaths.logout, { 
        method: 'POST',
        headers: { 'Authorization': `bearer ${cookies.user.token}` },
      })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  }

  adminLogout(cookies) {
    return new Promise((resolve, reject) => {
      http(this.host, apiPaths.logout, { 
        method: 'POST',
        headers: { 'Authorization': `bearer ${cookies.user.token}` },
      })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  }
}

const authAPI = new AuthAPI();
export default authAPI;