import * as React from 'react';

const Delete = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.45455C4.38505 1.45455 1.45455 4.38505 1.45455 8C1.45455 11.615 4.38505 14.5455 8 14.5455C11.615 14.5455 14.5455 11.615 14.5455 8C14.5455 4.38505 11.615 1.45455 8 1.45455ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#FB3836"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6961 5.30335C10.9801 5.58736 10.9801 6.04785 10.6961 6.33186L6.33247 10.6955C6.04846 10.9795 5.58797 10.9795 5.30396 10.6955C5.01994 10.4115 5.01994 9.951 5.30396 9.66698L9.66759 5.30335C9.95161 5.01933 10.4121 5.01933 10.6961 5.30335Z"
      fill="#FB3836"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30396 5.30335C5.58797 5.01933 6.04846 5.01933 6.33247 5.30335L10.6961 9.66698C10.9801 9.951 10.9801 10.4115 10.6961 10.6955C10.4121 10.9795 9.95161 10.9795 9.66759 10.6955L5.30396 6.33186C5.01994 6.04785 5.01994 5.58736 5.30396 5.30335Z"
      fill="#FB3836"
    />
  </svg>
);

export default Delete;
