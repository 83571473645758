import React from 'react';

const SideTeethNoneOff = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M8 15c-4.418 0-8-3.358-8-7.5C0 3.358 3.582 0 8 0s8 3.358 8 7.5c0 4.142-3.582 7.5-8 7.5zm6-7.703C13.999 6.114 13.627 4.96 12.937 4L5 11.937c1.736 1.243 4.021 1.409 5.918.43 1.897-.978 3.087-2.936 3.082-5.07zM2 7.703c.001 1.183.373 2.336 1.063 3.297L11 3.063c-1.736-1.243-4.021-1.409-5.918-.43C3.185 3.61 1.995 5.569 2 7.703z" transform="translate(-437 -535) translate(437 535)" />
      </g>
    </g>
  </svg>
);

export default SideTeethNoneOff;