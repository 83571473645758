import React from 'react';

const Eye = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11">
    <g fill="none" fillRule="evenodd">
      <g fill={props.color || '#868686'}>
        <path d="M704 420.25c3.869 7 10.131 7 14 0-3.869-7-10.131-7-14 0zm7 2.15c-1.187 0-2.15-.963-2.15-2.15 0-1.187.963-2.15 2.15-2.15 1.187 0 2.15.963 2.15 2.15 0 1.187-.963 2.15-2.15 2.15z" transform="translate(-704 -415)" />
      </g>
    </g>
  </svg>
);

export default Eye;