import React from 'react';
import styled from 'styled-components/macro';
import { palette, zIndex } from 'modules/defines/styles';
import IconAdd from 'components/icons/Add';
import IconCheckgreen from 'components/icons/Checkgreen';
import IconDelete from 'components/icons/Delete';
import DefaultButton from 'components/button/DefaultButton';
import Localization from '../../i18n/index';

const UploadWrapper = styled.div`
display: flex;
position: absolute;
flex-direction: column;
transform: translate(-50%, -50%);
align-items: flex-start;
left: 50%;
top: 50%;
font-size: 28px;
color: ${palette.blue}
`;

const UploadListWrap = styled.div`
  display: flex;
  width: 500px;
  min-height: 500px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${palette.blue};
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;

  input[type="file"] {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
`;

const ListWrap = styled.div`
  z-index: ${zIndex.modal};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

const InnerList = styled.div`
   height: 100%;
   width: 100%;
`;

const TableHead = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${palette.blue};
  justify-content: space-around;
`;

const TableHeadItem = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  color: ${palette.blue};
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  &.border {
    justify-content: flex-start;
    border-right: 1px solid ${palette.blue};   
  }
`;

const TableBody = styled.div`
  display: flex;
  flex-direction : column;
  min-height: 255px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${palette.blue};
  justify-content: space-around;
`;

const TableItem = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
min-height: 50px;
font-size: 18px;
cursor: default;
color:  ${palette.blue};
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
  div{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
&.border {
  justify-content: flex-start;
  border-right: 1px solid ${palette.blue};
}
&.status {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
${props => (props.theme === 'selected' && `
  background-color: ${palette.blue};
  color: ${palette.white}
  `)}
`;

const Progress = styled.div`
  display: flex;
  width: 100%;
  border : 1px solid ${palette.blue};
  height: 15px;
  background-color: ${palette.lightgray};
  color: ${palette.blue};
  margin-top: 20px;
  :before {
    content: '';
    display: block;
    width: ${props => props.progress}%;
    height: 15px;
    background-color: ${palette.blue};
  }
`;


const UploadModal = ({ isUpload, checkedArr, onRemove, singleProgress, progress, onClick, onReader, isFile, history, onUpload, inputFile }) => {
  return (
   <UploadWrapper>
    Patient Upload
    <UploadListWrap style={{ alignItems: isFile ? 'flex-start' : 'center' }} onClick={onClick}>
      {isFile ? <UploadList checkedArr={checkedArr} onRemove={onRemove} singleProgress={singleProgress} files={inputFile} /> : <IconAdd /> }
      <input onChange={onReader} style={{ width: '200px', display:'none' }} id="folder" webkitdirectory="" multiple="" directory="" type="file" /> 
    </UploadListWrap>
    <ButtonWrap>
      {isFile && <DefaultButton onClick={() => onUpload()}>{Localization.upload}</DefaultButton>}
    </ButtonWrap>
    {isUpload && (
        <>
          <Progress progress={progress} />
          <p style={{ marginTop: '10px', marginLeft: '5px', color: palette.blue }}> {progress}% </p>
        </>
       )}
   </UploadWrapper>
  );
};

export default UploadModal;

export const UploadList = ({ onRemove, files, singleProgress, checkedArr }) => {
  return (
    <ListWrap>
      <InnerList>
        <TableHead>
          <TableHeadItem> FOLDER </TableHeadItem>
          <TableHeadItem> UPLOAD </TableHeadItem>
        </TableHead>
        <TableBody style={{ width: '100%', overflowY: 'scroll', maxHeight: '500px' }}>
          {files.map((data, idx) => (
            <TableRow key={idx}>
              <TableItem> {data[0].path ? data[0].path.split('/')[2] : data[0].webkitRelativePath.split('/')[1]} </TableItem>
              <TableItem> 
                <button type="button" disabled={singleProgress === idx} onClick={() => onRemove(idx)}>{checkedArr[idx] === 'checked' ? <IconCheckgreen /> : <IconDelete />} </button>
              </TableItem>
            </TableRow>
          ))}
        </TableBody>
      </InnerList>
    </ListWrap>
  );
};