import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import ArrowLeft from 'components/icons/ArrowLeft';
import ArrowRight from 'components/icons/ArrowRight';
import ArrowPrev from 'components/icons/ArrowPrev';
import ArrowNext from 'components/icons/ArrowNext';
import { IndexPaging, IndexButton } from 'components/list/IndexButton';
import IconDelete from 'components/icons/Delete';
import IconSort from 'components/icons/Sort';
import { fontEn, palette } from 'modules/defines/styles';
import Localization from 'i18n';
import { Strings } from 'utils/Srings';
import ModifyButton from 'components/button/ModifyButton';
import GlobalContext from 'modules/context/GlobalContext';
import fileAPI from 'modules/api/file';
import { useCookies } from 'react-cookie';

const TableWrapper = styled.div`
  width: 100%;
`;
const TableHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 15px;
`;
const HeadItem = styled.div`
  width: ${props => props.width};
  color: ${palette.black};
  font-size: 13px;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;

  &:first-child {
    border-radius: 15px 0 0 15px;
  }
  &:last-child {
    border-radius: 0 15px 15px 0;
  }
`;

const ListItem = styled.div`
  flex-shink: 0;
  width: ${props => props.width};
  font-size: 13px;
  font-family: ${fontEn};
  text-align: center;
  vertical-align: middle;

  ${props => (props.theme === 'black' ? (`color: ${palette.black}`) : (`color: ${palette.gray};`))};
`;
const TableBody = styled.div`
`;
const TableList = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px; 

  &:nth-child(2n) {
    background-color: ${palette.bg};
  }
  &:hover ${ListItem} {
    color: ${palette.blue};
    cursor: pointer;
  }
`;

const TableFooter = styled.div`
  position: relative;
  margin-top: 30px;
`;

const Total = styled.div`
  position: absolute;
  white-space: nowrap;
  color: ${palette.gray};
`;

const SortButton = styled.button`
  font-size: inherit;
  font-weight: inherit;

  svg {
    margin: 0 -18px 0 3px;
  }
`;

// 테이블 컬럼 사이즈
const cellSize = {
  'no': '20%',
  'patient_id': '25%',
  'working_state': '25%',
  'create_date': '20%',
  'delete': '10%',  
  // 'modify': '5%',
};

const ListBody = (props) => {
  const { listHead, listData, sortData, sortResponse, searchWord } = props;
  const listSize = 10; // 한 번에 보여질 리스트 개수
  const pagingSize = 10; // 한 번에 보여질 페이징 개수
  const search = searchWord;
  const totalData = sortResponse.totalItems;
  const currntPage = sortResponse.curPage;
  const totalPage = sortResponse.totalPage;
  const [listDatas, setListDatas] = useState(listData); // 리스트 전체 데이터
  const [curr, setCurr] = useState(0); // 현재 페이징 넘버
  const [data, setData] = useState(listDatas.slice(curr, listSize) || []); // 화면에 보여줄 리스트 데이터
  const numIndex = Array.from({ length: Math.ceil(totalData / listSize) }, (_, i) => i + 1); // 전체 페이징 개수
  const [sortItem, setSortItem] = useState('patient_id');
  const [orderIndex, setOrderIndex] = useState('asc');
  const cookies = useCookies();
  const patientInfo = {
    image: [],
};

  // paging 처리
  const handlePaging = (n) => {
    sortData({ page: curr + 1, order: orderIndex, order_target: sortItem, search_word: search });
    setCurr(n);
  };

  // 환자 상세보기
  const handleDetail = (patientId) => {
    if (props.onClick) {
      props.onClick(patientId);
    }
  };

  const handleDelete = (patientId) => {

    const delImgPath = [];
    let delIdx = -1;
    for (let i = 0; i < listDatas.length; i += 1) {
      const list = listDatas[i];
      if (list.patient_id === patientId) {
          delImgPath.push(list.image[0].path);
          delImgPath.push(list.image[1].path);
          delIdx = i;
          break;
      }
    }
    
    const bodyVal = { path: delImgPath };
    if (delImgPath.length !== 2) {
      console.log('Error');
    } else {
      fileAPI.delete(bodyVal, cookies).then(response => {
        if (response.success) {
          listDatas.splice(delIdx, 1);
          setListDatas(listDatas);
          window.location.reload();
        }
      });
    }
  }; 

  const handleSort = (listId) => {
    const sortOrder = listId === sortItem && orderIndex === 'asc' ? 'desc' : 'asc';
    setSortItem(listId);
    setOrderIndex(sortOrder);
    sortData({ page: curr + 1, order: orderIndex, order_target: sortItem, search_word: search });
  };

  //   const handleSorting = (sortItem, sortOrder) => {
  //   if (sortItem === 'patient_id') {
  //     const sorted = [...listDatas].sort((a, b) => {
  //       if (a[sortItem] === null) return 1;
  //       if (b[sortItem] === null) return -1;
  //       if (a[sortItem] === null && b[sortItem] === null) return 0;
  //       return (
  //        a[sortItem].toString().localeCompare(b[sortItem].toString(), 'en', {
  //         numeric: true,
  //        }) * (sortOrder === 'asc' ? 1 : -1)
  //       );
  //      });
  //      setData(sorted.slice(curr, listSize * curr));
  //      console.log(curr, listSize, 'ck');
  //    } else {
  //     const sorted = [...listDatas].sort((a, b) => {
  //       if (a[sortItem] === null) return 1;
  //       if (b[sortItem] === null) return -1;
  //       if (a[sortItem] === null && b[sortItem] === null) return 0;
  //       return (
  //        a[sortItem].toString().localeCompare(b[sortItem].toString(), 'en', {
  //         numeric: true,
  //        }) * (sortOrder === 'asc' ? 1 : -1)
  //       );
  //      });
  //      setData(sorted.slice(curr, listSize * curr));
  //    }
  // };



  useEffect(() => {
    setListDatas(listData);
    setData(listDatas.slice(0, listSize));
  }, [listData, listDatas]);

  useEffect(() => {
    const sorting = { page: curr + 1, order: orderIndex, order_target: sortItem, search_word: search };
    sortData(sorting);
  }, [curr, orderIndex, sortItem]);

  const handleModify = () => {
    //TODO: 수정 페이지 및 api
  };

  return (
    <>
      <TableWrapper>
        <TableHeader>
          {listHead.map((v, i) => (
            <HeadItem key={i} width={cellSize[v.id]}>
            {v.sortable ? (
              <SortButton type="button" onClick={() => handleSort(v.id)}>
                {v.label} 
                <IconSort theme={(v.id === sortItem && orderIndex )} />
              </SortButton>            
            ) : (
              v.label
            )}
            </HeadItem>
          ))}
        </TableHeader>

        <TableBody>
          {data.length > 0 ? data.map((v, i) => (
            <TableList key={i}>
              <ListItem theme="black" width={cellSize.no} onClick={() => handleDetail(v.patient_id)}>{(curr * listSize) + i + 1}</ListItem>
              <ListItem width={cellSize.patient_id} onClick={() => handleDetail(v.patient_id)}>{v.patient_id}</ListItem>
              <ListItem width={cellSize.working_state} onClick={() => handleDetail(v.patient_id)}> {v.working_state} </ListItem>
              <ListItem width={cellSize.create_date} onClick={() => handleDetail(v.patient_id)}>{v.created_at.split('T')[0]}</ListItem>
              <ListItem width={cellSize.delete}> <button type="button" onClick={() => handleDelete(v.patient_id)}> <IconDelete /> </button> </ListItem> {/* onClick={() => handleClick(i)} */}
              <ListItem width={cellSize.modify}> </ListItem>
              {/* <ListItem width={cellSize.modify}>  <ModifyButton type="button" onClick={handleModify} /> </ListItem> */}
            </TableList>
          )) : (
            <TableList>{Localization.noData}</TableList>
          )}
        </TableBody>
      </TableWrapper>

      <TableFooter>
        <Total>{Strings(Localization.totalPatients, sortResponse.totalItems)}</Total>

        {totalData > 10 && (
          <IndexPaging>
            {numIndex.length > pagingSize 
              && (
                <IndexButton
                  disabled={parseInt(curr / pagingSize, 10) === 0}
                  onClick={() => handlePaging(pagingSize * parseInt(curr / pagingSize, 10) - pagingSize)}
                ><ArrowPrev />
                </IndexButton>
              )}

            <IndexButton
              disabled={curr === 0}
              onClick={() => handlePaging(curr - 1)}
            ><ArrowLeft />
            </IndexButton>

            {numIndex.map((v, i) => (parseInt(curr / pagingSize, 10) === parseInt((v - 1) / pagingSize, 10))
              && (<IndexButton key={i} selected={i === curr} onClick={() => handlePaging(v - 1)}>{v}</IndexButton>))}

            <IndexButton
              disabled={curr === numIndex.length - 1}
              onClick={() => handlePaging(curr + 1)}
            ><ArrowRight />
            </IndexButton>

            {numIndex.length > pagingSize
              && (
                <IndexButton
                  disabled={Math.ceil((curr + 1) / pagingSize) === Math.ceil(numIndex.length / pagingSize)}
                  onClick={() => handlePaging(pagingSize * parseInt(curr / pagingSize, 10) + pagingSize)}
                ><ArrowNext />
                </IndexButton>
              )}
          </IndexPaging>
        )}
      </TableFooter>
    </>
  );
};

export default ListBody;