import React from 'react';
import styled from 'styled-components/macro';

import { fontEn } from 'modules/defines/styles';
import Localization from 'i18n';

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 21px;
  right: 15px;
`;

const UserName = styled.div`
  color: #141821;
  font-size: 16px;
`;

const Logout = styled.button`
  height: 24px;
  padding: 0 15px;
  color: #000000;
  font-size: 16px;
  font-family: ${fontEn};
`;

const UserInfo = (props) => (
  <UserInfoWrapper>
    {/* <UserName>{props.name} {Localization.doctor}</UserName> */}
    <Logout type="button" onClick={() => props.onLogout && props.onLogout()}>Log Out</Logout>
  </UserInfoWrapper>
);

export default UserInfo;
