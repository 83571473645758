import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import * as THREE from 'three';

// a simple ball marker within the main meshGrou, note position is in world-coords, need to transform to meshgroup local
// export const Marker = ({ viewerContext, position, color }) => (
//     <mesh position={viewerContext.worldToMeshLocal(position)}>
//         <meshPhongMaterial color={color || 0x1010ff} />
//         <sphereGeometry args={[0.4, 16, 16]} />
//     </mesh>
// );
export const Marker = ({ viewerContext, active, position, color, data }) => {
    const enterMarker = (e, entered) => {
        document.getElementById('three-d-viewer').style.cursor = entered ? 'pointer' : 'crosshair';
    };
    
    const clickMarker = (e, data) => {
        e.stopPropagation();
        // console.log('click marker', data);
        const { tooth, teeth, step } = data;
        viewerContext.setToothAndStep(tooth, teeth, step);
    };
    return (
        <mesh
            position={viewerContext.worldToMeshLocal(position)}
            onPointerEnter={e => !active && enterMarker(e, true)} onPointerLeave={e => enterMarker(e, false)}
            onPointerDown={e => !active && clickMarker(e, data)}
        >
            <meshPhongMaterial color={color || 0x1010ff} />
            <sphereGeometry args={[0.4, 16, 16]} />
            
        </mesh>
    );
};

export const MarkerHighLight = ({ viewerContext, position, transparent = false, opacity = 1 }) => {
    return (
        <mesh
            position={viewerContext.worldToMeshLocal(position)}
            
        >
            <meshPhongMaterial transparent={transparent} opacity={opacity} />
            <sphereGeometry args={[0.8, 16, 16]} />
        </mesh>
    );
};

// a 3js Line object positioned at 'from' going to 'to', 'from' is the object's local pivot point
export const Line = ({ from, to, pivot, color = 0xff0000, opacity = 1, transparent = false, linewidth = 4 }) => {
    const [origin, setOrigin] = useState(pivot || [(to[0] + from[0]) / 2, (to[1] + from[1]) / 2, (to[2] + from[2]) / 2]); // pivot defaults to centerpoint twixt from & to
    const lineGeometry = useMemo(() => {
        const points = [
            new THREE.Vector3(from[0] - origin[0], from[1] - origin[1], from[2] - origin[2]),
            new THREE.Vector3(to[0] - origin[0], to[1] - origin[1], to[2] - origin[2]),
        ];
        return new THREE.BufferGeometry().setFromPoints(points);
    }, [from, to]);

    return (
        <line geometry={lineGeometry} position={[origin[0], origin[1], origin[2]]}>
            <lineBasicMaterial color={color} linewidth={linewidth} opacity={opacity} transparent={transparent} />
        </line>
    );
};

// an axis pole made from a thin cylinder (3js Line objects are too thin and feint)
export const Axis = ({ viewerContext, position, rotation, length, color }) => (
    <mesh position={viewerContext.worldToMeshLocal(position)} rotation={viewerContext.worldToMeshLocal(rotation)}>
        <meshPhongMaterial color={color} />
        <cylinderGeometry args={[0.05, 0.035, length, 8]} />
    </mesh>
);

