// maintains & manages the 3D viewer-state context for the labeller

import { createContext, useState, useEffect, useContext } from 'react';
import * as THREE from 'three';
import { labelingSteps } from 'modules/defines/labelingSteps';
import fileAPI from 'modules/api/file';
import GlobalContext from 'modules/context/GlobalContext';

const _ = require('lodash');

export const useKeyEvent = (callback) => {
    useEffect(() => { 
        const handleKey = (e) => {
            if ( e.type === 'keydown' && e.key === ' ') {
            callback(Boolean((e.key === ' ' && !e.metaKey)), e);
            } else {
            callback(Boolean((e.shiftKey && !e.metaKey), e));
            }
        };
        document.addEventListener('keydown', handleKey);
        document.addEventListener('keyup', handleKey);
        return () => {
            document.removeEventListener('keydown', handleKey);
            document.removeEventListener('keyup', handleKey);
        };
    }, []);
};

export const useMouseButtonState = () => {
    const [buttonState, setButtonState] = useState(-1);
    useEffect(() => {
        const updateButtonState = (e)  => {
            setButtonState(e.type === 'mousedown' ? e.button : -1);
        };
        window.addEventListener('mousedown', updateButtonState);
        window.addEventListener('mouseup', updateButtonState);
        return () => {
            window.removeEventListener('mousedown', updateButtonState);
            window.removeEventListener('mouseup', updateButtonState);
        };
    }, []);
    return buttonState;
};

const initialCamSettings = { position: new THREE.Vector3(0, 100, 0), fov: 35, up: new THREE.Vector3(0, 0, 1) };
// const initialCamSettings = { upper: { position: new THREE.Vector3(0, -150, 0), fov: 35, up: new THREE.Vector3(0, 0, 1) },
//                              lower: { position: new THREE.Vector3(0, -150, 0), fov: 35, up: new THREE.Vector3(0, 0, 1) } };
// const initialCamSettings = { position: [0, -150, 0], fov: 35, up: [0, 0, 1] };


const saveData = { 
    _id: '',
    annotation: {},
};

const initData = {
    categories: '',
    image_id: '',
};
// viewer states, modes & filters
const ViewerContext = createContext({});

export const viewerStateInit = {
    toothNumber: 11,
    labelingStep: 0,
    labelingSteps,
    labels: { upper: {}, lower: {} }, // { <tooth#>: { <all labeling data captured in update() functions in labelingSteps definition>, }, ... }
    // Status
    missingStatus: false,
    // Create Date
    createDate: null,
    // DB objects
    categories: null,
    upperImage_id: null,
    lowerImage_id: null,
    upperDataInfo: [],
    lowerDataInfo: [],
    initInfo: null,
    //functionList: null,

    // gingiva mesh geometries
    upperGeometry: null,
    lowerGeometry: null,
    slicedGeometry: null,

    // various key 3js object references
    meshGroup: null, // main mesh group, all mesh-related objects are children and in its coordinate system
    cameraRef: null,
    trackballControlsRef: null,
    FAAxisRef: null,
    clippingPlaneRef: null,
    meshPointClicked: null, // last point clicked on mesh

    // updating camera view settings, used to set camera during react re-renders
    camSettings: null,
    trackballSettings: { noRotate: true, target: new THREE.Vector3(0, 0, 0), rotateSpeed: 10 },

    // utility functions
    setOrientation: () => {},   // filled-in by Meshes component

    // interaction & display helpers - all point coords are world-space
    markers: { upper: [], lower: [] },
    planes: { upper: null, lower: null },

    display: {
        teeth: 'upper', // 'upper', 'lower', 'none'
        orient: 'bottom',   // 'top', 'bottom', 'left', 'right'
        showWireframe: true,
    },
    // UX controls
    shiftDown: false,
};
// dynamic initialization
viewerStateInit.labelingStepName = viewerStateInit.labelingSteps[0].title;
viewerStateInit.camSettings = _.cloneDeep(initialCamSettings);

export const useViewerContext = () => {
    const [viewerState, setViewerState] = useState(viewerStateInit);
    const [data, setData] = useState();
    const globalContext = useContext(GlobalContext);
    viewerState.createDate = globalContext.patientInfo.created_at;
    const speakMsg = new SpeechSynthesisUtterance();
    const voices = speechSynthesis.getVoices();
    const voiceTone = voices.find(voiceTone => voiceTone === 'en-US');
    const synth = window.speechSynthesis;
    speakMsg.lang = 'en-US';
    speakMsg.voice = voiceTone;
    speakMsg.rate = 1;
    speakMsg.pitch = 1;
    speakMsg.volume = 1;

    const updateViewerState = (update) => {
        setViewerState((prev) => ({ ...prev, ...update }));
    };

    const updateDisplay = (update) => {
        setViewerState((prev) => ({ ...prev, display: { ...prev.display, ...update } }));
    };

    const initCamSettings = (teeth) => {
        const camSettings = _.cloneDeep(initialCamSettings);
        const checkDate = new Date('2023-03-31');
        if (((teeth || viewerState.display.teeth) === 'upper')) {
            camSettings.position = new THREE.Vector3(0, -100, 0);
            camSettings.up = new THREE.Vector3(0, 0, 1);
        } else if ((teeth || viewerState.display.teeth) === 'lower') {
            camSettings.position = new THREE.Vector3(0, 100, 0);
            camSettings.up = new THREE.Vector3(0, 0, -1);
        }
        
        return camSettings;
    };    

    const getLabelData = (teeth) => viewerState.labels[teeth || viewerState.display.teeth][viewerState.toothNumber]; // 상악 하악 배열에 치아번호 annotation 전체 데이터 저장

    // move focus to give tooth number, reset step state to 1(??)
    const getStateUpdateForTooth = (n, teeth, stateInit) => {
        let step = 0;
        const state = stateInit || viewerState;
        // const savedCams = { camSettings: _.cloneDeep(viewerState.camSettings) };
        const stepLabelData = state.labels[teeth || state.display.teeth][n];
        if (stepLabelData && stepLabelData.savedStates ) {
            // tooth has some data already, find best step to start & restore saved state for it
            while (stepLabelData.savedStates[step] && step < 1)
                step += 1;
            const savedState = stepLabelData.savedStates[step];
            if ( step === 1 && !stepLabelData.savedStates[1]) {
                return {
                    toothNumber: n,
                    labelingStep: 0,
                    labelingStepName: state.labelingSteps[0].title,
                    upperDataInfo: state.upperDataInfo,
                    lowerDataInfo: state.lowerDataInfo,
                    camSettings: initCamSettings(teeth),
                    trackballSettings: { noRotate: true, target: new THREE.Vector3(0, 0, 0), rotateSpeed: 10 },
                };
            } else {
                const update = {
                    toothNumber: n,
                    labelingStep: step,
                    labelingStepName: state.labelingSteps[step].title,
                    labels: state.labels,
                    upperDataInfo: state.upperDataInfo,
                    lowerDataInfo: state.lowerDataInfo,
                    camSettings: { ...savedState.camSettings },
                    trackballSettings: { ...savedState.trackballSettings } };
                if (savedState.stepUpdate)
                    Object.assign(update, savedState.stepUpdate);
                return update;
            }
        } else if (stepLabelData && stepLabelData.missingStatus === true) {
            return {
                toothNumber: n,
                labelingStep: 0,
                labelingStepName: state.labelingSteps[0].title,
                camSettings: initCamSettings(teeth),
                upperDataInfo: state.upperDataInfo,
                lowerDataInfo: state.lowerDataInfo,
                trackballSettings: { noRotate: true, target: new THREE.Vector3(0, 0, 0), rotateSpeed: 10 },
            };
        } else {
            return {
                toothNumber: n,
                labelingStep: 0,
                labelingStepName: state.labelingSteps[0].title,
                camSettings: initCamSettings(teeth),
                upperDataInfo: state.upperDataInfo,
                lowerDataInfo: state.lowerDataInfo,
                trackballSettings: { noRotate: true, target: new THREE.Vector3(0, 0, 0), rotateSpeed: 10 },
            };            
        }
    };
    const setDeleteLabel = (n, teeth, dataDelete) => {
        const state = viewerState;
        state.labelingStep = 0;
        state.camSettings = initCamSettings(teeth);
        const emptyLabels = { [n]: { } };
        const emptyLabel = {};
        if ( state.toothNumber < 28) {
        const update = Object.assign(state.labels.upper, emptyLabels);
        if (dataDelete === true) {
            if ( viewerState.toothNumber < 28 ) {
                for (let i = 0; i < viewerState.upperDataInfo.length; i += 1) {
                    if ( viewerState.toothNumber === viewerState.upperDataInfo[i].categories ) {
                        saveData._id = viewerState.upperDataInfo[i]._id;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < viewerState.lowerDataInfo.length; i += 1) {
                    if ( viewerState.toothNumber === viewerState.lowerDataInfo[i].categories ) {
                        saveData._id = viewerState.lowerDataInfo[i]._id;
                        break;
                    }
                }
            }
            saveData.annotation = emptyLabel;
            fileAPI.save(saveData).then(dataSave => {
            });
        }
        updateViewerState(update);
        } else {
            const update = Object.assign(state.labels.lower, emptyLabels);
            if (dataDelete === true) {
                if ( viewerState.toothNumber < 28 ) {
                    for (let i = 0; i < viewerState.upperDataInfo.length; i += 1) {
                        if ( viewerState.toothNumber === viewerState.upperDataInfo[i].categories ) {
                            saveData._id = viewerState.upperDataInfo[i]._id;
                            break;
                        }
                    }
                } else {
                    for (let i = 0; i < viewerState.lowerDataInfo.length; i += 1) {
                        if ( viewerState.toothNumber === viewerState.lowerDataInfo[i].categories ) {
                            saveData._id = viewerState.lowerDataInfo[i]._id;
                            break;
                        }
                    }
                }
                saveData.annotation = emptyLabel;
                fileAPI.save(saveData).then(dataSave => {
                });
            }
            updateViewerState(update);
        }
       
    };

    const setMissingTooth = (n, teeth, missing) => {
        const state = viewerState;
        state.toothNumber = n;
        const step = viewerState.labelingStep;
        const missingLabels = { [n]: { [n]: {}, missingStatus: missing } };
        const missingLabel = { [n]: {}, missingStatus: missing };
        const update =  Object.assign(state.labels[teeth], missingLabels);
        const checkDate = new Date();
        if (state.toothNumber < 28) {
            initData.categories = state.toothNumber;
            initData.image_id = state.upperImage_id;
            if (step === 0) {
                fileAPI.init(initData).then(dataInit => {
                    if (!dataInit.error) {
                      state.initInfo = dataInit.data;
                      setData(dataInit.data);
                      saveData.annotation = missingLabel;
                      saveData._id = state.initInfo._id;
                      fileAPI.save(saveData).then(dataSave => {
                      });
                    }
                });
                if (state.initInfo === null) {
                        for (let i = 0; i < state.upperDataInfo.length; i += 1) {
                            if ( state.toothNumber === state.upperDataInfo[i].categories ) {
                                saveData._id = state.upperDataInfo[i]._id;
                                break;
                            }
                        }
                    saveData.annotation = missingLabel;
                    fileAPI.save(saveData).then(dataSave => {
                    });
                }
            updateViewerState(update);
            } else {
                if (state.initInfo === null) {
                    for (let i = 0; i < state.upperDataInfo.length; i += 1) {
                        if ( state.toothNumber === state.upperDataInfo[i].categories ) {
                            saveData._id = state.upperDataInfo[i]._id;
                            break;
                        }
                    }
                saveData.annotation = missingLabel;
                fileAPI.save(saveData).then(dataSave => {
                });
                }
            updateViewerState(update);
            }
        } else {
            initData.categories = state.toothNumber;
            initData.image_id = state.lowerImage_id;
            if (step === 0) {
                fileAPI.init(initData).then(dataInit => {
                    if (!dataInit.error) {
                      state.initInfo = dataInit.data;
                      setData(dataInit.data);
                      saveData.annotation = missingLabel;
                      saveData._id = state.initInfo._id;
                      fileAPI.save(saveData).then(dataSave => {
                      });
                    }
                });
                if (state.initInfo === null) {
                    for (let i = 0; i < viewerState.lowerDataInfo.length; i += 1) {
                        if ( state.toothNumber === state.lowerDataInfo[i].categories ) {
                            saveData._id = state.lowerDataInfo[i]._id;
                            break;
                        }
                    }
                    saveData.annotation = missingLabel;
                    fileAPI.save(saveData).then(dataSave => {
                    });
                }
            updateViewerState(update);
            } else {
                if (state.initInfo === null) {
                    for (let i = 0; i < viewerState.lowerDataInfo.length; i += 1) {
                        if ( state.toothNumber === state.lowerDataInfo[i].categories ) {
                            saveData._id = state.lowerDataInfo[i]._id;
                            break;
                        }
                    }
                    saveData.annotation = missingLabel;
                    fileAPI.save(saveData).then(dataSave => {
                    });
                }
            updateViewerState(update);
            }
        }
        
        if (missing === true) {
            if ( state.toothNumber === 17 || state.toothNumber === 37 ) {
                return setToothNumber(state.toothNumber + 4);    
            } 
            if ( state.toothNumber === 27 ) {
                updateViewerState(initCamSettings('lower'));
                updateDisplay({ teeth: 'lower' });
                return setToothNumber(state.toothNumber + 4, 'lower');
            }
            if ( state.toothNumber === 47) {
                return updateViewerState();
            }
            return setToothNumber(state.toothNumber + 1);
        }
        updateViewerState(update);
    };

    const setToothNumber = (n, teeth) => {
        const update = getStateUpdateForTooth(n, teeth);
        speakMsg.text = update.labelingStepName;
        synth.speak(speakMsg);
        updateViewerState(update); 
    };

    const setToothAndStep = (tooth, teeth, step) => {
        const state = viewerState;
        const stepLabelData = state.labels[teeth || state.display.teeth][tooth];
        const savedState = stepLabelData.savedStates[step];
        speakMsg.text = state.labelingSteps[step].title;
        state.labelingSteps[state.labelingStep].holdAtStep = false; // clear any prior step hold
        state.labelingSteps[step].holdAtStep = true; // hold here while we continue to adjust pos
        if (savedState) {
            const update = {
                categories: tooth,
                toothNumber: tooth,
                labelingStep: step,
                labelingStepName: state.labelingSteps[step].title,
                labels: state.labels,
                upperDataInfo: state.upperDataInfo,
                lowerDataInfo: state.lowerDataInfo,
                camSettings: { ...savedState.camSettings },
                trackballSettings: { ...savedState.trackballSettings } };
            if (savedState.stepUpdate)
                Object.assign(update, savedState.stepUpdate);
                speechSynthesis.speak(speakMsg);
            updateViewerState(update);
        }
    };

    const autoStep = (stepUpdate = {}) => nextStep(stepUpdate, true);

    // next labeling step, wire in results of leaving step, prep cam etc for next step
    const nextStep = (stepUpdate = {}, autoStep) => {
        const netWork = window.navigator.onLine;
        const curStep = viewerState.labelingSteps[viewerState.labelingStep];
        const nextStep = viewerState.labelingStep + 1;
        const nextState = viewerState.labelingSteps[nextStep];
        const prevStep = viewerState.labelingStep - 1;
        const prevState = viewerState.labelingSteps[prevStep];
        const labelData = getLabelData() || {};
        // if stepping forward into previously-performed steps, restore its saved state
        if (labelData.savedStates && labelData.savedStates[nextStep])
            Object.assign(labelData, labelData.savedStates[nextStep].addedProps);
        if (!autoStep && labelData.savedStates && labelData.savedStates[viewerState.labelingStep] && labelData.savedStates[viewerState.labelingStep].stepUpdate)
            Object.assign(stepUpdate, labelData.savedStates[viewerState.labelingStep].stepUpdate);
        const mergedViewerState = _.merge(viewerState, stepUpdate);
        const savedState = { stepUpdate: _.clone(stepUpdate), camSettings: _.cloneDeep(viewerState.camSettings), trackballSettings: _.cloneDeep(viewerState.trackballSettings) };
        // get exiting state's update
        const curStateUpdate = curStep.update(mergedViewerState, labelData, viewerState.display.teeth === 'upper', viewerState.createDate, autoStep);
        const { toothData } = curStateUpdate;
        savedState.addedProps = _.cloneDeep(toothData);
        // place tooth update data from step's update() in correct upper or lower labels
        curStateUpdate.labels = { [viewerState.display.teeth]: { [viewerState.toothNumber]: toothData } };
        delete curStateUpdate.toothData;
        // snap cam & control settings & update viewer state
        const update = _.merge(viewerState, stepUpdate, curStateUpdate);
        if (viewerState.toothNumber < 28) {
            initData.categories = viewerState.toothNumber;
            initData.image_id = viewerState.upperImage_id;
        } else {
            initData.categories = viewerState.toothNumber;
            initData.image_id = viewerState.lowerImage_id;
        }
        if (nextStep === 1) {
            fileAPI.init(initData).then(dataInit => {
                if (!dataInit.error) {
                  viewerState.initInfo = dataInit.data;
                  viewerState.createDate = dataInit.data.created_at;
                  setData(dataInit.data);
                  
                }
            });
            update.labels[viewerState.display.teeth][viewerState.toothNumber].savedStates = [savedState];
        } else {
            if (viewerState.initInfo === null) {
                if ( viewerState.toothNumber < 28 ) {
                    for (let i = 0; i < viewerState.upperDataInfo.length; i += 1) {
                        if ( viewerState.toothNumber === viewerState.upperDataInfo[i].categories ) {
                            saveData._id = viewerState.upperDataInfo[i]._id;
                            viewerState.createDate = new Date(viewerState.upperDataInfo[i].created_at);
                            break;
                        }
                    }
                } else {
                    for (let i = 0; i < viewerState.lowerDataInfo.length; i += 1) {
                        if ( viewerState.toothNumber === viewerState.lowerDataInfo[i].categories ) {
                            saveData._id = viewerState.lowerDataInfo[i]._id;
                            viewerState.createDate = new Date(viewerState.upperDataInfo[i].created_at);
                            break;
                        }
                    }
                }
                update.labels[viewerState.display.teeth][viewerState.toothNumber].savedStates[nextStep - 1] = savedState;
                saveData.annotation = labelData;
                fileAPI.save(saveData).then(dataSave => {
                    const checkData = _.isEqual(JSON.stringify(saveData.annotation), JSON.stringify(dataSave.data));
                    if ( checkData === false) {
                        const update = {
                            labelingStep: prevStep,
                            labelingStepName: prevState.title,
                            labels: viewerState.labels,
                            camSettings: { ...savedState.camSettings },
                            trackballSettings: { ...savedState.trackballSettings } };
                        updateViewerState(update);
                    }           
                });

            } else {
                update.labels[viewerState.display.teeth][viewerState.toothNumber].savedStates[nextStep - 1] = savedState;
                saveData.annotation = labelData;
                saveData._id = viewerState.initInfo._id;
                fileAPI.save(saveData).then(dataSave => {
                    const checkData = _.isEqual(JSON.stringify(saveData.annotation), JSON.stringify(dataSave.data));
                    if ( checkData === false) {
                        const update = {
                            labelingStep: prevStep,
                            labelingStepName: prevState.title,
                            labels: viewerState.labels,
                            camSettings: { ...savedState.camSettings },
                            trackballSettings: { ...savedState.trackballSettings } };
                        updateViewerState(update);
                    }
                });
            }
        }
        if (!curStep.holdAtStep || !autoStep) {
            // bump to next step
            if (nextStep >= viewerState.labelingSteps.length) {
                // last step, bump tooth number (right?)
                if ( viewerState.toothNumber === 17 || viewerState.toothNumber === 37 ) {
                    return setToothNumber(viewerState.toothNumber + 4);    
                } 
                if ( viewerState.toothNumber === 27 ) {
                    updateViewerState(initCamSettings('lower'));
                    updateDisplay({ teeth: 'lower' });
                    return setToothNumber(viewerState.toothNumber + 4, 'lower');
                }
                if ( viewerState.toothNumber === 47) {
                    return updateViewerState();
                }
                return setToothNumber(viewerState.toothNumber + 1);
            }
            update.labelingStep = nextStep;
            update.labelingStepName = nextState.title;
            update.camSettings.position = viewerState.cameraRef.current.position;
            update.camSettings.up = viewerState.cameraRef.current.up;
            update.trackballSettings = { target: viewerState.trackballControlsRef.current.target, noRotate: Boolean(!nextState.enableRotation) };
            document.getElementById('three-d-viewer').style.cursor = nextState.cursor || 'crosshair';
        }
        if ( netWork !== true ) {
            window.location.reload();
        }

        if ( nextStep >= viewerState.labelingSteps.length) {
            speakMsg.text = curStep.title;
        } else {
            speakMsg.text = nextState.title;
        }
        if (!curStep.holdAtStep)
            synth.speak(speakMsg);
        updateViewerState(update);
    };

    useEffect(() => {
        if (data) {
            updateViewerState({ initInfo: data });
        }
    }, [data]);

    // step back - for now, effectively undoes current step, will work on an 'incremental modifying' scheme soon
    const backStep = () => {
        const prevStep = viewerState.labelingStep - 1;
        const prevState = viewerState.labelingSteps[prevStep];
        const prevStepLabelData = viewerState.labels[viewerState.display.teeth][viewerState.toothNumber];
        const savedState = prevStepLabelData.savedStates[prevStep];
        // restore prev-state step & saved label-data
        Object.assign(prevStepLabelData, savedState.addedProps);
        const update = {
            labelingStep: prevStep,
            labelingStepName: prevState.title,
            labels: viewerState.labels,
            camSettings: { ...savedState.camSettings },
            trackballSettings: { ...savedState.trackballSettings } };
        
        if (Object.keys(savedState.stepUpdate).length !== 0) {
            Object.assign(update, savedState.stepUpdate);
        }
        speakMsg.text = prevState.title;
        speechSynthesis.speak(speakMsg);
            
        // console.log('\n** backStep update:', update, '\n');
        // console.log('\n**     saved state:', savedState, '\n');
        updateViewerState(update);
    };

    // used by LabelStep.js to determine if the next button is enabled, currently disabled if step is declared auto-step (requires point pick to proceed)
    const nextStepDisabled = () => {
        // console.log('nextStepDisabled', viewerState);
        const curStep = viewerState.labelingSteps[viewerState.labelingStep];
        const labelData = getLabelData();
        const savedState = labelData && labelData.savedStates && labelData.savedStates[viewerState.labelingStep];
        return curStep.autoStep && !savedState;
    };

    // standard orientations
    const setCameraOrientation = (orientation) => {
        const position = {
            front: new THREE.Vector3(0, 100, 0),
            back: new THREE.Vector3(0, -100, 0),
            top: new THREE.Vector3(0, 0, -100),
            bottom: new THREE.Vector3(0, 0, 100),
            left: new THREE.Vector3(100, 0, 0),
            right: new THREE.Vector3(-100, 0, 0),
        }[orientation];
        const up = {
            front: new THREE.Vector3(0, 0, 1),
            back: new THREE.Vector3(0, 0, 1),
            top: new THREE.Vector3(0, 1, 0),
            bottom: new THREE.Vector3(0, 1, 0),
            left: new THREE.Vector3(0, 0, 1),
            right: new THREE.Vector3(0, 0, 1),
        }[orientation];
        console.log('orientation', orientation, 'pos', position, 'up', up);
        setCamera(position, up, new THREE.Vector3(0, 0, 0));
    };

    const setCamera = (position, up, target) => {
        updateViewerState({ camSettings: { position, up, fov: 35 }, trackballSettings: { ...viewerState.trackballSettings, target } });
    };

    const worldToMeshLocal = (point) => {
        if (viewerState.meshGroup) {
            // console.log('point', point.clone());
            // console.log('ss', viewerState.meshGroup.worldToLocal(new THREE.Vector3(point.x, point.y, point.z).clone()));
            // new THREE.Vector3(point.x, point.y, point.z)
            return viewerState.meshGroup.worldToLocal(point.clone());
        }
    };

    const clear = () => setViewerState({ ...viewerStateInit });

    // ViewerContext value
    return {
        ...viewerState,
        update: updateViewerState,
        updateDisplay,
        setToothNumber,
        getStateUpdateForTooth,
        setDeleteLabel,
        setMissingTooth,
        nextStepDisabled,
        nextStep,
        backStep,
        autoStep,
        setToothAndStep,
        getLabelData,
        worldToMeshLocal,
        setCameraOrientation,
        clear,
    };
};

export default ViewerContext;