import { apiPaths } from 'modules/defines/paths';
import http from './axios';

const projectAPI = {
  details: (lowerProjectId) => {
    return new Promise((resolve, reject) => {
      http('', `${apiPaths.projectDetails}?lowerProject_id=${lowerProjectId}`, { method: 'get' })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },
};

export default projectAPI;