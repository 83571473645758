import React from 'react';
import MainLayout from 'components/layout/MainLayout';
import GlobalContainer from 'containers/common/GlobalContainer';
import MenuContainer from 'containers/common/MenuContainer';
import ViewerMenuContainer from 'containers/viewer/ViewerMenuContainer';
import ViewerContainer from 'containers/viewer/ViewerContainer';

const Main = () => (
  <MainLayout>
    <GlobalContainer />
    <MenuContainer />

    <ViewerMenuContainer />
    <ViewerContainer />
  </MainLayout>
);

export default Main;
